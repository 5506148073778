﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.customer-notes-modal {
  min-width: 800px !important;

  @include respond-max($mobile-breakpoint-width) {
    min-width: 94% !important;
  }
}

.unsaved-warning-content {
  display: flex !important;
  justify-content: center;

  .btns {
    display: flex;
    justify-content: space-between;
    min-width: 320px;

    .btn {
      margin-top: 0;
    }
  }
}

.control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .control-text,
  .header,
  .close-icon {
    display: flex;
    flex: 1;
  }

  .control-text {
    .main-blue {
      cursor: pointer;
    }
  }

  .header {
    justify-content: center;
  }

  .close-icon {
    height: 12px;
    width: 12px;
    background-image: url("../../../images/commonIcons/icon-close@2x.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    cursor: pointer;
  }
}

.customer-notes-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include respond-max(600px) {
    flex-direction: column;
  }

  .contact-info {
    flex: 1;
    padding: 1.5rem;

    .contact-info-form {
      .name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .input-container {
        flex: 1;
        margin: 10px 0;

        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .clear-icon {
            background-image: url("../../../images/titlefyPro/searchIcons/search-icon-clear@2x.png");
            height: 12px;
            width: 12px;
            background-size: contain;
            position: absolute;
            right: 5px;
            cursor: pointer;
            z-index: 100;
          }

          .clear-icon.hidden {
            visibility: hidden;
          }

          .ui.selection.dropdown {
            border-width: 0 0 1px;
            border-bottom-color: rgba(0, 0, 0, 0.25);
            border-radius: 0;
            height: 25px;
            min-height: 0;
            padding: 0;
            padding-right: 30px;
            padding-bottom: 5px;
            display: flex;
            align-items: flex-end;
            box-shadow: none;
            min-width: 50px;

            .icon {
              display: none;
            }

            .text {
              font-weight: 700;
              margin-left: 5px;
            }

            span.text {
              margin-left: 0;
            }
          }
        }

        .input-label {
          margin-bottom: 10px;
          margin-left: 5px;
        }

        .form-control {
          width: 100%;
          color: black;
          font-size: 14px;
          font-weight: 700;
          position: relative;
          z-index: 1;
          border-radius: 0;
          /*border-width: 0 0 1px;*/
          border-width: 0 0 1px;
          border-bottom-color: rgba(0, 0, 0, 0.25);
          height: auto;
          padding: 3px 0 5px 5px;
          outline: none;
          caret-color: $light-blue;
        }

        .form-control:focus {
          border-bottom-color: $light-blue;
        }

        .phone-inputs {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .divider {
            width: 1px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.25);
            position: relative;
            bottom: 0px;
            margin: 0 1px;
          }

          .ui.selection.dropdown {
            min-width: 100px;
          }
        }
      }

      .button-section {
        padding-top: 20px;
        border-bottom: 1px solid;
        border-bottom-color: rgba(0, 0, 0, 0.25);

        .buttons {
          display: flex;
          flex-wrap: wrap;
          grid-column-gap: 5px;
          grid-row-gap: 10px;
          margin: 20px 0;

          .btn {
            margin: 0;
            box-shadow: none;
          }
        }
      }
    }
  }

  .notes {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    padding: 1.5rem;

    @include respond-max(600px) {
      flex: 1 1 175px;
    }

    .notes-input {
      flex: 1;
      width: 100%;
      background: #fafafa;
      border: none;
      resize: none;
      padding: 0;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.add-company-content {
  margin: 40px;
}

.add-company-header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.add-company-header-title {
  font-size: 18px;
  font-weight: bold;
}

.add-company-header {
  font-size: 16px;
  margin-bottom: 20px;
}

.add-company-input {
  margin-bottom: 25px;
}

.add-company-bottom-section {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  border-top: 1px solid #e5e9ed;
  margin-top: 150px;
}

.btn-add-company {
  margin-bottom: 40px;
}

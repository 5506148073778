.releaseNotes-page {
  width: 100%;

  .releaseNotes-main {
    margin: 32px;
  }

  .releaseNotes-maxWidth {
    max-width: 600px;
    margin: auto;
  }

  .releaseNotes-section {
    padding: 50px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .releaseNotes-section:last-child {
    border-bottom: none;
  }

  ul {
    padding-inline-start: 1em;
  }

  li {
    margin: 10px 0;
  }

  p {
    font-size: 18px;
  }

  .margin-bottom_xsm {
    margin-bottom: 5px;
  }

  .margin-bottom_md {
    margin-bottom: 30px;
  }

  .margin-right_sm {
    margin-right: 10px;
  }
}

.releaseNotes-label_Major.ui.label {
  background-color: #116b27;
  border-color: #116b27;
  color: white;
}
.releaseNotes-label_Minor.ui.label {
  background-color: #21ba45;
  border-color: #21ba45;
  color: white;
}

.releaseNotesAlert-txt_margin {
  margin: 0 30px;
}

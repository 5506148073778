﻿@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

.whatElse-container {
  background-color: #222222;
  width: 100%;

  .whatElse-content {
    color: white;
    width: 70%;
    max-width: $home-max-width;
    margin: auto;
    padding-top: 125px;
    padding-bottom: 190px;
    position: relative;

    @include respond-max($breakpoint) {
      width: $section-width;
    }

    .header-1 {
      color: white;
    }

    .subheader-1 {
      margin-top: 30px;
    }

    .whatElseTxtSubSecMain {
      margin-top: 60px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      @include respond-range(320px, 1024px) {
        flex-direction: column;
      }
    }

    .whatelseSec {
      width: 27%;

      @include respond-range(320px, 1024px) {
        width: 100%;
      }

      .sec {
        margin-top: 30px;
      }

      .p-3 {
        margin-top: 10px;
      }

      .whatelseSecParaTxt {
        display: flex;
        margin-top: 20px;

        .icon-check-white {
          position: relative;
          top: 4px;
          margin-right: 10px;
        }

        .p-3 {
          margin-top: 0;
        }
      }

      .whatelseSecImage {
        height: 180px;
        width: 220px;
        background: url("../../../images/homePage/Image\ 1@2x.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 40px;
      }
    }

    .block-text-margin {
      width: 100%;
      margin-top: 30px;
    }

    .rich {
      @include respond-range(320px, 1024px) {
        margin-top: 30px;
      }
    }
  }
}

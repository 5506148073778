﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.new-order__people {
  @include respond-min(600px) {
    padding: 0 80px;
  }
}

.person-row_marginBottom-sm {
  margin-bottom: 20px;
}

.people-section {
  &:not(:first-child) {
    margin-top: 50px;
  }

  .person-row__label {
    color: #000;
  }

  .person-row {
    max-width: 405px;
    padding: 10px 3px 10px 10px;
    width: 100%;
  }
}

.people-section__split-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.people-section__header-wrapper {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.people-section__header {
  margin-bottom: 5px !important;
}

.people-section__btn-add {
  background: none;
  border: none;
  color: #03b3f2;
  cursor: pointer;
}

.order-step-card {
  .people-column + .people-column {
    margin-top: 10px;
  }
}

.btn-contact-modal-close {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 800;
  height: 15px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 15px;
}

.contact-info__dropdown-warning {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 25px;
  right: -28px;
}

.contact-info-warning {
  display: flex;
  margin: 30px 0 35px;
}

.contact-info-warning__icon {
  flex: 1 0 30px;
  height: 20px;
  margin-right: 5px;
}

.contact-info__dropdown {
  margin-top: 25px;

  &.with-warning {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 93%;
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";
.partner-row-container {
  margin-left: 140px;
  margin-right: 140px;
  background-color: #ffffff;

  height: 60px;
  border: 1px solid #ececec;
  border-bottom: none;
  cursor: pointer;
  max-width: 1900px;
}

.partner-row-container:hover {
  border: 1px solid #9ce1fa;
}

.partner-row-content {
  display: flex;
  justify-content: space-between;
  max-width: 1900px;
  min-width: 400px;
  padding-right: 10px;
}

.partner-client-row-content {
  display: flex;
  max-width: 1300px;
  min-width: 400px;
}

.partner-row-cell {
  flex: 1;
  padding-top: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partner-row-id {
  color: #8c8c8c;
}

.partner-row-logo {
  max-height: 40px;
  max-width: 80px;
}

.partner-row-pad-left-10 {
  padding-left: 30px;
}

.partner-row-name {
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25px;
  display: block;
  left: 0;
  font-weight: bold;
}

.partner-row-division {
  color: #8c8c8c;
}

.partner-row-branches {
  color: #8c8c8c;
}

.partner-row-website {
  color: #8c8c8c;
}

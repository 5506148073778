@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

$admin-body-max-width: 1900px;
$admin-body-min-width: 1200px;
$admin-body-left-right-padding: 140px;

.adminpage-container {
  height: auto;
  width: 100%;
  min-height: 900px;
}

.default-user-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  vertical-align: middle;
  margin: auto;
  background-image: url("../../../images/adminPage/graphic-noProfile.png");
}

.view-edit {
  display: flex;
  background-color: #ffffff;
  height: calc(100% - 65px);

  .users-search-invite {
    height: auto;
  }
}

.admin-body-left-right-padding {
  padding-left: $admin-body-left-right-padding;
  padding-right: $admin-body-left-right-padding;
}

//styles added for permissions
.permissions-table-container {
  .ui.permission-name__checkbox {
    @extend %order-options-checkbox;
  }

  .view-permissions {
    margin-left: 20px;
    text-decoration: underline;
    display: inline;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
  }
}

.profile-flex-tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e1e1;

  .selected-tab {
    padding-bottom: 8px;
    border-bottom: 3px solid black;
    font-weight: bold;
  }

  .profile-tab-list {
    font-size: 12px;
    margin: 40px 20px 0 0;
    cursor: pointer;
  }
}

.perm-flex-tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e1e1;

  .selected-tab {
    padding-bottom: 8px;
    border-bottom: 3px solid black;
    font-weight: bold;
  }

  .permission-tab-list {
    margin: 20px 20px 0 0;
    cursor: pointer;
  }
}

.partner-perm-link {
  float: right;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.perm-modal-x {
  height: 20px;
}

.perm-modal {
  padding: 20px;
}

.titlefy-pro-container {
  .permissions-modal-header {
    text-align: center;
  }

  .permissions-modal-description {
    font-size: 14px;
    font-weight: 400;
  }

  .ui.table thead th {
    font-weight: 400;
  }

  .text-light-grey {
    color: #afafaf;
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.submit-order-container {
  width: 100%;
  flex: 1;
  position: absolute;
  left: 0;
  height: 145px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: white;
  padding: 20px 0;

  .terms-link {
    color: $light-blue;
  }

  .btn {
    cursor: pointer;
  }
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

/* add any classes that are utilizing the pdf-doc-page styles here */
.personalize-report-container.view-report {
  @extend %pdf-doc-page;
}

.prop-document-page {
  @extend %pdf-doc-page;
}

.order-doc-subpage {
  @extend %pdf-doc-page;
}

%pdf-doc-page {
  width: 100%;
  background-color: #ebebeb;

  .max-width {
    width: 100%;
    max-width: 1125px;
    margin: 0 auto;
  }

  .form-border {
    border: none;
    margin: 0;
    padding: 0;
    padding-bottom: 150px;
  }

  .form-content {
    max-width: 100%;
  }

  .pdf-doc-wrapper {
    width: 100%;
    min-height: 81vh;
    padding-top: 5%;
    border: 1px solid #ebebeb;
    background-color: #ebebeb;
  }

  .pdf-width {
    width: 100%;
    max-width: 75%;
    margin: auto;

    @include respond-max($breakpoint-xsm) {
      max-width: 90%;
    }
  }

  .pdf-page-border {
    margin-bottom: 20px;
    overflow-x: hidden;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  }

  .bottom-container {
    border-top: 1px solid #d8d8d8;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    padding: 0 $padding-16;
  }

  .btn {
    margin-top: $margin-40;

    @include respond-max($breakpoint-xsm) {
      margin-top: $margin-20;
    }
  }

  .btn-xxl {
    max-width: 329px;
    width: 100%;
  }

  .btn-text {
    margin-top: 12px;
  }

  .underline {
    margin-top: $margin-40;
    margin-bottom: $margin-40;

    @include respond-max($breakpoint-xsm) {
      margin-top: $margin-20;
      margin-bottom: $margin-20;
    }
  }
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../../common/dropdown";

%wrapper {
  padding-left: 2%;
  padding-right: 2%;
}

%active-dot {
  &.active {
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 50%;
      position: absolute;
      right: 2px;
      top: 3px;
    }
  }
}

.orders-overview-container {
  flex: 1;
  overflow-y: auto;
}

.orders-nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  padding: 0 2%;

  @include respond-min($breakpoint-lg) {
    height: 130px;
  }
}

.orders-tabs {
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  &.mobile {
    @include mobile-only($breakpoint-lg, flex);
    width: 100%;
  }

  &.desktop {
    @include desktop-only($breakpoint-lg, flex);
  }
}

.orders-tabs-item {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  position: relative;
  text-align: center;
  width: auto;

  &.active {
    font-weight: 700;

    &:after {
      background-color: #000;
      bottom: -1px;
      content: "";
      height: 2px;
      position: absolute;
      width: 100%;

      .desktop & {
        bottom: -6px;
      }
    }
  }

  .mobile & {
    padding: 18px;
  }

  .desktop & {
    flex: 1 0 auto;
    margin-right: 30px;
    padding: 5px 0;

    &:last-child {
      margin-right: 10px;
    }
  }
}

.order-tab {
  background: none;
  border: none;
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }
}

.btn-order__wrapper {
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 15px 0;
  width: 100%;

  @include respond-min($breakpoint-sm) {
    flex: 1;
    width: auto;
  }
}

.btn-order {
  transition: none;
  width: 100%;

  @include respond-min($breakpoint-sm) {
    margin-left: auto;
    width: 174px;
  }
}

.orders-control-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.orders-results-wrapper {
  background-color: #fafafa;
  width: 100%;
  height: 100%;
}

.orders-results {
  margin: 0 auto;
  max-width: 1875px;
  padding-bottom: 175px;
}

.orders-controls {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 25px;
  width: 100%;

  @include respond-min($breakpoint-sm) {
    margin: 0 auto;
    padding-bottom: 25px;
  }
}

.orders-grid {
  @extend %wrapper;

  animation: fadeIn 0.6s;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-template-rows: 1fr;
  justify-content: space-around;
  margin: 0 auto;

  // on mobile, if a dropdown is open, allow cards to be clicked on to close dropdown
  &.masked {
    pointer-events: none;

    @include respond-min($breakpoint-sm) {
      pointer-events: all;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &.hidden {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }
}

.results-message {
  margin-top: 10px;
  text-align: center;
}

.search-wrapper {
  position: relative;
  width: 100%;
  z-index: 100;
}

.orders-search-form {
  position: absolute;
  transition: width 0.3s;
  width: 0;
  will-change: width;

  &.open {
    width: 100%;

    @include respond-min($breakpoint-sm) {
      width: 500px;
    }
  }
}

.orders-dropdown-wrapper {
  display: flex;
  position: relative;
  width: 100%;

  // necessary for active sort text ellipsis, no classnames because react-click-outstide doesn't allow it
  & > div {
    min-width: 0;
  }

  @include respond-min($breakpoint-sm) {
    width: auto;
  }
}

.btn-search-orders-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.25em;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  z-index: 100;
}

.icon-search-orders {
  @extend .icon-search;

  display: block;
  height: 16px;
  width: 16px;
}

%btn-order-control {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 30px;
  padding: 0 12px;

  &.open,
  &:hover {
    background-color: #f0f0f0;
  }
}

.btn-filter {
  @extend %btn-order-control;
  @extend %active-dot;

  margin-top: 5px;
  position: relative;
}

.btn-sort {
  @extend %btn-order-control;
  margin-top: 5px;
  margin-top: 5px;
  overflow: hidden;
  padding: 0 6px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include respond-min($breakpoint-sm) {
    max-width: none;
    padding: 0 12px;
  }
}

.btn-search-orders {
  @extend %btn-order-control;
  @extend %active-dot;

  position: relative;

  .open & {
    background-color: transparent;
  }
}

.filter-menu {
  @extend %dropdown;
}

.filter-list {
  @extend %dropdown__list;
}

.filter-option {
  @extend %dropdown__option;
}

.filter-options-heading {
  @extend %dropdown__heading;
}

.icon-filter-selected {
  @extend %dropdown__selected;
}

.btn-filter-reset {
  background: none;
  border: none;
  color: #01b2f0;
  cursor: pointer;
  font-weight: 700;
  margin-left: auto;
  padding: 0 0 0 5px;
}

.filters-wrapper {
  @extend %dropdown__wrapper;
  margin-left: 50px;

  @include respond-min($breakpoint-sm) {
    position: relative;
  }
}

.sort-wrapper {
  @extend %dropdown__wrapper;
  display: flex;
  margin-left: 22px;

  @include respond-min($breakpoint-sm) {
    margin-left: 5px;
  }
}

.sort-menu {
  @extend %dropdown;
  margin-top: 40px;
}

.sort-list {
  @extend %dropdown__list;
}

.sort-options-heading {
  @extend %dropdown__heading;
}

.sort-option {
  @extend %dropdown__option;
}

.btn-filter-option {
  @extend %dropdown-option-btn;
}

.btn-sort-option {
  @extend %dropdown-option-btn;
}

.sort-option__icon {
  margin-right: 10px;
}

.icon-sort-selected {
  @extend %dropdown__selected;
}

.transactions {
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
  }
}

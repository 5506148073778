﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";
@import "../propertiesPage/_propListConstants.scss";

.farm-overview-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  transition: 0.3s ease all;

  @include respond-max($mobile-breakpoint-width) {
    width: 100%;
    max-width: 450px;
    position: absolute;
    z-index: 1000;
    background: white;
    /*  height with a navbar height: 92%;*/
    height: 85%; /* height without a navbar*/
    max-height: 850px;
    bottom: 0;
  }

  .btn {
    cursor: pointer;
  }

  .txt-small {
    font-size: 12px;
  }
}

.farm-overview-container.height-collapsed {
  max-height: 260px;
}

.farm-overview-container.height-collapsed.farm-prop-show {
  max-height: 364px;
}

.farm-overview-container.full-screen {
  max-height: 100%;
  height: 100%;
}

.farm-overview-container.no-height {
  max-height: 0px !important;
}

.farm-overview-container.display-none {
  display: none;
}

.l-r-padding {
  padding: 0 $prop-list-margin-desktop;

  @include respond-max($mobile-breakpoint-width) {
    padding: 0 $prop-list-margin-mobile;
  }
}

.border-top {
  border-top: 1px solid #efedee;

  .basic-row {
    border: none;

    .arrow-icon {
      margin-left: 15px;
    }
  }
}

.farm-overview-control-bar {
  .back-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    @include respond-max($mobile-breakpoint-width) {
      display: none;
    }

    .back-arrow {
      width: 10px;
      height: 11px;
      background-image: url("../../../images/commonIcons/icon-backArrow@2x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .control-sections {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efedee;
    padding-bottom: 20px;

    .left-info {
      .farm-title {
        font-size: 18px;
        font-weight: 900;
        margin: 5px 0;
      }
    }

    .right-controls {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .farm-control {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        border-radius: 30px;
        border: 1px solid #eaeaea;
        cursor: pointer;
      }

      .farm-icon {
        background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
      }

      .list-icon {
        background-image: url("../../../images/titlefyPro/commonIcons/icon-list@2x.png");
        background-size: 26px;
      }

      .overview-icon {
        background-image: url("../../../images/titlefyPro/commonIcons/btn-overview@2x.png");
        border: none;
        background-size: contain;
      }

      .filters-icon {
        background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
        margin: 0 10px;
      }

      .active-filter {
        background-image: url("../../../images/titlefyPro/commonIcons/icon-filter-active.png");
        background-size: 38px;
      }

      .farm-control.options-icon {
        background-color: #fafafa;
        border: none;
      }

      .dot {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: #323232;
      }
    }
  }
}

.farm-control-desktop-sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include respond-max($mobile-breakpoint-width) {
    display: none;
  }

  .sort-control {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
  }

  .mr-sm {
    margin-right: 5px;
  }
}

.farm-desktop-only {
  @include respond-max($mobile-breakpoint-width) {
    display: none;
  }
}

.farm-prop-mobile-list-control-bar {
  display: flex;
  flex-direction: column;
  margin-top: -16px;

  @include respond-min($mobile-breakpoint-width) {
    display: none;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #efefef;

    .l-r-padding {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .control {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 16px;
      width: 16px;
    }

    .icon.close {
      background-image: url("../../../images/commonIcons/icon-close@2x.png");
      position: absolute;
      left: 20px;
    }

    .icon.filter {
      background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
      margin: 0 5px 0 0;
    }

    .icon-overview_mobile {
      margin-right: 5px;
    }

    .icon.sort {
      width: 32px;
      background-size: 28px;
    }

    .divider {
      width: 2px;
      height: 24px;
      background-color: #efefef;
    }

    .farm-name,
    .control {
      margin: 12px 0;
    }

    .farm-name {
      font-size: 18px;
    }
  }

  .row.top {
    padding: 10px 0;
  }
}

.farm-overview,
.farm-prop-overview {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .sort-props-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: 0.3s all ease;
    flex-direction: column;
    overflow: hidden;

    .row {
      border-bottom: 1px solid #efefef;
      width: 100%;
      cursor: pointer;

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        margin: 10px 20px;

        .sort-type {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .sort-name {
          margin-left: 5px;
        }

        .icon {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
        }

        .icon.address {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-address@2x.png");
        }

        .icon.newest {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-newest@2x.png");
        }

        .icon.high-value {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-highest-Val@2x.png");
        }

        .icon.low-value {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-lowest-Val@2x.png");
        }

        .icon.largest {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-largest@2x.png");
        }

        .icon.smallest {
          background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-smallest@2x.png");
        }

        .icon.checkmark {
          background-image: url("../../../images/titlefyPro/commonIcons/icon-checkmark-green@2x.png");
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .sort-props-container.visible {
    max-height: 240px;
  }

  .sort-props-container.hidden {
    max-height: 0;
  }

  .icon.sort {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
  }

  .swipe-arrow {
    display: none;
    width: 46px;
    height: 30px;
    position: absolute;
    z-index: 100;
    left: 50%;
    margin-left: -23px;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px;

    @include respond-max($mobile-breakpoint-width) {
      display: block;
    }
  }

  .swipe-arrow_top-offset {
    top: -16px;
  }

  .swipe-arrow.expanded {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-swipe-down@2x.png");
  }

  .swipe-arrow.collapsed {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-swipe-up@2x.png");
  }

  .overview-info-container {
    .btn-export-farm {
      @include respond-max-height(829px) {
        display: none;
      }
    }
  }

  .main-stats-container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    @include respond-max($mobile-breakpoint-width) {
      padding-top: 18px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .stat-value.hot {
      color: $orange-hot-prop;
    }

    .dark-gray {
      color: #787878;
    }

    .stat-value {
      font-size: 24px;
    }

    .stat-name {
      margin-top: 10px;
    }
  }

  .secondary-stats-container {
    background-color: #fafafa;
    border-bottom: 1px solid #efedee;
    border-top: 1px solid #efedee;

    &.COLLAPSED {
      @include respond-max($mobile-breakpoint-width) {
        display: none;
      }
    }

    .stat-row {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 1px solid #efedee;

      .flex-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .diff-container {
          display: flex;

          .diff-percent {
            margin-right: 3px;
          }

          .diff-percent.plus {
            color: #66cc00;
          }

          .diff-percent.negative {
            color: red;
          }
        }

        .txt-small {
          font-size: 13px;
        }
      }

      .light-blue {
        color: $light-blue;
      }

      .blue {
        color: $blue;
      }

      .forward-icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../../images/titlefyPro/up-arrow@3x.png");
        width: 15px;
        height: 15px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    .stat-row.clickable {
      cursor: pointer;
    }

    .section-header {
      font-size: 16px;
      border: none;
      margin-top: 20px;
    }
  }

  .scroll-container {
    overflow-y: auto;
    width: 100%;
  }

  .overview-stats-relative {
    @include respond-max($mobile-breakpoint-width) {
      position: relative;
      height: 100%;
    }
  }

  .overview-info-container {
    @include respond-max($mobile-breakpoint-width) {
      position: absolute;
      overflow-y: auto;
      height: 100%;
    }
  }

  .scroll-container.overview-info-container.collapsed {
    overflow-y: hidden;
  }

  .farm-prop-cards-container::-webkit-scrollbar {
    display: none;
  }

  .farm-prop-cards-container::-ms-scrollbar {
    display: none;
  }

  .farm-prop-cards-container::-moz-scrollbar {
    display: none;
  }
}

.farm-overview {
  @include respond-max($mobile-breakpoint-width) {
    justify-content: space-between;
  }
}

.confirm-deletion-container {
  // position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 85%;
  background: white;
  padding-top: 40px;
  max-height: 420px;

  .confirm-title {
    font-size: 18px;
    max-width: 269px;
    text-align: center;
  }

  .btn-container {
    display: block;
  }

  .btn-container > * {
    cursor: pointer;
  }
}

.selected-farm-prop-container {
  height: 100%;
  flex: 1;
  position: relative;

  .farm-prop-info-list-container {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
  }

  .secondary-stats-container {
    width: 100%;
    position: absolute;
  }
}

.filter-action-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0;
  border-top: 1px solid #efedee;

  @include respond-max($mobile-breakpoint-width) {
    padding-top: 20px;
    border: none;
  }

  .export-icon {
    width: 16px;
    height: 16px;
    background-image: url("../../../images/titlefyPro/commonIcons/icon-export@2x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .btn {
    font-size: 12px;
    font-weight: 900;
    height: 35px;

    @include respond-max($mobile-breakpoint-width) {
      width: 100%;
    }
  }
}

.farm-display-desktop-height {
  @include respond-max-height(829px) {
    display: none;
  }
}

.filter-action-btn-container.display-mobile {
  @include respond-min-heigth(830px) {
    display: none;
  }

  .detail-card-btn {
    margin-top: 0;
  }
}

.farm-sidebar-container {
  // overflow: auto;
  &.farm-sidebar-height_COLLAPSED {
    @include respond-max($mobile-breakpoint-width) {
      height: auto;
      bottom: 0;
    }
  }

  &.farm-sidebar-height_EXPANDED {
    @include respond-max($mobile-breakpoint-width) {
      height: 80%;
      // height: auto;
      max-height: 100%;
      bottom: 0;
    }
  }

  &.farm-sidebar-height_FULL {
    height: 100%;
    max-height: 100%;
  }

  .detail-card-btn {
    margin-top: 20px;
  }
}

﻿@import "../../../../modules/_constants.scss";
@import "../../../../modules/_mixins.scss";

.calc-mini-container {
  @include respond-max($prop-detail-mobile-breakpoint) {
    order: 3;
  }

  .btn-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .property-estimateProvider {
      color: #bebebe;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      height: 40px;
      display: flex;
      align-items: center;
      max-width: 330px;
      margin: 10px 0;
      align-self: flex-start;

      @include respond-max($prop-detail-mobile-breakpoint) {
        text-align: center;
      }
    }
  }

  .calc-fields-container {
    .property-sheet-Details {
      .property-sheet-Items {
        .property-sheet-ItemContent {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          height: 40px;

          @include respond-max($prop-detail-mobile-breakpoint) {
            margin: 10px 0;
          }

          .property-sheet-ItemTotal {
            color: #323232;
            font-size: 20px;
            font-weight: 900;

            @include respond-max($prop-detail-mobile-breakpoint) {
              font-size: 16px;
            }
          }

          .property-sheet-Item {
            color: #323232;
            font-size: 16px;
            font-weight: 500;

            @include respond-max($prop-detail-mobile-breakpoint) {
              font-weight: 500;
              font-size: 15px;
            }
          }

          .calc-form {
            .calc-input {
              border: none;
              color: $light-blue;
              font-weight: 900;
              border-bottom: 2px solid #e1e1e1;
              width: 80px;
              font-size: 16px;
              text-align: right;
            }
          }

          .SalesMobile {
            @include respond-max($prop-detail-mobile-breakpoint) {
              font-weight: 900;
              font-size: 16px;
            }
          }

          .SalesPrice {
            color: #00b2f1;
            font-weight: 900;
            border-bottom: 2px solid #e1e1e1;
            width: auto;
            cursor: pointer;
            min-width: 20px;
            text-align: center;

            @include respond-max($prop-detail-mobile-breakpoint) {
              font-weight: 900;
              font-size: 16px;
            }
          }

          .closingMobile {
            @include respond-max($prop-detail-mobile-breakpoint) {
              font-size: 15px;
            }
          }

          .salesComission {
            color: #00b2f1;
            width: auto;
            font-weight: 900;
            margin-left: 10px;
            border-bottom: 2px solid #e1e1e1;
            cursor: pointer;
          }

          .SellerMobile {
            font-size: 16px;
          }
        }

        .SubTotal {
          margin: 0px !important;
        }

        .SellerPayMobile {
          @include respond-range(805px, 1920px) {
            display: none;
          }

          @include respond-max($prop-detail-mobile-breakpoint) {
            justify-content: center;
            margin: 0px;
          }
        }

        .sellPriceMobile {
          @include respond-max($prop-detail-mobile-breakpoint) {
            font-weight: 900;
            font-size: 24px;
            text-align: center;
          }

          @include respond-range(805px, 1920px) {
            display: none;
          }
        }
      }
    }
  }
}

.fade-in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

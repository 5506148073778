﻿.farmLoading-container {
  width: 100%;
  height: 100%;

  .m-t-lg {
    margin-top: 150px;
  }

  .m-b-sm {
    margin-bottom: 30px;
  }
}

.farmLoading-content {
  max-width: 600px;
  margin: 150px auto;
}

.farmLoading-progress_maxWidth {
  max-width: 300px;
  margin: auto;
}

.farmLoading-modal {
  .farmLoading-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 500px;
    text-align: center;
  }

  .icon-close-black {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 16px;
    height: 16px;
  }

  h3 {
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
  }

  p,
  ul {
    margin: 0 0 40px;
    max-width: 450px;
  }

  li {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 21px;
    text-align: left;
  }

  .btn {
    margin-bottom: 20px;
  }
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../_navbarPro.scss";
@import "../../../common/animations.scss";

.prop-photo-viewer-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .fade-in {
    animation: fadeIn 1s;
  }

  .tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;

    @include respond-max-height(900px) {
      padding: 20px 0;
    }
  }

  .tab {
    padding-bottom: 10px;
    flex: 1;
    text-align: center;
    cursor: pointer;
  }

  .tab.active {
    font-weight: 700;
    box-shadow: 0 3px 0 0 #00b2f1;
  }

  .prop-photos-container {
    width: 100%;
  }

  .prop-photos-max-width {
    max-width: 1075px;
    margin: 0 auto;
  }

  .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 2px;
    justify-content: space-between;
    max-width: 800px;
    margin: 50px auto;
  }

  .photo-grid-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;

    @include respond-min($breakpoint-sm + 1) {
      display: none;
    }
  }

  .photo-grid-mobile > img {
    height: 200px;
  }

  .photo-grid-mobile > :nth-child(5n + 1) {
    grid-column: span 2;
    height: 320px;
  }

  .active-mobile-img-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: black;
    z-index: 1001;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-close {
      position: absolute;
      top: 15px;
      left: 15px;
    }

    .react-multi-carousel-list {
      width: 90%;
      height: 90%;

      @include respond-max($breakpoint-sm) {
        display: flex;
      }
    }
  }

  .react-multi-carousel-list,
  .photo-grid {
    @include respond-max($breakpoint-sm) {
      display: none;
    }
  }

  .react-multi-carousel-item {
    transition: opacity 750ms ease-in-out;
    opacity: 0;
  }

  .react-multi-carousel-item--active {
    opacity: 1;
  }

  .react-multiple-carousel__arrow {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
  }

  .react-multiple-carousel__arrow:hover {
    background-color: #e6e6e6;
  }

  .react-multiple-carousel__arrow::before {
    @include imgCenterContain;
    height: 12px;
    width: 12px;
    margin: 0 auto;
  }

  .react-multiple-carousel__arrow--right::before {
    left: 1px;
    content: "";
    background-image: url("../../../../images/titlefyPro/commonIcons/arrows/icon-next-viewer@2x.png");
  }

  .react-multiple-carousel__arrow--left::before {
    right: 1px;
    content: "";
    background-image: url("../../../../images/titlefyPro/commonIcons/arrows/icon-back-viewer@2x.png");
  }

  .ui.image {
    width: 800px;
    cursor: pointer;
    margin: 0 auto;
    object-fit: cover;
  }

  .ui.image.thumbnail {
    width: 125px;
    height: 75px;
  }

  .ui.image.carousel {
    min-height: 400px;
    height: 600px;
  }

  .ui.image.active-mobile-img {
    height: auto;
    max-height: 450px;
  }

  .map-container {
    position: relative;
    width: calc(100% - 100px);
    min-width: 200px;
    min-height: 200px;
    margin: 0 auto;
    margin-bottom: 50px;

    @include respond-max($breakpoint-sm) {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .map-container.hidden {
    visibility: hidden;
    position: absolute;
    height: calc(100vh - #{$navbar-height});
  }

  .map-container.no-display {
    display: none;
  }

  #basic-street-view {
    width: 100%;
    height: 100%;
  }

  .svg-icon-photos {
    width: 50px;
    height: 50px;
    margin: auto;
    margin-top: 50px;
  }

  .header-5 {
    text-align: center;
    padding: 16px;
  }
}

.prop-photo-viewer-container.height-auto {
  @include respond-max($breakpoint-sm) {
    height: auto;
  }
}

﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.navbar-pro-container.upload-farm {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;

  .back-icon {
    position: absolute;
    left: 20px;
  }

  .upload-farm-cancel {
    position: absolute;
    right: 20px;
    font-size: 14px;
  }
}

.upload-farm-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  .flex {
    display: flex;
  }

  .flex-column {
    @extend .flex;
    flex-direction: column;
  }

  .justify-content-space-between {
    @extend .flex;
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .align-items-center {
    @extend .flex;
    align-items: center;
  }

  .full-width {
    width: 100%;
  }

  .max-width {
    @extend .full-width;
    max-width: 950px;
  }

  .content-max-width {
    @extend .full-width;
    max-width: 720px;
  }

  .form-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 5%;
    height: 100%;
    position: relative;
    border: 1px solid #f0f0f0;
    margin: 35px 0;
    padding: 5%;

    @include respond-max(950px) {
      border: none;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    max-width: 625px;
  }

  .title {
    font-size: 26px;
    line-height: 31px;
    font-weight: 900;
    text-align: center;
  }

  .sub-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @extend .margin-top-15;
  }

  .sub-title-sml {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .sub-title-xsm {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .txt-gray {
    color: #afafaf;
  }

  .underline {
    text-decoration: underline;
  }

  .file-drag-drop {
    height: 180px;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    transition: 0.3s ease all;
  }

  .drag-in-shadow {
    box-shadow: 0px 0px 6px 0px rgba(0, 178, 241, 0.3);
  }

  .file-upload-border {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px dashed #d4d4d4;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin: 0 auto;
    flex-direction: column;
    padding: 5%;
    text-align: center;
  }

  .pending-info-block {
    @extend .content-max-width;
    @extend .flex-column;
    @extend .align-items-center;
  }

  .file-info-block {
    width: 100%;
    margin: 50px 0;
    @extend .flex;
  }

  .file-info-text {
    @extend .flex-column;
    @extend .justify-content-space-between;
    @extend .margin-left-10;
    margin-right: 15px;
  }

  .icon-upload-gray {
    width: 35px;
    height: 35px;
  }

  .ui.progress {
    width: 100%;
  }

  .input-padding {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    max-width: 550px;
  }

  .input-group {
    margin: 10px 5px;
  }

  .data-preview-block-container {
    width: 100%;
    margin: 40px;
  }

  .data-preview-block {
    height: auto;
    min-height: 100px;
    flex: 1;
    border: 1px solid #d4d4d4;
    background-color: #f8f8f8;
    padding: 15px;
    @extend .margin-5;
    @extend .flex;
    @extend .flex-column;
    @extend .justify-content-center;
  }

  .btn-container {
    width: 100%;
    /*position: sticky;
        bottom: 0;*/
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    cursor: pointer;
  }

  .icon-blue-check,
  .icon-check-green {
    margin-bottom: 20px;
  }

  .icon-check-green {
    width: 24px;
    height: 24px;
  }

  .margin-5 {
    margin: 5px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .margin-10 {
    margin: 10px;
  }

  .margin-left-10 {
    margin-left: 10px;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .margin-bottom-25 {
    margin-bottom: 25px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .margin-top-45 {
    margin-top: 45px;
  }

  .margin-bottom-45 {
    margin-bottom: 45px;
  }

  .upload-message-block {
    max-width: 300px;
    text-align: left;
  }

  .upload-message-columns-list {
    padding-left: 15px;
  }

  .icon-download {
    background-image: url("../../../images/titlefyPro/icon-share@3x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  /* SAVE FARM DIMMER/LOADER STYLE OVERRIDES */
  .ui.inverted.dimmer {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .ui.loader {
    top: 12px;
  }
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

@mixin icon20x20 {
  width: 20px;
  height: 20px;
}

@mixin icon15x15 {
  width: 15px;
  height: 15px;
}

@mixin icon12x12 {
  width: 12px;
  height: 12px;
}

@mixin icon10x10 {
  width: 10px;
  height: 10px;
}

/*------------------------ PROPERTY ICONS ------------------*/
.icon-status-hot {
  background-image: url("../../images/titlefyPro/propertyDetails/cards/icon-hot-properties@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-status-not-hot {
  background-image: url("../../images/titlefyPro/propertyDetails/cards/icon-hot-inactive@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-house {
  background-image: url("../../images/titlefyPro/searchIcons/search-icon-prop@2x.png");
  @include imgCenterContain;
  @include icon10x10;
}

/*----------------------------------------------------------*/

.icon-alert {
  position: relative;
  display: inline-block;
  background-image: url("../../images/commonIcons/icon-alert@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-alert-circle {
  background-image: url("../../images/commonIcons/icon-alert-circle@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-saved {
  background-image: url("../../images/titlefyPro/propertyDetails/cards/icon-saved@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-saved-active {
  background-image: url("../../images/titlefyPro/propertyDetails/cards/icon-saved-active@2x.png");
  @include imgCenterContain;
  @include icon12x12;
}

.icon-notes {
  background-image: url("../../images/titlefyPro/commonIcons/icon-notes@2x.png");
  @include imgCenterContain;
  @include icon12x12;
}

.icon-export {
  background-image: url("../../images/titlefyPro/commonIcons/icon-export@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

/*------------------*/
/* ARROWS */
/* no tail, ex. < */
.back-icon {
  background-image: url("../../images/commonIcons/icon-backArrow@2x.png");
  @include imgCenterContain;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.icon-back-photo-viewer {
  background-image: url("../../images/titlefyPro/commonIcons/arrows/icon-back-viewer@2x.png");
  @include imgCenterContain;
  @include icon15x15;
  cursor: pointer;
}

/* no tail, ex. > */
.icon-forward-photo-viewer {
  background-image: url("../../images/titlefyPro/commonIcons/arrows/icon-next-viewer@2x.png");
  @include imgCenterContain;
  @include icon15x15;
  cursor: pointer;
}

/* arrow with tail, ex. <- */
.icon-back-arrow {
  background-image: url("../../images/commonIcons/icon-back-arrow@2x.png");
  @include imgCenterContain;
  @include icon20x20;
  cursor: pointer;
}

/* no tail, ex. < */
.icon-back-arrow-gray {
  background-image: url("../../images/titlefyPro/commonIcons/arrows/icon-back-carousel@2x.png");
  @include imgCenterContain;
  @include icon20x20;
  cursor: pointer;
}

/* no tail ex. >*/
.icon-forward-arrow-gray {
  background-image: url("../../images/titlefyPro/commonIcons/arrows/icon-next-carousel@2x.png");
  @include imgCenterContain;
  @include icon20x20;
  cursor: pointer;
}

.arrow-icon.gray {
  width: 12px;
  height: 12px;
  background-image: url("../../images/titlefyPro/up-arrow@3x.png");
  @include imgCenterContain;
}

.arrow-icon.gray.right {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*------------------*/

.icon-clear {
  background-image: url("../../images/titlefyPro/searchIcons/search-icon-clear@2x.png");
  height: 14px;
  width: 14px;
  @include imgCenterContain;
}

.icon-close {
  background-image: url("../../images/commonIcons/icon-close@2x.png");
  @include imgCenterContain;
  @include icon15x15;
  cursor: pointer;
}

.icon-close-black {
  background-image: url("../../images/titlefyPro/commonIcons/icon-close-black@2x.png");
  @include imgCenterContain;
  @include icon12x12;
  cursor: pointer;
}

.icon-dropdown {
  background-image: url("../../images/commonIcons/dropdown-arrow@2x.png");
  @include imgCenterContain;
  width: 12px;
  height: 12px;
}

.icon-blue-plus {
  background-image: url("../../images/titlefyPro/orders/actions/icon-add-bluePlus@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

/*----------------*/
/* CHECKMARKS */
.icon-blue-check {
  background-image: url("../../images/titlefyPro/orders/common/icon-checkmark-complete@2x.png");
  @include imgCenterContain;
  @include icon15x15;
  width: 24px;
  height: 24px;
}

.icon-check-gray {
  background-image: url("../../images/commonIcons/icon-checkmark-gray@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

/* has green background */
.icon-check-green {
  background-image: url("../../images/titlefyPro/orders/common/icon-checkmark-lg@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-basic-check-green {
  background-image: url("../../images/titlefyPro/commonIcons/icon-checkmark-green@2x.png");
  @include imgCenterContain;
  @include icon12x12;
}

/* has a transparent background */
.icon-check-green-complete {
  background-image: url("../../images/titlefyPro/commonIcons/icon-checkmark-complete@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-check-white {
  background-image: url("../../images/commonIcons/checkmarks/icon-checkmark.white@2x.png");
  @include imgCenterContain;
  @include icon12x12;
}

.icon-edit {
  background-image: url("../../images/commonIcons/icon-edit.png");
  @include imgCenterContain;
  @include icon15x15;
  cursor: pointer;
}

.icon-filter {
  background-image: url("../../images/titlefyPro/commonIcons/icon-filter@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-locked {
  background-image: url("../../images/titlefyPro/orders/common/icon-secure@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-unlocked {
  background-image: url("../../images/titlefyPro/orders/common/icon-unlocked-unmasked@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-upload-blue {
  background-image: url("../../images/titlefyPro/orders/actions/icon-share-quote@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-upload-btn-white {
  background-image: url("../../images/titlefyPro/commonIcons/btn-upload-farm@2x.png");
  @include imgCenterContain;
  @include icon15x15;
}

.icon-upload-gray {
  background-image: url("../../images/titlefyPro/commonIcons/icon-upload-farm@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-farm {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/btn-farming@2x.png");
}

.icon-user-location {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/map-button-mylocation@2x.png");
}

.icon-user-location_active {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/map-my-location-active@2x\ \(1\).png");
}

.icon-layers {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/map-button-layers@2x.png");
}

.icon-reset {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/icon-reset@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-preview {
  background-image: url("../../images/titlefyPro/googleMaps/controlIcons/icon-preview@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

/*---------------*/
/* SORTING */

.icon-sort-desc {
  background-image: url("../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
  @include imgCenterContain;
  width: 28px;
  height: 28px;
}

.icon-sort-address {
  background-image: url("../../images/titlefyPro/commonIcons/sorting/icon-sort-address@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-sort-open-asc {
  background-image: url("../../images/titlefyPro/commonIcons/sorting/icon-sort-open-ascending@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-sort-open-desc {
  background-image: url("../../images/titlefyPro/commonIcons/sorting/icon-sort-open-descending@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-sort-closed-asc {
  background-image: url("../../images/titlefyPro/commonIcons/sorting/icon-sort-closed-ascending@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-sort-closed-desc {
  background-image: url("../../images/titlefyPro/commonIcons/sorting/icon-sort-closed-descending@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

/*----------------*/

.icon-overflow {
  cursor: pointer;
  width: 25px;
  height: 20px;
  background-image: url("../../images/adminPage/icon-overflow@2x.png");
  @include imgCenterContain;
}

.icon-overflow-gray-circle {
  cursor: pointer;
  background-image: url("../../images/titlefyPro/commonIcons/btn-overflow-farming@2x.png");
  width: 36px;
  height: 36px;
  @include imgCenterContain;
}

.icon-overflow-white-dot {
  cursor: pointer;
  background-image: url("../../images/titlefyPro/commonIcons/icon-overflow-farming@2x.png");
  @include icon20x20;
  @include imgCenterContain;
}

.icon-overflow-vertical {
  cursor: pointer;
  background-image: url("../../images/adminPage/icon-overflow-vertical@2x.png");
  @include icon20x20;
  @include imgCenterContain;
}

/* black styled avatar */
.icon-no-profile {
  width: 50px;
  height: 50px;
  background-image: url("../../images/adminPage/graphic-noProfile.png");
  @include imgCenterContain;
}

.icon-appstore {
  background-image: url("../../images/homePage/icon-appstore@2x.png");
  @include imgCenterContain;
  width: 125px;
  height: 44px;
}

.icon-avatar-gray {
  background-image: url("../../images/adminPage/icon-avatar@2x.png");
  @include imgCenterContain;
  width: 50px;
  height: 50px;
}

.icon-search {
  background-image: url("../../images/titlefyPro/commonIcons/icon-search@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-search-active {
  background-image: url("../../images/titlefyPro/searchIcons/icon-props-active@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-document {
  background-image: url("../../images/titlefyPro/orders/common/icon-document@2x.png");
  @include imgCenterContain;
  width: 40px;
  height: 40px;
}

.icon-document-gray {
  background-image: url("../../images/titlefyPro/commonIcons/icon-document-gray@2x.png");
  @include imgCenterContain;
  width: 40px;
  height: 40px;
}

.icon-trashcan {
  background-image: url("../../images/titlefyPro/commonIcons/icon-delete-trashcan@2x.png");
  cursor: pointer;
  @include imgCenterContain;
  @include icon20x20;
}

.icon-trashcan-white {
  background-image: url("../../images/titlefyPro/commonIcons/icon-trashcan@2x.png");
  cursor: pointer;
  @include imgCenterContain;
  @include icon20x20;
}

.icon-photos {
  background-image: url("../../images/titlefyPro/commonIcons/btn-photos-map@2x.png");
  @include imgCenterContain;
  width: 40px;
  height: 40px;
}

.icon-list {
  background-image: url("../../images/titlefyPro/commonIcons/icon-list@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-handle-pipes {
  background-image: url("../../images/titlefyPro/commonIcons/icon-handle@2x.png");
  cursor: pointer;
  @include imgCenterContain;
  @include icon15x15;
}

.icon-orders {
  background-image: url("../../images/titlefyPro/orders/common/icon-orders@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-orders-active {
  background-image: url("../../images/titlefyPro/orders/common/icon-orders-active@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-street-view {
  background-image: url("../../images/titlefyPro/googleMaps/btn-streetView@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-map {
  background-image: url("../../images/titlefyPro/commonIcons/icon-map@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-zoning {
  background-image: url("../../images/titlefyPro/propertyIcons/icon-zoning (1).png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-password-show {
  background-image: url("../../images/auth/icon-show@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

.icon-password-hidden {
  background-image: url("../../images/auth/icon-hide@2x.png");
  @include imgCenterContain;
  @include icon20x20;
}

/*--------------------*/
/* Larger Images */

.img-prop-placeholder {
  background-image: url("../../images/titlefyPro/propertyDetails/graphic-property-placeholder@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.img-prop-placeholder.no-lat-lng {
  background-image: url("../../images/titlefyPro/propertyDetails/graphic-property-placeholder@2x.png") !important;
}

/*--------------------------*/
/* CONSUMER WEBSITE LOGOS */

.icon-zillow {
  background-image: url("../../images/titlefyPro/propertyIcons/icon-zillow@2x.png");
  @include imgCenterContain;
  width: 24px;
  height: 24px;
}

.icon-realtor {
  background-image: url("../../images/titlefyPro/propertyIcons/icon-realtor@2x.png");
  @include imgCenterContain;
  width: 24px;
  height: 24px;
}

.icon-trulia {
  background-image: url("../../images/titlefyPro/propertyIcons/icon-trulia@2x.png");
  @include imgCenterContain;
  width: 24px;
  height: 24px;
}

.icon-check {
  display: block;
  fill: #bebebe;
  height: 25px;

  &.green {
    fill: $bright-green;
  }

  &.black {
    fill: #000;
  }
}

.icon-warning {
  fill: #f0bd14;

  &.red {
    fill: $red;
  }

  &.pink {
    fill: #ff4472;
  }
}

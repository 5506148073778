@import "./_variables.module.scss";

:root {
  #{$partnerMainColor}: black;
  #{$partnerSecondaryColor}: black;
}

.partner-main-color {
  color: var($partnerMainColor);
}

.partner-main-color_background {
  background-color: var($partnerMainColor);
}

.partner-secondary-color {
  color: var($partnerSecondaryColor);
}

.partner-secondary-color_background {
  background-color: var($partnerSecondaryColor);
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";
@import "./_partnerRow.scss";
@import "../../_adminPageContainer.scss";

.partners-container-content {
  min-width: $admin-body-min-width;
  max-width: $admin-body-max-width;
  padding-bottom: 40px;
}

.partner-client-container-content {
  min-width: $admin-body-min-width;
  max-width: $admin-body-max-width;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 40px;
}

.partners-container-title {
  font-size: 22px;
  font-weight: bold;
  margin-left: 140px;
  padding-top: 34px;
  margin-bottom: 36px;
}

.partners-filter-wrap {
  display: flex;
}

.partners-container-header-group-wrapper {
  max-width: 1900px;
  margin-left: 140px;
  margin-right: 140px;
}

.partners-container-header-group-wrapper.sticky-scroll {
  position: sticky;
  top: 0;
}

.partners-container-header-group {
  display: flex;
  justify-content: space-between;
  max-width: 1900px;
  min-width: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f4f4f4;
  margin-bottom: 5px;
  padding-right: 10px;
}

.partners-client-container-header-group {
  display: flex;
  max-width: 1300px;
  min-width: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.partners-container-header {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.id-column-min-width {
  min-width: 100px;
  flex: 0.5;
}

.partners-container-header-text {
  font-size: 14px;
  font-weight: 600;
}

.arrow-icon-headers {
  background-image: url("../../../../../images/adminPage/icon-columnSort-inactive@2x.png");
  width: 10px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 3px;
  margin-left: 10px;
}

.partners-container-header-long-name {
  padding-left: 0px;
  padding-right: 25px;
}

.partners-container-header-id {
  width: 100px;
}

.partners-client-container-header-group {
  .partners-clients-container-header-long-name {
    @extend .partner-row-name;
  }
}

.partners-container-header-long {
  padding-left: 25px;
  padding-right: 25px;
}

.partners-main-title {
  font-size: 22px;
  font-weight: bold;
  padding-left: 140px;
  padding-top: 60px;
  margin-bottom: 25px;
  line-height: 40px;
}
.client-main-title {
  font-size: 22px;
  font-weight: bold;
  padding-top: 60px;
  margin-bottom: 25px;
  line-height: 40px;
}
.client-companies-header-group {
  display: flex;
  justify-content: space-between;
  margin-right: 140px;
}

.add-client-btn {
  margin-top: 40px;
}

.partner-add-company-btn {
  cursor: pointer;
  margin-right: 140px;
}

.add-client-margin-top {
  margin-top: 10px;
}

.admin-partner-search-btn-group {
  display: flex;
  justify-content: space-between;
}

.admin-partner-search-btn-group {
  margin-left: 140px;
  margin-bottom: 40px;

  .users-searchIcon {
    background-image: url("../../../../../images/adminPage/icon-search.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 5px;
    top: 9px;
  }
}

.your-company-margin {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

$block-margin-top-btm: 30px;
$spacing-lg: 45px;

.order-detail-container {
  width: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /************/
  /* Text Styles */
  .address-header {
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;

    @include respond-max($breakpoint-xsm) {
      font-size: 22px;
    }
  }

  .section-header {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0;
  }

  .sub-title {
    font-size: 16px;
  }

  .txt-sm {
    font-size: 13px;
  }

  .txt-xsm {
    font-size: 12px;
  }

  .underline {
    text-decoration: underline;
  }
  /************/
  .l-r-pad {
    width: 100%;
    padding: 0 5%;
  }

  .top-padding {
    padding-top: 20px;
  }

  .top-bottom-margin {
    margin: $block-margin-top-btm 0;
  }

  .max-width {
    max-width: 900px;
  }

  .full-width {
    width: 100%;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .open-close-date {
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .map-photo-container {
    display: flex;
    width: 100%;
    max-height: 500px;
    min-height: 250px;
    height: 30vw;
  }

  .map-photo-container.has-prop-photo {
    @include respond-max($breakpoint-sm) {
      .google-map {
        display: none;
      }
    }
  }

  .google-map {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .order-property-map-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .prop-photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    flex: 0.5;

    @include respond-max($breakpoint-sm) {
      flex: 1;
    }
  }

  .img-prop-placeholder {
    width: 100%;
    margin: 0 auto;

    @include respond-max(404px) {
      background-size: cover;
    }
  }

  .prop-photo.full-width {
    flex: 1;
  }

  .partner-logo,
  .logo-titlefy {
    width: 150px;

    @include respond-max($breakpoint-xsm) {
      width: 100px;
    }
  }

  .basic-order-info {
    .basic-row {
      padding: 15px 0;

      .basic-row-name,
      .boldest {
        font-size: 16px;

        @include respond-max($breakpoint-xsm) {
          font-size: 14px;
        }
      }
    }

    .basic-row-list {
      margin-bottom: $block-margin-top-btm;
    }
  }

  .ui.progress,
  .ui.progress .bar {
    border-radius: 10px;
  }

  .ui.progress {
    margin: 20px 0;

    .bar {
      height: 20px !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .ui.progress.complete {
    .bar {
      border-radius: 10px;
    }
  }

  .contact-section-margin {
    margin: $block-margin-top-btm 0;
  }

  .progress-container {
    width: 100%;
    background: #fafafa;
  }

  .progress-row {
    position: relative;
    border: dashed 1.25px #e1e1e1;
    border-top: none;
    border-bottom: none;
    border-right: none;

    .icon-check-gray,
    .icon-check-green {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 18px;
      left: -13px;
    }

    .padded-content {
      padding: 20px 0 20px 0;
      border-bottom: 1px solid #e1e1e1;
      margin-left: 40px;
    }
  }

  .progress-list {
    padding-left: 12px;
    margin-bottom: 30px;

    div:first-child {
      .icon-check-gray,
      .icon-check-green {
        top: 0;
      }

      .padded-content {
        padding-top: 0;
      }
    }

    div:last-child {
      .icon-check-gray,
      .icon-check-green {
        bottom: 0;
      }

      .padded-content {
        padding-bottom: 0;
        border: none;
      }
    }
  }

  .basic-row-list {
    div:last-child {
      border-bottom: none;
    }

    .basic-row-grid.details > .basic-row {
      border-bottom: 1px solid #e1e1e1;
    }
  }

  .basic-row-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    grid-column-gap: 2em;
  }

  .contacts-list {
    div:last-child {
      margin-bottom: 0;
    }
  }

  .copy-text {
    text-decoration: underline;
    cursor: pointer;
    color: #129ef2;
    line-height: 14px;
  }

  .view-more-less {
    text-decoration: underline;
    cursor: pointer;
    color: #4b4b4b;
    line-height: 22px;
    font-size: 14px;
  }

  .hide-overflow-text {
    display: block;
    display: -webkit-box;
    height: 39px;
    font-size: 14px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.order-badge-row {
  display: flex;
  justify-content: space-between;
  height: 24px;
  position: relative;
  top: -14px;
  width: 100%;

  .badge {
    width: auto;
    border-radius: 2px 0 0 2px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }

  .badge.order-description {
    background-color: #000;
    color: #fafafa;
  }

  .badge.order-status {
    background-color: #fafafa;
    color: #000;
  }
}

.order-status-badge {
  align-items: center;
  background-color: #5ae995;
  border-radius: 2px;
  color: #fafafa;
  display: flex;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  min-width: 70px;
  padding: 0 8px;

  &.closed {
    background-color: #34495e;
  }

  &.canceled {
    background-color: #bebebe;
  }
}

.order-status-badge__date {
  margin-left: 5px;
}

.order-filters {
  .scroll {
    padding: 20px 6%;

    .sub-title {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.order-modal-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 30px;
  border-bottom: 1px solid #e1e1e1;

  .block-left,
  .block-right {
    position: absolute;
    cursor: pointer;
  }

  .block-left {
    left: 6%;
  }

  .block-right {
    right: 6%;
  }

  .icon-close {
    position: absolute;
    left: 6%;
  }
}

.order-detail-container.with-sidebar {
  animation: fadeIn 1s;
  display: block;
  padding-bottom: 0;

  p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }

  .tc-order-details-bg-wrapper {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0;
    position: relative;
  }

  .full-bleed-grey {
    border-top: 1px solid #efedee;
    background-color: #f9f9f9;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }

  .tc-order-details-wrapper {
    margin: 0 auto;
    max-width: 1400px;

    .tc-order-details-content {
      padding: 0 20px;

      @include respond-min($breakpoint) {
        width: 66.6%;
      }
    }
  }

  .lower-details-section {
    @extend .tc-order-details-wrapper;

    margin-top: 45px;
  }

  .prop-address-one {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;

    @include respond-min($breakpoint) {
      font-size: 30px;
      font-weight: 700;
      margin: 5px 0;
    }
  }

  .prop-address-two {
    @include respond-min($breakpoint) {
      font-size: 16px;
      color: #000;
    }
  }
  .prop-address-three {
    @include respond-min($breakpoint) {
      font-size: 16px;
      color: $lighter-gray;
    }
  }

  .tc-detail-address {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    min-height: 80px;

    @include respond-min($breakpoint) {
      margin-top: 30px;
    }
  }

  .icon {
    display: inline-block;
    margin-right: 10px;
    vertical-align: text-top;

    &.yellow {
      fill: $warning-yellow;
    }

    &.green {
      fill: $bright-green;
    }

    &.red {
      fill: $error-red;
    }
  }

  .icon-result {
    flex: 1 0 24px;
    height: 24px;
    margin-right: 15px;
    width: 24px;

    @include respond-min($breakpoint) {
      flex: 1 0 27px;
      height: 27px;
      margin-right: 18px;
      width: 27px;
    }
  }

  .amenity-description {
    font-size: 13px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    @include respond-min($breakpoint) {
      font-size: 15px;
    }
  }

  .tc-detail-numbers {
    margin-top: 20px;

    p {
      @include respond-max($breakpoint) {
        font-size: 13px;
      }
    }
  }

  .tc-no-data {
    border-bottom: 1px solid #efedee;
    color: #c0c0c0;
    padding-bottom: 15px;
  }

  .tc-data-section {
    margin-top: 10px;

    & + .tc-data-section {
      margin-top: 50px;
    }
  }

  .tc-detail-info {
    max-width: 200px;
    text-align: right;

    @include respond-min($breakpoint) {
      max-width: none;
    }
  }

  .tc-results {
    margin-top: 30px;

    @include respond-min($breakpoint) {
      margin-top: 50px;
    }
  }

  .tc-result {
    display: flex;
    // position relative needed for tooltip on mobile
    position: relative;
  }

  .tc-status {
    font-size: 20px;
    display: inline-block;
    font-weight: 700;
    margin: 2px 0 15px;
    vertical-align: text-top;

    @include respond-min($breakpoint) {
      font-size: 24px;
      font-weight: 700;
      vertical-align: -10px;
    }
  }

  .tc-results-header {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 40px;

    &.inline {
      display: inline;
    }

    @include respond-min($breakpoint) {
      font-size: 30px;
    }
  }

  .insurful-results__header {
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 20px;

    @include respond-min($breakpoint) {
      font-size: 32px;
    }
  }

  .tc-results-subheader {
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;

    @include respond-min($breakpoint) {
      font-size: 24px;
      font-weight: 700;
      margin-top: 40px;
    }
  }

  .insurful-results__subheader {
    @extend .tc-results-subheader;

    margin-top: 56px;
  }

  .tc-results-content {
    margin: 40px 0;

    @include respond-min($breakpoint) {
      padding-bottom: 30px;
    }
  }

  .tc-detail-title {
    @include respond-min($breakpoint) {
      display: inline;
      margin-right: 5px;

      ~ p {
        display: inline;
      }
    }
  }

  .tc-mobile-ctas {
    border-bottom: 1px solid #efedee;
  }

  .tc-detail-link {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 10px auto 25px;
    padding: 5px 0;
    text-align: center;
    text-decoration: underline;

    @include respond-min($breakpoint) {
      max-width: 330px;
    }
  }

  .tc-analysis-list {
    margin-bottom: 40px;
  }

  .analysis-item {
    border-bottom: 1px solid #efedee;
    display: flex;
    padding: 20px 0;
  }

  .analysis-status {
    margin-top: 15px;

    @include respond-min($breakpoint) {
      flex: 1 1 auto;
      margin: 0 auto;
      text-align: right;
    }

    &.inline {
      flex: 1 1 auto;
      margin: 0 auto;
      text-align: right;
    }
  }

  .status-text {
    font-weight: 700;
    margin-right: 5px;
    text-transform: uppercase;

    @include respond-min($breakpoint) {
      display: block;
      margin-right: 0;
    }
  }

  .analysis-status-wrapper {
    margin-top: 3px;
    width: 100%;

    @include respond-min($breakpoint) {
      display: flex;
    }

    &.inline {
      display: flex;
    }
  }

  .consumer-count {
    margin: 3px 17px 0 0;
  }

  .tc-analysis-row {
    @extend .data-list__row;
  }

  .order-details-footer {
    background-color: #f9f9f9;
    color: #afafaf;
    margin-top: 40px;
    padding: 40px 20px;
    min-height: 100px;
  }

  .order-details-footer__content {
    max-width: 1440px;
    margin: 0 auto;

    @include respond-min($breakpoint) {
      text-align: center;
    }
  }

  .order-details-footer__text {
    color: #afafaf;
    font-size: 11px;
    margin-bottom: 15px;
  }

  .order-details-link {
    text-decoration: underline;
  }

  .fd-sidebar {
    @include desktop-only($breakpoint);

    height: 100%;
    padding: 0 20px;
    position: absolute;
    right: 10px;
    top: -85px;
    width: 33%;
  }

  .fd-sidebar__content {
    align-items: center;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    min-height: 250px;
    padding: $spacing-lg 20px;
    position: sticky;
    text-align: center;
    top: 115px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
      rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }

  .fd-sidebar__text {
    font-size: 16px;
  }

  .sidebar-icon {
    display: block;
    height: 36px;
    width: 36px;
    margin: 0 auto;
  }

  .btn-tc-refresh {
    &:disabled {
      cursor: not-allowed;
      color: #000;
    }
  }

  .fd-sidebar__subtext {
    font-size: 16px;
    color: $lighter-gray;
  }

  .btn-tc {
    display: block;
    margin-bottom: 10px;

    &:first-of-type {
      margin-top: 15px;
    }
  }

  .btn-sidebar {
    max-width: 330px;
    width: 100%;
  }

  .btn-decision-download {
    margin: 5px 0 30px;
  }

  .od-sidebar__separator {
    border: 1px solid #eaeaea;
    border-width: 1px 0 0 0;
    margin: 0;
    max-width: 330px;
  }

  .m-b-lg {
    margin-bottom: $spacing-lg;
  }

  .m-b-md {
    margin-bottom: 30px;
  }

  .m-b-sm {
    margin-bottom: 24px;
  }

  .m-b-xsm {
    margin-bottom: 10px;
  }

  .p-0 {
    padding: 0;
  }

  .mh-0 {
    min-height: 0;
  }

  .fd-sidebar__error {
    line-height: 1.5;
    margin: 35px 0 20px;
  }

  .tc-error-header {
    display: inline;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .tc-error-text {
    line-height: 1.5;
    padding: 35px 0 100px;
  }

  .icon-error {
    height: 18px;
    margin-right: 5px;
    width: 20px;
  }

  .tc-other-props {
    margin-top: 40px;
  }
}

.od-update {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 330px;
  padding: 75px 20px;
  text-align: center;

  @include respond-min($breakpoint-sm) {
    max-width: none;
    padding: 100px;
  }
}

.od-update__header {
  font-size: 26px;

  @include respond-min($breakpoint-sm) {
    font-size: 28px;
  }
}

.od-update__text {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  max-width: 530px;

  @include respond-min($breakpoint-sm) {
    font-size: 18px;
  }
}

.od-update__btn-continue {
  margin-top: 50px;
  width: 100%;

  @include respond-min($breakpoint-sm) {
    width: 325px;
  }
}

.underwriter-result {
  font-size: 22px;
  display: inline-block;
  font-weight: 700;
  margin: 2px 0 0 0;
  vertical-align: -7px;

  @include respond-min($breakpoint) {
    font-size: 32px;
    vertical-align: -13px;
  }
}

.data-list__row {
  border-bottom: 1px solid #efedee;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;

  &.align-right p:last-child {
    text-align: right;
  }
}
.data-list__row_with_underline {
  border-bottom: 1px solid #efedee;

  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
  }
}
.data-list__row__underline {
  margin-top: -18px;
  margin-bottom: 16px;
  color: #aaaaaa;
  font-size: 12px;
}

.od-property-description {
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0;

  @include respond-min($breakpoint-sm) {
    margin: 10px 0 20px;
  }
}

.od-mobile-ctas {
  @include mobile-only($breakpoint, flex);

  border-bottom: 1px solid #efedee;
  flex-direction: column;
  margin: 0 auto;
  max-width: 330px;
}

.insurful-results__content {
  margin: 20px 0;

  .dl-data-table {
    .view {
      margin: auto;
      width: 100%;
    }

    .wrapper {
      position: relative;
      overflow: auto;
      white-space: nowrap;
    }

    .sticky-col {
      position: sticky;
      background-color: white;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th {
      font-weight: normal;
      text-align: right;
      padding: 10px 10px 10px 0;

      &:first-of-type {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        left: 0px;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    td {
      border-bottom: 1px solid #eaeaea;
      text-align: right;
      padding: 10px 10px 10px 0;

      &:first-of-type {
        border-right: 1px solid #eaeaea;
        text-align: left;
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        left: 0px;
      }

      &:not(:first-of-type) {
        font-weight: bold;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;

    &.green {
      background-color: #17b24a;
    }

    &.red {
      background-color: #e72b32;
    }
  }
}

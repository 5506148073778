﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";
@import "../_navbarPro.scss";

.profile-settings-container {
  width: 100%;

  .profile-settings-max-width {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
  }

  .profile-settings-navbar-container {
    height: $navbar-height;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 3;

    .icon-back-arrow {
      position: absolute;
      left: 5%;
    }

    .title {
      font-size: 16px;
    }
  }

  .property-name-container {
    z-index: 1001;
  }

  .settings-main {
    padding: 0 5%;
    max-width: 1600px;
    display: flex;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      display: block;
      text-align: center;
    }

    .profile-title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 800;
      color: #152935;

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        position: relative;
      }
    }

    .profile-section {
      width: 40%;
      padding-top: 50px;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        width: 100%;
      }

      .image-profile {
        text-align: center;
        padding-top: 35px;
        margin-left: 55px;
        height: 129px;
        width: 100px;
        cursor: pointer;
        position: relative;

        @include respond-max($breakpoint-md) {
          margin: 0 auto;
        }

        .container {
          position: relative;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          display: block;
          transition: 0.5s ease;
          backface-visibility: hidden;
          background-color: currentColor;
          height: 100px;

          .image {
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s ease;
            backface-visibility: hidden;
          }

          .profile-image {
            height: 100px;
            width: 100px;
            background-image: url("../../../images/titlefyPro/avatar-default@3x.png");
            background-repeat: no-repeat;
            background-size: 110% 111%;
            background-position: center;
            align-self: center;
          }

          .middle {
            transition: 0.5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
          }
        }

        .container:hover .image {
          opacity: 0.3;
        }

        .container:hover .middle {
          opacity: 1;
        }

        .middle {
          transition: 0.5s ease;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;

          .uplaod_button {
            background-image: url("../../../images/titlefyPro/upload_button@3x.png");
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            align-self: center;
          }
        }
      }

      .form-group {
        /* make sure this width is only set to the auth forms, not the homepage forms */
        width: 100% !important;
        position: relative;
        padding-top: 16px;
        padding-bottom: 10px;
        margin-bottom: 16px;
        top: 0;

        .label-back {
          z-index: 0 !important;
        }

        .animated-label {
          display: flex;
          align-items: flex-start;
          position: absolute;
          top: 16px;
          left: 0;
          bottom: 0;
          z-index: 2;
          width: 100%;
          font-weight: 600;
          cursor: text;
          transition: 0.2s ease all;
          margin: 0;
          padding-left: 5px;
          padding-bottom: 5px;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          font-size: 16px;
          letter-spacing: 0.79px;
          color: #a6b1b9;

          &:after {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 45%;
            height: 2px;
            width: 10px;
            visibility: hidden;
            background-color: $light-blue;
            transition: 0.2s ease all;
          }
        }

        &.not-empty {
          .animated-label {
            top: -4px;
            font-size: 13px;
            color: black;
            opacity: 1;
          }
        }

        .form-control {
          width: 100%;
          color: black;
          font-size: 16px;
          font-weight: 700;
          position: relative;
          border-radius: 0;
          border-width: 0 0 1px;
          border-bottom-color: rgba(0, 0, 0, 0.25);
          height: auto;
          padding: 3px 0 5px 5px;
          outline: none;
          caret-color: $light-blue;

          &:focus {
            box-shadow: none;
            border-bottom-color: rgba(0, 0, 0, 0.12);

            ~ .animated-label {
              top: -4px;
              opacity: 1;
              /*color: $light-gray;*/
              color: black;
              font-size: 12px;

              &:after {
                visibility: visible;
                width: 100%;
                left: 0;
              }

              .icon-alert {
                bottom: 5px;
              }
            }
          }
        }

        .invalid {
          border-bottom-color: red;
        }
      }

      .error-label:after {
        background-color: $red !important;
        /*content: 'Please enter a correct email address';
				color: $red;
				padding-left: 5px;*/
      }

      .error-message {
        text-align: left;
        padding-left: 5px;
        padding-top: 5px;
        color: $red;
        width: 70%;
        min-width: 260px;
        height: 24px;
      }

      .error-message.multiple-errors {
        height: auto;

        ul {
          margin-top: 0;
          padding-left: 10px;
        }
      }

      .not-empty {
        .invalid ~ .animated-label {
          .icon-alert {
            bottom: 5px;
          }
        }
      }

      .button-group {
        padding-right: 10%;

        @include respond-max($breakpoint-md) {
          padding-right: 0;
        }

        .disabled-saveChnage-main {
          width: 100%;
          max-width: 330px;
          border-radius: 30px;
          background-color: #e5e9ed;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
          font-weight: 900;
          padding-top: 15px;
          padding-bottom: 15px;
          cursor: not-allowed;
          margin-bottom: 100px;
          margin-top: 20px;
          display: none;
        }

        .saveChnage-main {
          width: 100%;
          max-width: 330px;
          outline: none;
          border: none;
          border-radius: 30px;
          background-color: #00b2f1;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
          font-weight: 900;
          padding-top: 15px;
          padding-bottom: 15px;
          cursor: pointer;
          margin-bottom: 100px;
          margin-top: 20px;
        }
      }
    }

    .form-main {
      padding-top: 60px;
      padding-right: 10%;

      @include respond-max($breakpoint-md) {
        padding: 60px 0 0 0;
      }
    }

    .password-section {
      width: 60%;
      padding-top: 50px;
      background-color: #f8f8f8;
      padding-left: 25px;
      padding-right: 25px;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        padding-bottom: 40px;
      }

      .change-password-main {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #efedee;

        .change-pass-text {
          font-size: 15px;
          line-height: 18px;
          font-weight: 500;
        }

        .change-pass-image-down {
          background-image: url("../../../images/titlefyPro/down-arrow@3x.png");
          height: 10px;
          width: 17px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          align-self: center;
          cursor: pointer;
        }

        .change-pass-image-up {
          background-image: url("../../../images/titlefyPro/up-arrow@3x.png");
          height: 10px;
          width: 17px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          align-self: center;
          cursor: pointer;
        }

        .ui.toggle.checkbox input:checked ~ .box:before,
        .ui.toggle.checkbox input:checked ~ label:before {
          background-color: #00b2f1 !important;
        }

        .ui.toggle.checkbox input:checked ~ .box:after,
        .ui.toggle.checkbox input:checked ~ label:after {
          height: 18px;
          width: 18px;
          margin-top: 1px;
          left: 1.15rem;
        }

        .ui.toggle.checkbox .box:before,
        .ui.toggle.checkbox label:before {
          width: 2.5rem;
          margin-right: -10px;
        }
      }

      .password-form {
        width: 70%;
        padding-top: 30px;

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 100%;
        }

        .form-group {
          /* make sure this width is only set to the auth forms, not the homepage forms */
          width: 100% !important;
          position: relative;
          padding-top: 16px;
          padding-bottom: 10px;
          margin-bottom: 16px;
          top: 0;

          .label-back {
            z-index: 0 !important;
          }

          .animated-label {
            display: flex;
            align-items: flex-start;
            position: absolute;
            top: 16px;
            left: 0;
            bottom: 0;
            z-index: 2;
            width: 100%;
            font-weight: 600;
            cursor: text;
            transition: 0.2s ease all;
            margin: 0;
            padding-left: 5px;
            padding-bottom: 5px;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            font-size: 16px;
            letter-spacing: 0.79px;
            color: #a6b1b9;

            &:after {
              content: "";
              position: absolute;
              bottom: 10px;
              left: 45%;
              height: 2px;
              width: 10px;
              visibility: hidden;
              background-color: $light-blue;
              transition: 0.2s ease all;
            }
          }

          &.not-empty {
            .animated-label {
              top: -4px;
              font-size: 13px;
              color: black;
              opacity: 1;
            }
          }

          .form-control {
            width: 100%;
            color: black;
            font-size: 16px;
            font-weight: 700;
            position: relative;
            background: #f8f8f8;
            border-radius: 0;
            border-width: 0 0 1px;
            border-bottom-color: rgba(0, 0, 0, 0.25);
            height: auto;
            padding: 3px 0 5px 5px;
            outline: none;
            caret-color: $light-blue;

            &:focus {
              box-shadow: none;
              border-bottom-color: rgba(0, 0, 0, 0.12);

              ~ .animated-label {
                top: -4px;
                opacity: 1;
                /*color: $light-gray;*/
                color: black;
                font-size: 12px;

                &:after {
                  visibility: visible;
                  width: 100%;
                  left: 0;
                }

                .icon-alert {
                  bottom: 5px;
                }
              }
            }
          }

          .invalid {
            border-bottom-color: red;
          }

          .form-control.fieldInvalid {
            border-bottom-color: $red;
            caret-color: $red;
          }
        }

        .not-empty {
          .invalid ~ .animated-label {
            .icon-alert {
              bottom: 5px;
            }
          }
        }

        .error-label:after {
          background-color: $red !important;
          /*content: 'Please enter a correct email address';
				color: $red;
				padding-left: 5px;*/
        }

        .error-message {
          text-align: left;
          padding-left: 5px;
          padding-top: 5px;
          color: $red;
          width: 70%;
          min-width: 260px;
          height: 24px;
          margin-top: -15px;
        }

        .error-message.multiple-errors {
          height: auto;

          ul {
            margin-top: 0;
            padding-left: 10px;
          }
        }
      }

      .save-disabled {
        width: 100%;
        max-width: 330px;
        border-radius: 30px;
        background-color: #e5e9ed;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        color: #ffffff;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        font-weight: 900;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 40px;
        cursor: not-allowed;
      }

      .save-visible {
        width: 100%;
        max-width: 330px;
        border-radius: 30px;
        background-color: #00b2f1;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        color: #ffffff;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        font-weight: 900;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 40px;
        margin-top: 40px;
        cursor: pointer;
      }
    }
  }
}

﻿%order-options-checkbox {
  label::after {
    content: "" !important;
    background-color: black;
    border-radius: 2px;
    height: 9px;
    left: 3px;
    top: 4px;
    width: 11px;
  }
}

.production-settings-content.order-options-content {
  .ui.grid.order-options-states {
    & > .column.includes {
      padding-left: 0;
    }

    & > .column.excludes {
      padding-right: 0;
    }

    .ui.multiple.selection.dropdown {
      border-radius: 0;
      background-color: #fafafa;
    }
  }

  .production-settings-input-header-group:nth-child(4) {
    padding-top: 3rem;
  }

  .production-branch-settings-container {
    .column:first-of-type {
      padding-left: 0 !important;
    }

    .header-row {
      font-weight: bold;
      border-bottom: 1px solid #eee;
      padding-bottom: 4px;
    }

    .content-row {
      border-bottom: 1px solid #eee;

      &::before {
        box-shadow: none !important;
      }

      p.subtype-button {
        font-size: 14px;
        color: #808080;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .order-options-content__checkbox {
      @extend %order-options-checkbox;
    }

    .content-row:last-child {
      border-bottom: none;
    }
  }
}

.order-options-modal {
  padding: 60px;

  hr {
    border: 1px solid #eee;
  }

  .order-options-modal__header {
    text-align: center;
    margin-bottom: 5px;
  }

  .order-options-modal__instructions {
    text-align: center;
  }

  .order-options-modal__options {
    margin-top: 50px;
  }

  .order-options-modal__check-wrapper {
    padding-left: 17px;
  }

  .options-header {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
  }

  // semantic override
  .ui.checkbox {
    @extend %order-options-checkbox;
  }

  .order-options-modal__option {
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #eee;
  }

  .order-options-modal__popup {
    margin-left: 5px;
    vertical-align: text-bottom;
  }
}

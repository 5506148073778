.client-permission-form-container-wrapper {
  width: 885px;
  background-color: #ffffff;
  border-radius: 4px;
  //border: 1px solid #ececec;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flex-table-container {
    display: flex;
    align-items: baseline;
  }
}

.client-permission-form-container-content {
  margin: 50px;
}

.client-permission-header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.client-permission-form-title-group {
  display: flex;
  justify-content: space-between;
}

.client-permission-form-title-main {
  margin-bottom: 30px;
}

.client-permission-form-title {
  font-size: 18px;
  font-weight: bold;
}

.client-permission-form-subtitle {
  font-size: 15px;
  color: #4e565b;
  margin-top: 2px;
}

.permission-header {
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
}

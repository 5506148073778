﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";
@import "../common/_logos.scss";

.auth-form-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 85px;
  height: auto;
  width: 520px;
  border: 1px solid #f0f0f0;
  position: relative;

  @include respond-max(580px) {
    width: 90%;
  }

  @include respond-max(1062px) {
    margin-top: 8%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 65px auto;
    height: 100%;
    width: 100%;
    text-align: center;

    .header {
      width: 70%;
      margin-bottom: 65px;

      @include respond-max(479px) {
        width: 200px;
      }

      @include respond-max($breakpoint-xsm) {
        margin-bottom: 10%;
      }
    }

    .paragraph-small {
      width: 70%;
      min-width: 215px;
      margin-bottom: 44px;
    }

    .m-t_md {
      margin-top: 40px;
    }

    .m-t_none {
      margin-top: 0;
    }

    .m-b_sm {
      margin-bottom: 10px;
    }

    .btn {
      @extend .m-t_md;
    }

    .main-blue:hover {
      color: $light-blue-hover;
    }

    .btn-basic {
      margin-top: 0;
      margin-bottom: 0;
    }

    .auth-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .form-group {
      width: 70%;
      min-width: 260px;
      position: relative;
      padding-top: 16px;
      margin-bottom: 0;
    }
  }

  .content.terms-conditions {
    margin: 65px 0;
    min-height: 320px;
    padding: 0 4%;

    .header-7 {
      max-width: 330px;
      margin: 20px 0 20px 0;
      line-height: 26px;
    }

    .btn-basic {
      width: 215px;
      height: 32px;
      font-size: 13px;
    }
  }

  .shorter-content {
    margin-bottom: 65px;

    .header {
      @include respond-max(504px) {
        width: 80%;
      }

      @include respond-max(479px) {
        width: 75%;
        min-width: 240px;
      }
    }
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.order-form-container {
  .prop-step__map-container {
    text-align: center;
  }

  .prop-step__map {
    background: #e1e1e1;
    background-position: 50% -20px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    height: 280px;
    justify-content: center;
    margin: 25px 0 40px 0;
    position: relative;
    width: 100%;

    &.empty {
      background: transparent;
      height: auto;
    }

    @include respond-min(600px) {
      background-position: 100% 15%;
    }
  }

  .prop-step__address-container {
    background: #fff;
    bottom: -35px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    position: absolute;
    width: 90%;

    @include respond-min(600px) {
      box-shadow: none;
      height: 75px;
      padding: 5px;
      position: static;
      width: 450px;
    }
  }

  .prop-step__map-address {
    font-size: 16px;
    margin-bottom: 5px;
    padding: 5px 0;
  }

  .prop-step__address-text {
    display: block;
    font-weight: 700;

    @include respond-min(600px) {
      display: inline;
      font-weight: 500;
    }
  }

  .btn-change-address {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    padding: 1px 0;
    text-decoration: underline;
  }

  .prop-step__occ {
    margin-top: 70px;
    text-align: center;

    @include respond-min(600px) {
      margin-top: 50px;
    }
  }

  .prop-step__occ-text {
    font-size: 14px;
    margin: 0;

    @include respond-min(600px) {
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .owner-occ-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    width: 100%;

    .btn-new {
      width: 130px;
      margin: 0 5px;
    }
  }

  .prop-type-confirm {
    padding-top: 50px;
    border-top: 1px solid #eaeaea;
    margin: 25px 0 10px;
  }

  .btn-prop-address {
    display: block;
    font-weight: 700;
    margin: 20px auto;
    max-width: 330px;
    width: 100%;

    &:last-of-type {
      margin: 0 auto 50px;
    }
  }
}

.confirm-changes-modal__btn-container {
  padding: 10px 10px 30px 10px;
}

.confirm-changes-modal-btn {
  margin: 10px 3px 0;
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../propertiesPage/_propListConstants.scss";

.search-results-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  @include respond-max($mobile-breakpoint-width) {
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .search-results-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include respond-max($mobile-breakpoint-width) {
      height: 100%;
      margin: 0;
      width: 95%;
    }

    .search-category-container {
      .category-header {
        font-size: 16px;
        font-weight: 900;
        margin-top: 20px;
      }
    }

    .result-row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px;
      border-bottom: 1px solid #efedee;

      .gray-txt {
        color: $light-gray;
      }

      .result-icon {
        background-repeat: no-repeat;
        background-size: contain;
        height: 14px;
        width: 12px;
        margin-right: 24px;
        position: relative;
        top: 2px;
        flex-shrink: 0;
      }

      .result-icon.my-location {
        background-image: url("../../../../images/titlefyPro/commonIcons/search-icon-nearme@2x.png");
      }

      .result-icon.search {
        background-image: url("../../../../images/titlefyPro/commonIcons/search-icon-recent@2x.png");
      }

      .result-icon.prop {
        background-image: url("../../../../images/titlefyPro/searchIcons/search-icon-prop@2x.png");
      }

      .result-icon.owner {
        background-image: url("../../../../images/titlefyPro/searchIcons/search-icon-owner@2x.png");
      }

      .result-icon.place {
        background-image: url("../../../../images/titlefyPro/searchIcons/search-icon-places@2x.png");
      }

      .result-text {
        font-size: 14px;
        font-weight: 500;
      }

      .address-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    .result-row:hover {
      background-color: #f5f8fa;
      cursor: pointer;
    }

    .result-row.no-results {
      border-bottom: none;
      background-color: #fafafa;
      cursor: auto;

      .result-text {
        margin-left: 36px;
        cursor: default;
      }
    }
  }
}

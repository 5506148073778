﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.info-branding-forms-container-wrapper {
  width: 885px;
  background-color: #ffffff;
  border-radius: 4px;
  //border: 1px solid #ececec;
  margin-top: 30px;
}

.info-branding-forms-container-content {
  margin: 50px;

  .input-group {
    .label {
      font-size: 13px;
      color: #8a9499;
      margin-left: -5px;
    }

    .error-message {
      bottom: -20px;
    }
  }
}

.info-branding-input-field {
  width: 280px;
}

.info-branding-input-field-border {
  margin-top: 19px;
}

.info-branding-input-field-service {
  margin-top: 19px;
  margin-bottom: 18px;
}

.info-branding-header-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info-branding-edit-icon {
  background-image: url("../../../../../images/commonIcons/icon-edit.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.info-branding-title-group {
  margin-bottom: 38px;
}

.info-branding-title-main {
  font-size: 18px;
  font-weight: 800;
}

.info-branding-title-subtitle {
  font-size: 15px;
  color: #4e565b;
}

.info-branding-company-group {
  margin-bottom: 45px;
  display: flex;
}

.info-branding-email-group {
  margin-bottom: 45px;
  display: flex;
  flex-wrap: wrap;
}

.info-branding-logo-group {
  margin-bottom: 45px;
}

.info-branding-customer-service-group {
  margin-bottom: 35px;
  display: flex;
}

.info-branding-group-cs {
  width: 300px;
  margin-bottom: 15px;
}

.info-branding-group-csi {
  width: 70px;
  margin-right: 40px;
}

.info-branding-customer-service-text {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.info-branding-customer-service-text.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.info-branding-save-changes-button {
}

.info-branding-header {
  color: #152935;
  font-size: 16px;
  font-weight: bold;
}

.info-branding-group {
  width: 370px;
  margin-bottom: 15px;
}

.info-branding-light {
  font-size: 13px;
  color: #8a9499;
  margin-bottom: 10px;
}

.info-branding-bold {
  font-size: 16px;
  color: #152935;
  font-weight: bold;
}

.info-branding-group-logos {
  margin-right: 40px;
}

.info-branding-bold-logos {
  margin-bottom: 5px;
}

.info-branding-logo-subtitle {
  margin-bottom: 20px;
  color: #8a9499;
}

.info-branding-img-upload-small {
  width: 90px;
  height: 40px;
}

.info-branding-img-upload-medium {
  width: 120px;
  height: 40px;
}

.info-branding-img-upload-large {
  width: 320px;
  height: 80px;
}

.info-branding-lighter {
  font-size: 10px;
  color: #152935;
  margin-top: 10px;
}

.save-changes-btn-container {
  display: flex;
  justify-content: flex-end;
}

.info-branding-save-changes-button {
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.file-upload-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed #d4d4d4;
  cursor: pointer;
  padding: 2%;

  .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.file-upload-border.disabled {
  cursor: auto;
}

.file-upload-border.circle {
  width: 75px;
  height: 75px;
  border-radius: 40px;
  border: 1px solid #d4d4d4;
  padding: 0;
}

.file-upload-border.small {
  height: 40px;
}

.file-upload-border.medium {
  height: 50px;
}

.file-upload-border.large {
  height: 90px;
}

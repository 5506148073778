﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.property-main-container {
  .disclaimers-body-main {
    margin-top: 120px;
    padding-left: 20%;
    padding-right: 20%;

    .page-title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 900;
    }

    .body-main {
      margin-top: 40px;

      .secondry-title {
        font-size: 31px;
        line-height: 38px;
      }

      .text-data {
        font-size: 18px;
        line-height: 26px;
        color: #8a9499;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
}

﻿@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

.hero-container {
  width: 100%;
  margin-top: 100px;

  @include respond-range(320px, 767px) {
    margin-top: 30px;
  }

  .hero-content-container {
    max-width: $home-max-width;
    margin: auto;
    width: $section-width;
    height: auto;

    .header-main {
      max-width: 800px;
    }

    .subheader-main {
      margin-top: 40px;
      max-width: 650px;
    }

    .btn-white {
      width: 134px;
      margin-top: 40px;
    }
  }

  .hero-visibility {
    width: $section-width;
    max-width: $home-max-width;
    position: absolute;

    @include respond-max($breakpoint) {
      position: relative;
      width: 100%;
    }
  }

  .photo-loanOfficer {
    background-image: url("../../../images/homePage/photo-loan.officer@2x.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 175px;
    height: 285px;
    position: absolute;
    top: 37%;
    left: -10%;

    @include respond-max($breakpoint) {
      top: 62%;
      left: 65%;
      width: 19vw;
      height: 40vw;
      min-width: 90px;
    }
  }

  .heroBackgroundSolid {
    background-color: $background-gray;
    margin-top: 230px;
    height: 50vw;
    position: relative;
    z-index: 0;
    /*-------------------*/
    height: 615px;
    display: flex;
    justify-content: center;

    @include respond-range(320px, 1024px) {
      margin-top: 60px;
      height: auto;
    }

    .heroBackgroundImg {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../images/homePage/graphic-real.time.progress@2x.png");
      background-position: center;
      position: absolute;
      top: -295px;
      margin-left: 30%;
      width: 958px;
      min-height: 820px;

      @include respond-max($breakpoint) {
        background-size: cover;
        margin-left: 0;
        top: 0;
        width: 100%;
        min-width: 100%;
        min-height: 91vw;
        height: 91vw;
        min-height: 400px;
        max-height: 950px;
        position: relative;
        width: 100%;
        height: 83vw;
        min-height: 400px;
        max-height: 91vw;
        background-size: contain;
      }

      @include respond-max($breakpoint-sm) {
        background-image: url("../../../images/homePage/graphic-moible-real.time.progress.png");
        height: 107vw;
        max-height: none;
        background-size: cover;
        min-height: 350px;
      }
    }
  }

  .logo-badge-flueid {
    position: absolute;
    bottom: -5%;
    left: 0%;
    right: 0;
    margin: auto;

    @include respond-max($breakpoint) {
      bottom: -3%;
    }

    @include respond-max($breakpoint-sm) {
      bottom: -5%;
    }

    @include respond-max($breakpoint-xsm) {
      bottom: -12%;
    }
  }
}

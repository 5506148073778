﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../_propListConstants.scss";

$content-spacing: 15px;

.detail-card-container-link {
  display: block;
}

.detail-card-container {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &.no-minHeight {
    min-height: 0;
  }

  &.selected {
    border: 1px solid #666;
  }

  &:hover {
    border: 1px solid #666;
  }

  .mt-spacing {
    margin-top: $content-spacing;
  }

  .mr-spacing {
    margin-right: $content-spacing;
  }

  .header-overflow_margin {
    margin-right: 3px;
  }

  .img.farm,
  .img.property,
  .img-map-placeholder {
    width: 100%;
    height: 172px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .img.property {
    background: gainsboro;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .close-btn {
      background-image: url("../../../../images/titlefyPro/commonIcons/icon-backCircle@2x.png");
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center;
      height: 30px;
      width: 30px;
      padding: 25px;
      position: absolute;
      top: 0;

      @include respond-max($mobile-breakpoint-width) {
        background-image: url("../../../../images/commonIcons/icon-close@2x.png");
        background-size: 20px;
        height: 20px;
        width: 20px;
        padding: 20px;
      }
    }
  }

  .img-map-placeholder {
    background-image: url("../../../../images/titlefyPro/googleMaps/map-placeholder@2x.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    text-align: center;
    padding: 16px;
  }

  .info-container {
    padding: $content-spacing;
    background-color: #ffffff;

    .status-container {
      display: flex;
      justify-content: space-between;
      position: relative;
      bottom: 25px;

      .left-group {
        display: flex;
      }

      .status {
        color: #fafafa;
        font-size: 12px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .l-border-rad {
        border-radius: 2px 0 0 2px;
      }

      .r-border-rad {
        border-radius: 0 2px 2px 0;
      }

      .status.hot {
        background-image: url("../../../../images/titlefyPro/propertyDetails/cards/icon-hot-properties@2x.png");
      }

      .status.not-hot {
        background-image: url("../../../../images/titlefyPro/propertyDetails/cards/icon-hot-inactive@2x.png");
      }

      .status.hot,
      .status.not-hot {
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .status.label {
        border-radius: 2px;
      }

      .lead {
        background-color: $green-lead;
      }

      .prospect {
        background-color: $deep-purple-prospect;
      }

      .no-contact {
        background-color: $dark-blue-do-not-contact;
      }

      .add-label {
        background-color: $gray-off-market;
      }

      .prop-type {
        color: black;
        background-color: #fafafa;
      }

      .pending {
        background-color: $dark-blue-pending;
      }

      .sold {
        background-color: $yellow-recently-sold;
      }

      .for-sale {
        background-color: $green-listed;
      }

      .off-market {
        background-color: $gray-off-market;
      }
    }

    .title {
      font-size: 15px;
    }

    .txt-small {
      font-size: 11px;
    }

    .txt-light-gray {
      color: #aaaaaa;
    }

    .text-margin-top {
      margin: 5px 0;
    }

    .text-margin-left {
      margin-left: 3px;
    }

    .row-container,
    .left-content,
    .right-content {
      display: flex;
    }

    .row-container {
      justify-content: space-between;
      align-items: center;
    }

    .row-container.justify-flex-start {
      justify-content: flex-start;
    }

    .row-container.align-flex-start {
      align-items: flex-start;
    }

    .minus-margin-top {
      margin-top: -10px;
    }

    .amenity-margin-top {
      margin-top: $content-spacing;
    }

    .prop-info-amenity {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 33%;
      @extend .txt-small;

      .icon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }

      .bed {
        background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bedroom@2x.png");
      }

      .bath {
        background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bathroom@2x.png");
      }

      .sqft {
        background-image: url("../../../../images/titlefyPro/propertyIcons/icon-sqft@2x.png");
      }
    }

    .prop-info-amenity > div {
      margin-right: 5px;
    }

    .prop-info-amenity > span {
      margin-right: 2px;
    }
  }

  .icon-status-hot {
    width: 15px;
    height: 15px;
  }
}

.detail-card-btn {
  width: 100%;
  height: 38px;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: 900;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease background-color;
}

.detail-card-btn:hover {
  background-color: #f2f2f2;
}

.detail-card-btn > div {
  margin-right: 5px;
}

﻿@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

$contact-grid-breakpoint: 630px;

.flueidProMotive-container {
  width: 100%;
  position: relative;

  .p-2 {
    margin-top: 20px;
  }

  .flueidProTalktDes-main {
    width: 100%;

    a {
      display: block;
    }

    .flueidProTalktDes-sec {
      width: 80%;
      margin: auto;
      margin-top: 100px;
      max-width: 1400px;

      @include respond-max($breakpoint) {
        width: 90%;
        margin-top: 100px;
      }

      .subheader-1 {
        margin-top: 30px;
      }

      .flueidProTalktDes-officeSec {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;

        @include respond-max($breakpoint) {
          grid-template-columns: 1fr 1fr;
        }

        @include respond-max($contact-grid-breakpoint) {
          grid-template-columns: 1fr;
        }

        .flueidProTalktDes-officeItem {
          .flueidProTalktDes-seperator {
            height: 3px;
            max-width: 300px;
            transform: scaleY(-1);
            background-color: #000000;
            margin-top: 50px;

            @include respond-max($contact-grid-breakpoint) {
              max-width: none;
            }

            @include respond-range(320px, 1024px) {
              margin-top: 35px;
            }
          }

          .address {
            margin-top: 6px;
          }

          .sales {
            margin-top: 6px;
          }

          .flueidProTalktDes-locationDir {
            display: flex;
            margin-top: 6px;
            max-width: 280px;

            @include respond-range(320px, 1024px) {
              width: 100%;
              max-width: 300px;
            }

            .p-2 {
              cursor: pointer;
              border-bottom: 1px solid #000000;
            }

            .p-2:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .flueidProContactForm-main {
    width: 100%;

    .flueidProContactForm-contactSec {
      .flueidProContactFormContainer {
        @include respond-range(320px, 1024x) {
          height: auto;
          padding-bottom: 40px;
        }

        @include respond-range(1025px, 3000px) {
          max-height: 900px;
        }

        .flueidProContactForm-container {
          width: 100%;
          height: 100%;
          margin-top: 0;
          display: flex;
          justify-content: center;

          .flueidProContactForm-content-container {
            width: 100%;
            position: relative;

            .flueidProContactForm-text-background-container {
              display: flex;
              justify-content: center;
              width: 100%;
              position: absolute;
              z-index: 0;

              @include respond-range(320px, 1024px) {
                position: unset;
              }

              .flueidProContactForm-text-content {
                width: 80%;
                margin-top: 75px;
                max-width: $home-max-width;

                @include respond-max($breakpoint) {
                  width: $section-width;
                }

                .flueidProContactForm-lineSeperator {
                  height: 1px;
                  width: 263px;
                  transform: scaleY(-1);
                  background-color: #000000;
                }

                .flueidProContactForm-successContainer {
                  width: 52%;
                  border: 1px solid #000000;
                  margin-top: 20px;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  height: 400px;
                  position: relative;
                  border-radius: 5px;

                  @include respond-range(320px, 1024px) {
                    width: 100%;
                    height: 230px;
                  }

                  .flueidProContactForm-IconClose {
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;
                  }

                  .flueidProContactForm-successTxt {
                    color: #000000;
                    font-family: "Abril Fatface";
                    font-size: 30px;
                  }

                  .flueidProContactForm-successSubTxt {
                    color: #000000;
                    font-family: "acumin-pro";
                    font-size: 16px;
                    margin-top: 15px;
                  }
                }

                .flueidProContactForm-inputSec {
                  margin-top: 30px;
                  width: 50%;

                  @include respond-range(320px, 1024px) {
                    width: 100%;
                  }
                }

                .msg {
                  margin-top: 60px;
                }

                .flueidProContactForm-submitBtnSec {
                  width: 50%;
                  display: flex;
                  margin-top: 60px;
                  justify-content: space-between;

                  @include respond-range(320px, 1024px) {
                    flex-direction: column;
                    width: 100%;
                    margin-top: 30px;
                  }

                  .p-4 {
                    width: 60%;
                    max-width: 325px;

                    @include respond-range(320px, 1024px) {
                      width: 100%;
                      max-width: 100%;
                    }
                  }

                  .btn-black {
                    width: 104px;

                    @include respond-range(320px, 1024px) {
                      margin: 40px auto;
                    }
                  }
                }
              }
            }

            .flueidPro-linefive {
              width: 90%;
              margin: auto;
              height: 1px;
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              bottom: 10%;
              left: 2%;

              @include respond-range(320px, 1024px) {
                width: 100%;
                bottom: -1%;
                left: 0;
              }
            }

            .flueidProContactForm-transition-containerWeb {
              margin-top: 95px;

              .flueidProContactForm-container-column-right {
                float: right;
                background-color: #fff;
                width: 40%;
                position: relative;
                z-index: 2;
                top: -40px;
                left: 1%;
                background-color: #000000;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100%;
                background-position-y: top;
                max-height: 900px;

                @include respond-range(320px, 1024px) {
                  float: none;
                  width: 100%;
                  left: 0;
                }

                @include respond-range(1501px, 2500px) {
                  background-size: cover;
                }

                .flueidProContactForm-content {
                  padding: 125px 65px 160px 125px;
                  color: white;

                  @include respond-max($breakpoint) {
                    padding: 110px 0px;
                    margin: auto;
                    width: $section-width;
                  }

                  .flueidProContactForm-img {
                    height: 232px;
                    width: 174px;
                    background-image: url("../../../images/homePage/photo-sina@2x.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 100%;
                    background-position-y: top;

                    @include respond-max($breakpoint) {
                      margin: auto;
                    }
                  }

                  .subheader-2 {
                    margin-top: 40px;

                    @include respond-max($breakpoint) {
                      text-align: center;
                    }
                  }

                  .p-1 {
                    @include respond-max($breakpoint) {
                      text-align: center;
                      margin: auto;
                    }
                  }

                  .subheader-main {
                    margin-top: 50px;

                    @include respond-max($breakpoint) {
                      text-align: center;
                      max-width: 345px;
                      margin: 25px auto 0 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .input-group {
    position: relative;

    * {
      font-family: "acumin-pro" !important;
    }

    .label {
      font-size: 13px;
      font-weight: 500;
      position: absolute;
      top: 10px;
      left: 0;
      transition: 200ms;
      font-size: 15px;
      font-weight: 500;
      color: #7f8c8d;
      padding-left: 0;
    }

    .focus {
      font-size: 12px !important;
      transform: translate3d(0, -100%, 0);
    }

    input,
    .dropdown {
      padding: 15px 0;
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #000000;
      font-size: 18px;
      font-weight: 500;
      caret-color: #233752;
      -webkit-appearance: none;
      border-radius: 0;
      -webkit-border-radius: 0;
    }

    input:focus,
    textarea:focus,
    select:focus {
      outline: none;
    }

    input:focus + .label {
      outline: none !important;
      font-size: 12px;
      transform: translate3d(0, -100%, 0);
      color: #000000;
      font-weight: bold;
    }

    input.read-only {
      border-bottom-color: rgba(0, 0, 0, 0);
    }

    input.error {
      border-bottom: 1px solid red;
    }

    .bar {
      position: relative;
      display: block;
      width: 100%;
    }

    .error-message {
      color: red;
      position: absolute;
      font-size: 12px;
      left: 5px;
    }

    .bar:before,
    .bar:after {
      content: "";
      height: 3px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: #000000;
      transition: 0.3s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    .bar:before {
      left: 50%;
    }

    .bar:after {
      right: 50%;
    }

    .bar.dropdown-bar {
      z-index: 10;
    }
    /* active state */
    input:focus ~ .bar:before,
    input:focus ~ .bar:after {
      width: 50%;
    }

    .dropdown:focus ~ .bar:before,
    .dropdown:focus ~ .bar:after {
      width: 50%;
    }
    /*---------------*/
    /* Semantic UI Dropdown style overrides*/
    .ui.selection.dropdown {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000000;
      outline: none;
      min-height: 0;
      height: 29px;
      padding: 5px 5px 5px 5px;
    }

    .ui.selection.dropdown:hover {
      box-shadow: none;
      border-color: #e1e1e1;
    }

    .ui.selection.active.dropdown {
      box-shadow: none;
    }

    .ui.selection.active.dropdown:hover {
      box-shadow: none;
      border: none;

      .icon-dropdown {
        bottom: 9px;
      }
    }

    .ui.dropdown > .text {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: inherit !important;
    }

    .ui.selection.active.dropdown.menu {
      border-color: #233752 !important;
      width: 100%;
      min-width: 100%;
      border-radius: 0;
      margin: 0;
      border-radius: 0;
    }

    .ui.selection.active.dropdown:hover .menu {
      border-color: #233752 !important;
    }

    .ui.dropdown > .dropdown.icon {
      display: none;
    }

    .ui.disabled.dropdown {
      border-bottom-color: rgba(0, 0, 0, 0) !important;
      opacity: 1;

      .icon-dropdown {
        display: none;
      }
    }
    /*-------------------*/
    .icon-clear,
    .icon-dropdown,
    .icon-locked,
    .icon-unlocked {
      position: absolute;
      right: 5px;
      cursor: pointer;
      z-index: 5;
      bottom: 22px;
    }

    .icon-clear {
      // background-image: url('../../images/search-icon-clear@2x.png');
      height: 14px;
      width: 14px;
      background-size: contain;
    }

    .icon-clear.hidden {
      visibility: hidden;
    }

    .icon-dropdown {
      z-index: 10;
      // background-image: url('../../images/icon-down-arrow-navy@2x.png');
    }
  }

  .input-group.read-only {
    input {
      border-bottom-color: rgba(0, 0, 0, 0);
    }

    .icon-clear {
      visibility: hidden;
    }

    .bar {
      visibility: hidden;
    }
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: #7f8c8d !important;
  }

  .ui.dropdown > .text {
    position: relative;
    top: 5px;
  }

  .ui.selection.dropdown {
    border-radius: 0 !important;
    border-radius: 0 !important;
    width: 100%;
    height: 60px;
    background-color: initial;
    color: #000;
    font-size: 15px;
    letter-spacing: -0.24px;
    line-height: 24px;
    margin-top: 20px;
    border: 0 solid;
    border-bottom: 1px solid #000;
    padding: 10px 0 0;
    border-color: black !important;
  }

  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    top: 35px !important;
    right: 5px !important;
    background-image: url("../../../images/commonIcons/icon-dropdown@2x.png");
    background-repeat: no-repeat;
    background-size: 45%;
  }

  .ui.dropdown > .dropdown.icon:before {
    content: unset !important;
  }

  .ui.selection.active.dropdown .menu {
    padding: 10px;
    border: none !important;
    top: 120% !important;
  }

  .ui.selection.active.dropdown .menu::-webkit-scrollbar {
    width: 5px;
  }

  .ui.selection.dropdown .menu > .item {
    height: 40px;
    line-height: 20px;
    border-top: unset !important;
    margin-bottom: 15px !important;
    padding: 10px !important;
  }

  .ui.selection.dropdown .menu > .item {
    padding: 10px !important;
  }

  .ui.dropdown .menu > .item {
    padding: unset !important;
  }

  .ui.selection.active.dropdown {
    box-shadow: none !important;
  }

  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: unset !important;
  }

  .ui.selection.dropdown .menu > .item .text {
    position: absolute;
    border-bottom: 1px solid #f0f0f0;
    width: 95%;
    height: 37px;

    @include respond-range(720px, 1024px) {
      width: 97%;
    }
  }
}

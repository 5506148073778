﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.review-farm-data-container {
  .form-content {
    @include respond-max($breakpoint-xsm) {
      height: 100%;
      justify-content: space-between;
    }
  }

  .form-border {
    @include respond-max($breakpoint-xsm) {
      height: calc(100vh - 135px);
    }
  }

  .full-width {
    width: 100%;
  }

  .max-width {
    margin: 0 auto;
  }

  .content-padding {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
  }

  .btn-container {
    position: sticky;
    bottom: 0;
    background-color: white;
  }

  .prop-search-result-info {
    display: flex;

    .icon-house {
      position: relative;
      margin-right: 12px;
      top: 4px;
    }
  }

  .input-group {
    width: 100%;
  }

  .review-farm-search-results {
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    margin: -10px 5px 0 5px;
    display: flex;
    flex-direction: column;

    .basic-row {
      padding-left: 10px;
      padding-right: 10px;
    }

    .basic-row:hover {
      background-color: #fbfbfb;
    }

    .basic-row:last-child {
      border: none;
    }
  }

  .txt-16 {
    font-size: 16px;
    line-height: 22px;
  }

  .txt-align-center {
    text-align: center;
  }

  .txt-dark-gray {
    color: #787878;
  }

  .map-img {
    min-height: 150px;
    margin: 30px 0;
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .not-found-props-transition-group {
    width: 100%;

    .basic-row {
      transition: ease 500ms all;
    }
  }

  .not-found-prop-animate-item-exit {
    overflow: visible;
    max-height: 100px;
    opacity: 1;
  }

  .not-found-prop-animate-item-exit-active {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .fix-not-found-prop-expand-control {
    display: flex;
    align-items: center;

    .icon-trashcan-white {
      transition: 0.3s ease width;
      background-color: #e71d32;
      height: 58px;
      margin: -12px 0;
      background-size: 30%;
    }

    .icon-trashcan-white.open {
      margin-left: 10px;
      width: 58px;
    }

    .icon-trashcan-white.closed {
      width: 0;
    }

    .icon-handle-pipes {
      background-size: 20%;
      width: 25px;
      height: 25px;
    }
  }
}

﻿@import "./_partnerProfileConstants.scss";

.production-settings-container {
  width: 885px;
  min-height: 900px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ececec;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.production-settings-content {
  margin: $content-margin;
}

.production-settings-header-group {
  margin-bottom: 40px;
}

.production-settings-title {
  font-size: 18px;
  font-weight: bold;
}

.production-settings-subtitle {
  font-size: 15px;
}

.production-settings-input-header-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.production-settings-header {
  font-size: 16px;
  font-weight: bold;
}

.production-settings-input-button {
  background-image: url("../../../../../images/commonIcons/icon-edit.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.production-settings-input-group {
  display: flex;
  margin-bottom: 15px;
}

.production-settings-input {
  width: 370px;
  margin-right: 40px;

  .label {
    color: #8a9499;
  }
}

.production-settings-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.non-edit-mode-input {
  margin-right: 40px;
  width: 370px;
}

.production-input-label {
  font-size: 13px;
  margin-left: 5px;
  color: #8a9499;
}

.input-input-non-edit {
  margin-top: 4px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
}

.production-input-grey {
  width: 780px;
  height: 75px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  overflow-wrap: break-word;
  background-color: #f8f8f8;
  color: #4e565b;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  font-size: 13px;
  margin-bottom: 25px;
  line-height: 73px;
}

.production-settings-input-grey-input {
  width: 780px;
  margin-bottom: 20px;

  .label {
    color: #8a9499;
  }

  input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
    margin-top: 5px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4 !important;
    border-radius: 2px;
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .input-group {
    .bar:before {
      background: none;
    }

    .bar:after {
      background: none;
    }

    .icon-clear {
      display: none;
    }
    .error-message {
      bottom: -18px;
    }
  }

  .input-group.non-editable {
    input {
      background-color: #f8f8f8;
    }
  }
}

.grey-input-vertical-center-wrapper {
  vertical-align: middle;
}

.production-small-input {
  width: 370px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.production-input-grey-small {
  width: 370px;
  overflow: hidden;
  overflow-wrap: initial;
  margin-right: 40px;
  overflow-wrap: break-word;
}

.branch-header-group {
  border-bottom: $content-border;
  margin-bottom: 10px;
}

.branch-header {
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
}

.branch-map-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 30px;
  margin-bottom: 30px;
}

.btn-group {
  @extend %partner-save-button-container;
}

/*========================= BRANCH SETTINGS BLOCK =========================*/

.production-settings-block-header {
  font-size: 14px;
  font-weight: bold;
}

.production-settings-block-btn {
  background-image: url("../../../../../images/commonIcons/icon-edit.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.production-settings-block-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.production-settings-block-filler {
  position: absolute;
}

.production-settings-block-filler:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
}

.production-settings-block-left,
.production-settings-block-right {
  position: relative;
  background: white;
}

.production-settings-block-left {
  padding-right: 5px;
}

.production-settings-block-right {
  font-size: 13px;
  font-weight: bolder;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*======================================= BRANCH SETTINGS FORM =============================*/

.production-modal-content {
  margin: 50px;
}

.production-modal-header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.production-modal-title {
  font-size: 18px;
  font-weight: bold;
}

.production-modal-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.production-modal-input {
  margin-bottom: 20px;
}

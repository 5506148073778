﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

.accordion {
  border-top: 1px solid #f0f0f0;
  padding-top: 12px;
  padding-bottom: 12px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px !important;
    font-weight: 700;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    transition: 0.5s ease all;
    border-top: 4px solid #d4d7dc;
  }

  .arrow.up {
    transform: rotate(180deg);
  }

  .accordion-content {
    .txt-small {
      font-size: 12px;
      color: #afafaf;
      position: relative;
      bottom: 16px;
    }

    .filter-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .filter-text {
        cursor: pointer;
      }

      .filter-text.active {
        color: $light-blue;
      }

      .filter-text.max-value {
        display: flex;
        width: 65px;
      }

      .status-dot-container {
        display: flex;
        align-items: center;

        .status-dot {
          background-repeat: no-repeat;
          background-size: contain;
          height: 12px;
          width: 12px;
          position: relative;
          left: 9px;
        }

        .hot {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-hot.png");
        }

        .saved {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-saved.png");
        }

        .for-sale {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-listed.png");
        }

        .pending {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-pending.png");
        }

        .recently-sold {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-recently-sold.png");
        }

        .off-market {
          background-image: url("../../../../images/titlefyPro/propStatusDots/map-dot-off-market.png");
        }
      }

      .deselect-x {
        background-image: url("../../../../images/commonIcons/icon-close@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        height: 7px;
        width: 7px;
        cursor: pointer;
        position: relative;
        left: 19px;
      }

      .deselect-x.hidden {
        visibility: hidden;
      }
    }

    .min-max-column-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      .column-title {
        width: 65px;
        height: 25px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #d0d0d0;
      }

      .year-input {
        outline: none;
        border: 1px solid #f0f0f0;
      }
    }

    .input-container {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 14px;

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

﻿@import "../../modules/_mixins.scss";
@import "../../modules/_constants.scss";
@import "_homePageConstants.scss";

.homepage-container {
  /* mobile browsers were ignoring overflow-x: hidden on the body element, this wraps around home page content as fix*/
  .mobile-overflow-fix {
    overflow-x: hidden;
    position: relative;
  }
  /*------------------*/
  /* FONT STYLES */
  %abril-fatface {
    font-family: "Abril Fatface" !important;
  }

  %acumin {
    font-family: "acumin-pro" !important;
  }

  .header-main {
    @extend %abril-fatface;
    font-size: 58px;
    line-height: 68px;
  }

  .header-1 {
    @extend %abril-fatface;
    font-size: 48px;
    line-height: 46px;
    color: black;
    @extend .txt-regular;
  }

  .header-2 {
    @extend %acumin;
    font-size: 32px;
    line-height: 35px;
  }

  .header-3 {
    @extend %abril-fatface;
    font-size: 20px;
    line-height: 27px;
    @extend .txt-regular;
  }

  .subheader-main {
    @extend %acumin;
    font-size: 22px;
    line-height: 36px;
  }

  .subheader-1 {
    @extend %acumin;
    font-size: 20px;
    line-height: 29px;
  }

  .subheader-2 {
    @extend %acumin;
    font-size: 16px;
    line-height: 20px;
  }

  .p-1 {
    @extend %acumin;
    font-size: 18px;
    line-height: 26px;
  }

  .p-2 {
    @extend %acumin;
    font-size: 15px;
    line-height: 18px;
  }

  .p-3 {
    @extend %acumin;
    font-size: 14px;
    line-height: 20px;
  }

  .p-4 {
    @extend %acumin;
    font-size: 12px;
    line-height: 16px;
  }

  .p-5 {
    @extend %acumin;
    font-size: 10px;
    line-height: 21px;
  }

  p {
    @extend %acumin;
    color: $light-gray;
    font-size: 18px;
    line-height: 26px;
  }

  .txt-extraLight {
    font-weight: 200;
  }

  .txt-light {
    font-weight: 300;
  }

  .txt-regular {
    font-weight: 400;
  }

  .txt-medium {
    font-weight: 500;
  }

  .txt-semiBold {
    font-weight: 600;
  }

  .txt-gray {
    color: #555555;
  }

  .txt-lightGray {
    color: #95a5a6;
  }

  .txt-underline {
    text-decoration: underline;
  }
  /* ----------------- */
  /* BUTTON STYLES */
  %btn {
    border-radius: $btn-border-radius;
    border: 1px solid;
    @extend %acumin;
    font-size: 14px;
    @extend .txt-semiBold;
    letter-spacing: -0.1px;
    line-height: $btn-height;
    text-align: center;
    cursor: pointer;
    transition: ease all 0.3s;
    box-shadow: none;

    &:hover {
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .btn-black {
    @extend %btn;
    background-color: black;
    color: white;
  }

  .btn-white {
    @extend %btn;
  }
  /*---------------*/
  /* Used in missionSection & featureSection */
  .feature-blocks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    grid-gap: 20px;
  }

  .feature-block-grid-item {
    @include respond-max($feature-grid-text-breakpoint) {
      text-align: center;
      align-items: center;
    }
  }

  .closing-itemsContainer.feature-blocks-grid {
    @include respond-max($breakpoint) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-max($breakpoint-xsm) {
      grid-template-columns: 1fr;
    }
  }

  .customer-feature-subSecMain.feature-blocks-grid {
    @include respond-max($breakpoint) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond-max($breakpoint-sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-max($breakpoint-xsm) {
      grid-template-columns: 1fr;
    }
  }
}

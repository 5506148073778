.form {
  width: 100%;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.partnerBtn {
  background: none;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;
  width: 200px;

  &:disabled {
    border-color: #000;
    cursor: not-allowed;
  }
}

.partnerBtnActive {
  border: 1px solid black;
}

.mbSm {
  margin-bottom: 10px;
}

.mbMd {
  margin-bottom: 30px;
}

.mbLg {
  margin-bottom: 60px;
}

.logo {
  width: 100%;
  height: 75%;
  object-fit: contain;
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";
@import "../common/_logos.scss";
@import "../titlefyPro/_navbarPro.scss";

.navbar-auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $navbar-height;
  border-bottom: 1px solid #f0f0f0;

  .navbar-auth-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    position: relative;

    .navigavation-HeaderTxt {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .back-btn {
      cursor: pointer;
      background-image: url("../../images/commonIcons/icon-backArrow@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
    }

    .header-6 {
      @include respond-max($breakpoint-xsm) {
        font-size: 16px;
      }
    }

    .closing-btn {
      position: absolute;
      right: 0;
      background-image: url("../../images/commonIcons/icon-close@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

﻿@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

.customer-featureContainer {
  background-color: $background-gray;
  padding: 150px 0;

  @include respond-max($breakpoint) {
    height: auto;
    max-height: unset;
  }

  .customer-feature-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin-top: 0;
    margin: auto;
    position: relative;

    @include respond-max($breakpoint) {
      height: 100%;
    }

    .customer-featureContent-container {
      display: flex;
      width: 100%;
      position: relative;

      @include respond-max($breakpoint) {
        flex-direction: column-reverse;
      }

      .customer-feature-background-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        z-index: 0;

        @include respond-max($breakpoint) {
          width: 100%;
          height: auto;
          margin-top: 50px;
        }

        .customer-feature-content {
          width: $section-width;
          max-width: 535px;
          margin-top: 15%;
          position: relative;
          margin: auto;

          @include respond-max($breakpoint) {
            max-width: none;
          }
        }

        .subheader-1 {
          margin-top: 30px;
        }

        .p-1 {
          margin-top: 12px;
        }

        .customer-feature-subSecMain {
          width: 100%;
          margin-top: 50px;
          max-width: 564px;

          @include respond-max($breakpoint) {
            max-width: none;
          }

          .customer-feature-sub {
            display: flex;
            flex-direction: column;

            @include respond-max($breakpoint-xsm) {
              align-items: center;
              text-align: center;
            }

            .customer-feature-subIcon {
              height: 50px;
              width: 50px;
              background-image: url("../../../images/homePage/icon-ordering@2x.png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }

            .progress {
              width: 78px;
              background-image: url("../../../images/homePage/icon-progress.tracking@2x.png");
            }

            .sign {
              width: 49px;
              background-image: url("../../../images/homePage/icon-electronic.signing@2x.png");
            }

            .realTime {
              width: 39px;
              background-image: url("../../../images/homePage/icon-real.time.updates@2x.png");
            }

            .closing {
              width: 42px;
              background-image: url("../../../images/homePage/icon-closing.schedule@2x.png");
            }

            .vault {
              width: 35px;
              background-image: url("../../../images/homePage/icon-document.vault@2x.png");
            }

            .p-3 {
              @include respond-max($breakpoint) {
                max-width: $grid-text-max-width;
              }
            }
          }
        }

        .customer-cominSoonSec {
          width: 83.5px;
          border: 0.5px solid #000000;
          border-radius: 1.5px;
          text-align: center;
          margin-top: 20px;
        }

        .subheader-1.txt-extraLight {
          margin-top: 80px;

          @include respond-max($breakpoint) {
            text-align: center;
          }
        }

        .customer-featureContactBtn {
          margin-top: 75px;
          width: 110px;

          @include respond-max($breakpoint) {
            margin: auto;
            margin-top: 35px;
          }
        }
      }

      .customer-featuretransition-container {
        @include respond-max($breakpoint) {
          width: 100%;
          position: relative;
        }

        .customer-featurecontainer-column-left {
          position: relative;
          z-index: 2;
          top: -120px;
          background-image: url("../../../images/homePage/graphic-open.order@2x.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: 100%;
          background-position-y: top;
          /*----------------*/
          height: 980px;
          background-size: contain;
          background-position: center;
          height: 1000px;
          width: 588px;
          left: -2%;
          width: 58vw;
          max-width: 780px;

          @include respond-max($breakpoint) {
            top: 50px;
            width: 100%;
            height: 91vw;
            min-height: 400px;
            max-height: 91vw;
            background-size: contain;
            background-position: top;
            max-width: none;
            left: 0;
          }

          @include respond-max($breakpoint-sm) {
            background-image: url("../../../images/homePage/graphic-moible-open.order@2x.png");
            height: 153vw;
            max-height: none;
            background-size: cover;
          }
        }
      }
    }
  }
}

@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

.dashed-line-group-container-visibility {
  position: absolute;
  width: 100%;
}

.dashed-line-group-container {
  width: 50%;
  display: flex;
  position: absolute;
  overflow: hidden;
  // default to animate left
  left: 0;

  .dashed-group-item {
    height: 1px;
    background-color: black;
    position: relative;
    opacity: 0;
    flex: 1;
  }
}

/*.dashed-line-group-container.black > div {
    background-color: black;
}

.dashed-line-group-container.white > div {
    background-color: white;
}*/

/*.dashed-line-group-animate-right {
    left: 0;
}*/

.dashed-line-group-container.right {
  left: 0;
}

.dashed-line-group-container.left {
  right: 0;
  left: auto;
}

.dashed-line-group-animate-left {
  right: 0;
  justify-content: flex-end;
}

.visibility-container-display-none-mobile {
  @include respond-max($breakpoint-md - 1) {
    display: none;
  }
}

.hero-section-dashed-line-group-1 {
  width: 80%;
  bottom: 30px;
  left: -10%;
}

.hero-section-dashed-line-group-2 {
  bottom: 125px;
  width: 38%;

  @include respond-max($breakpoint) {
    bottom: -60px;
  }
}

.hero-section-visibility-line-group-3 {
  bottom: -25px;
  left: -3%;

  @include respond-max($breakpoint-sm) {
    bottom: -50px;
  }
}

.hero-section-dashed-line-group-3 {
  width: 90%;
  bottom: -30px;

  @include respond-max($breakpoint) {
    bottom: -110px;
  }

  @include respond-max($breakpoint-sm) {
    bottom: -69px;
  }

  @include respond-max($breakpoint-xsm) {
    bottom: -82px;
  }
}

.hero-section-dashed-line-group-2-mobile {
  width: 70%;
  bottom: 10%;

  @include respond-min($breakpoint-sm) {
    display: none;
  }
}

.mission-section-visibility-line-group-1 {
  bottom: -40px;

  @include respond-max($breakpoint) {
    bottom: -130px;
  }

  @include respond-max($breakpoint-sm) {
    bottom: -120px;
  }
}

.feature-section-visibility-line-group-1 {
  bottom: 100px;
  @include respond-max($breakpoint) {
    display: none;
  }
}

.feature-section-visibility-line-group-2 {
  bottom: -40px;
  right: -20%;
  @include respond-max($breakpoint) {
    bottom: -90px;
    right: 5%;
  }
}

.feature-section-dashed-line-group-2 {
  right: -22%;
}

.advantage-section-visibility-line-group-1 {
  right: -25%;
  top: 20%;

  @include respond-max($breakpoint-sm) {
    display: none;
  }
}

.advantage-section-visibility-line-group-2 {
  left: 24%;
  bottom: 10%;

  @include respond-max($breakpoint) {
    left: -6%;
    bottom: 6%;
  }
}

.advantage-section-dashed-line-group-2 {
  width: 90%;
}

.footer-visibility-line-group-1 {
  top: -3%;

  @include respond-max($breakpoint) {
    top: 18%;
  }
}

.footer-section-dashed-line-group-1 {
  @include respond-max($breakpoint) {
    width: 73%;
  }
}

.numbers-section-dashed-line-group {
  width: 69%;
  bottom: -10%;

  @include respond-max(1024px) {
    bottom: -5%;
  }

  @include respond-max(843px) {
    bottom: -7%;
  }

  @include respond-max(670px) {
    bottom: -5%;
    width: 85%;
  }
}

.build-by-flueid-dashed-line-group {
  width: 80%;
  bottom: 12%;
  z-index: 2;

  @include respond-max(1334px) {
    width: 52%;
  }

  @include respond-max(849px) {
    width: 64%;
  }

  @include respond-max(1159px) {
    bottom: 5%;
  }

  @include respond-max(629px) {
    bottom: 5.5%;
  }

  @include respond-min(1609px) {
    width: 55%;
  }
}

.footer-dashed-line-group {
  width: 55%;
  bottom: 10%;
  z-index: -1;

  @include respond-max(1024px) {
    display: none;
  }
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

.logo-titlefy {
  width: 200px;
  height: 50px;
  @include imgCenterContain;
}

.logo-titlefy-mark {
  width: 36px;
  height: 36px;
}

.logo-badge-flueid {
  background-image: url("../../images/homePage/badge-powerd.by.flueid.png");
  @include imgCenterContain;
  width: 115px;
  height: 30px;
}

.logomark-flueidpro-white {
  background-image: url("../../images/logos/mstile-70x70.png");
  @include imgCenterContain;
  width: 55px;
  height: 55px;
}

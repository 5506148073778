﻿@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

$subheader-margin-top: 20px;

.homepage-visibility-closing-container {
  background-color: $background-gray;

  @include respond-max($breakpoint) {
    height: auto;
  }

  .homepage-closing-container {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 150px;

    @include respond-max($breakpoint) {
      display: block;
      padding-top: 200px;
    }

    .closing-content-container {
      max-width: $home-max-width;
      width: $section-width;
      margin: auto;

      @include respond-max($breakpoint-sm) {
        width: 100%;
      }

      .closing-text-background-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        position: relative;
        max-height: none;

        @include respond-max($breakpoint-sm) {
          width: $section-width;
          margin: auto;
        }

        .closing-text-content {
          width: 100%;

          .subheader-1 {
            max-width: 900px;
            width: 101%;

            @include respond-range(320px, 1024px) {
              width: 100%;
              margin-top: 50px;
            }
          }

          .closing-text-paragraphs {
            width: $section-width;
            margin-top: 75px;

            @include respond-range(320px, 1024px) {
              width: 100%;
              margin-top: 50px;
            }

            .closing-itemsContainer {
              width: 100%;
              margin-top: 50px;

              .closing-item {
                display: flex;
                flex-direction: column;

                @include respond-max($breakpoint-xsm) {
                  text-align: center;
                  align-items: center;
                }

                @include respond-max($feature-grid-text-breakpoint) {
                  align-items: center;
                  text-align: center;
                }

                .closing-Img {
                  height: 42px;
                  width: 42px;
                  background-image: url("../../../images/homePage/icon-nationwide.data@2x.png");
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }

                .plat {
                  background-image: url("../../../images/homePage/icon-multi.platform@2x.png");
                }

                .valu {
                  background-image: url("../../../images/homePage/icon-valuation.equity@2x.png");
                }

                .hot {
                  background-image: url("../../../images/homePage/icon-hot.properties@2x.png");
                }

                .p-1 {
                  margin-top: 10px;
                }

                .p-3 {
                  max-width: $grid-text-max-width;

                  @include respond-max($feature-grid-text-breakpoint) {
                    max-width: none;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .closing-transition-container {
        position: relative;
        left: 8%;

        @include respond-max($breakpoint) {
          width: 100%;
          left: 0;
        }

        .closing-container-column-right {
          position: relative;
          z-index: 2;

          background-image: url("../../../images/homePage/graphic-property.detail@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          /*---------------*/
          height: 1000px;
          width: 780px;

          @include respond-max($breakpoint) {
            top: 50px;
            width: 100%;
            height: 91vw;
            min-height: 400px;
            max-height: 91vw;
            background-size: contain;
            background-position: top;
          }

          @include respond-max($breakpoint-sm) {
            background-image: url("../../../images/homePage/graphic-mobile-property.detail@2x.png");
            height: 153vw;
            max-height: none;
            background-size: cover;
          }
        }
      }
    }
  }

  .my-md {
    margin: 60px 0;
  }

  .mb-sm {
    margin-bottom: 15px;
  }

  .mr-sm {
    margin-right: 15px;
  }

  .mb-xsm {
    margin-bottom: 5px;
  }

  .subheader-1 {
    margin-top: $subheader-margin-top;
  }

  .p-1 {
    margin-top: 12px;
  }

  .top-row {
    max-width: 975px;

    @include respond-max($breakpoint-sm) {
      width: $section-width;
      margin: auto;
    }
  }

  span.header-3 {
    @include respond-max($breakpoint-xsm) {
      display: block;
    }
  }

  .closing-content-flex {
    display: flex;

    @include respond-max($breakpoint) {
      flex-direction: column;
    }
  }

  .subheader-txt {
    @include respond-max(1400px) {
      display: none;
    }
  }

  .subheader-txt.mobile {
    display: none;

    @include respond-max(1400px) {
      display: block;
      margin-top: $subheader-margin-top;
    }
  }
}

﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.properties-list-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: #fafafa;
  position: relative;

  @include respond-max($mobile-breakpoint-width) {
    position: absolute;
    width: 100%;
    height: 100% !important;
    padding-top: 0;
    justify-content: center;
  }

  .properties-list-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .ui.inverted.dimmer {
      background-color: rgba(250, 250, 250, 0.75);
    }

    @include respond-max($mobile-breakpoint-width) {
      justify-content: center;
    }

    .controls-bar {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: $prop-list-margin-desktop $prop-list-margin-desktop 0 $prop-list-margin-desktop;

      @include respond-max($mobile-breakpoint-width) {
        margin-top: 0;
        margin: 0;
        margin: $prop-list-margin-mobile $prop-list-margin-mobile 0 $prop-list-margin-mobile;
      }

      .list-count-text {
        font-size: 18px;

        @include respond-max($mobile-breakpoint-width) {
          display: none;
        }
      }

      .list-count-text.saved-mode {
        @include respond-max($mobile-breakpoint-width) {
          display: none;
        }
      }

      .filter-sort-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        .filter-control-container {
          display: flex;
          align-items: center;
          cursor: pointer;

          @include respond-max($mobile-breakpoint-width) {
            visibility: hidden;
          }

          .filter-icon {
            background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 16px;
            width: 16px;
            cursor: pointer;
          }

          .control-text {
            margin-left: 10px;
          }
        }

        .filter-control-container.hide {
          display: none;
        }

        .sorting-control-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          .sorting-icon {
            background-image: url("../../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 32px;
            width: 32px;
          }

          .control-text {
            margin-right: 10px;
          }
        }
      }

      .filter-sort-controls.saved-mode {
        @include respond-max($mobile-breakpoint-width) {
          /*display: none;*/
        }
      }

      .left-controls {
        display: flex;
        align-items: center;
        /*.prop-count-text {
                    font-size: 18px;
                }*/
        .filter-control-container {
          display: flex;
          align-items: center;
          margin-left: 30px;

          .filter-icon {
            background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 16px;
            width: 16px;
            cursor: pointer;
          }

          .control-text {
            margin-left: 10px;
          }
        }

        .filter-control-container.hide {
          display: none;
        }
      }

      .sorting-control-container {
        display: flex;
        align-items: center;

        .sorting-icon {
          background-image: url("../../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          height: 32px;
          width: 32px;
          cursor: pointer;
        }

        .control-text {
          margin-right: 10px;
        }
      }

      .prop-list-sort-options-container {
        @include respond-max($mobile-breakpoint-width) {
          /*display: none;*/
        }
      }
    }

    .controls-bar.saved-mode {
      @include respond-max($mobile-breakpoint-width) {
        display: block;
      }
    }
  }

  .properties-list-content.margin-left {
    margin-left: 10px;
  }
}

.sidebar-controls-mobile-hide {
  @include respond-max($mobile-breakpoint-width) {
    display: none;
  }
}

.list-type-control-container {
  padding-bottom: 32px;
  width: 100%;

  @include respond-max($mobile-breakpoint-width) {
    padding-bottom: 16px;
  }

  .list-type-control {
    display: flex;
    height: 27px;
    background: #f4f4f4;
    padding: 2px;
    border-radius: 5px;

    .list-type {
      flex: 1;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      align-items: center;
      cursor: pointer;
    }

    .list-type.active {
      background: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    }
  }
}

.filter-sort-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @include respond-max($mobile-breakpoint-width) {
    padding-top: 0;
  }

  .control-text-margin-left {
    margin-left: 10px;
  }
  .control-text-margin-right {
    margin-right: 10px;
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.form-containerAdminUsers {
  .form-content {
    padding: 50px 100px 50px 100px;
    max-width: 1000px;
    min-width: 900px;
    margin: 50px auto;
    border: 1px solid #ebebeb;
  }

  .header {
    color: #152935;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 700;
    margin: 25px 0 15px;
  }

  .header-tab-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .form-IntExtUserBtn {
    display: flex;
    margin-top: 30px;
    width: 100%;
    max-width: 700px;
    border-radius: 7px;

    .form-intUserBtnPartner,
    .form-extUserBtnPartner {
      font-size: 14px;
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eaeaea;
      cursor: pointer;
      height: 50px;
    }

    .intPartner {
      border-radius: 7px;
      margin-right: 5px;
    }

    .extPartner {
      border-radius: 7px;
      margin-left: 5px;
    }

    .activePartner {
      border: 1px solid #152935;
      cursor: pointer;
    }
  }

  .newUsercontact-form {
    margin-top: 50px;

    .newUsercontact-inputContactFields {
      width: 100%;
      display: flex;
      margin-bottom: 50px;

      .newUsercontact-inputFields {
        margin-top: 30px;

        .newUsercontact-input {
          margin-top: 30px;
        }

        .input-group .label {
          color: #1b1c1d;
          font-weight: 500;

          &.optional {
            color: #8a9499;
          }
        }
      }

      .firLast {
        margin-right: 50px;
      }
    }

    .newUsercontactConf-dropDown {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;

      .newUsercontactConf-company {
        margin-top: 20px;
        display: flex;
        min-width: 235px;

        .input-group {
          width: 100%;
        }

        .input-group .ui.selection.dropdown {
          min-width: 100%;
        }
      }
    }

    .newUsercontactBtnSec {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 26px;
      margin-top: 60px;

      .newUsercontactBtnSec-createSec {
        width: 44%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .section-error-message {
    font-weight: 500;
    display: inline;
    color: red;
    font-size: 11px;
    padding: 5px;
  }

  .sign-up-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add-new-user-form-wrapper {
    border-bottom: 1px solid #d4d4d4;
  }

  .new-user-header-subtitle {
    font-size: 18px;
  }

  .new-user-header-grey {
    color: #aaaaaa;
    font-size: 13px;
    margin-top: 10px;
  }

  .add-new-user-user-groups {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #d4d4d4;
  }

  .input-group .label {
    color: #8a9499;
    font-weight: 500;
  }

  .bold-form-label {
    .input-group {
      .label.optional {
        color: black !important;
        font-weight: bold !important;
      }
    }
  }

  .bold-text {
    font-weight: bold;
  }

  .user-groups-radio-section {
    display: flex;
    margin-top: 15px;

    .user-groups-radio-column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .radio-btn-wrapper {
    font-size: 13px;
    margin-top: 15px;
  }

  .input-group .error-message {
    top: 5px !important;
    left: 5px !important;
  }

  .m-b-xsm {
    margin-bottom: 15px;
  }

  .m-t-sm {
    margin-top: 30px;
  }

  .m-r-50 {
    margin-right: 30px;
  }

  .p-b-50 {
    padding-bottom: 50px;
  }

  .flex-row-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .no-display-flex {
    display: block !important;
  }

  .width-fix-input {
    min-width: 375px !important;
  }

  .mar-right50 {
    margin-right: 50px;
  }

  .no-margin-top {
    margin-top: 0px !important;
  }
}

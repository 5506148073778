.navbarLogo {
  width: 100px;
  height: 35px;
  object-fit: contain;
}

.formLogo {
  width: 200px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 50px;
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.view-edit-main {
  display: flex;
  width: 100%;
  max-width: 1900px;
  min-height: 900px;
  min-width: 950px;

  .txt-xsml {
    font-size: 12px;
    line-height: 1;
  }

  .txt-sml {
    font-size: 14px;
    font-weight: 500;
  }

  .txt-med {
    font-size: 16px;
    font-weight: 700;
  }

  .section-title {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .section-info-msg {
    margin-top: 40px;
  }

  .sub-section-title {
    font-size: 16px;
    text-align: left;
  }

  .section-padding {
    padding: 55px 15px;
  }

  .section-padding.user-data-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .section-margin-top {
    margin-top: 60px;
  }

  .section-border-bottom {
    border-bottom: 1px solid #efedee;
    padding-bottom: 30px;
  }

  .flex-container {
    display: flex;
    margin-top: 25px;

    .flex1 {
      flex: 1;
      max-width: 230px;
    }
  }

  .flex-container.dropdowns {
    padding: 0 5px;
  }

  .profile-fields {
    .column {
      padding-top: 20px;
      padding-left: 0 !important;

      .basic-radio-btn {
        margin-top: 15px;
      }
    }

    .input-field-group {
      margin: 0px 0px 30px 0px;

      &.full {
        width: 520px;
      }

      &.half {
        width: 247px;
      }

      &.fourth {
        width: 100px;
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 320px));
    grid-gap: 10px;
    margin-top: 15px;
  }

  // Added as per discussion here: https://gitlab.com/flueid/flueid-pro/fp-ui/-/merge_requests/14#note_887741682
  .ui.grid.grid-left {
    margin-left: 0px !important;
  }

  .connection-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 10px 10px;
    position: relative;

    .profile-img {
      width: 50px;
      height: 50px;
      border-radius: 30px;
    }

    .icon-overflow {
      position: absolute;
      position: absolute;
      right: 10px;
      top: 2px;
    }

    .txt-gray {
      margin-bottom: 5px;
    }
  }

  .margin-top-user-profile {
    margin-top: 5px;
  }

  .text-grey {
    color: #646464;
  }

  .text-light-grey {
    color: #afafaf;
  }

  .name-header-container {
    display: flex;
    align-content: center;
  }

  .status-buton {
    border-radius: 25px;
    padding: 3px 10px 3px 10px;
  }

  .user-profile-wrapper {
    width: 80%;
  }

  .enable-account-btn {
    cursor: pointer;
    margin-top: 20px;
    font-weight: 500;
  }

  .body-container {
    display: flex;
    height: 100%;
  }

  .user-info-block-content {
    display: flex;
  }

  .tab-section {
    width: 100%;
    background-color: $white-background;
    min-height: 625px;

    .user-input-block {
      margin-top: 30px;
    }

    .user-info-block {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efedee;
      padding-bottom: 32px;
      margin-bottom: 46px;

      .icon-overflow {
        width: 16px;
        height: 20px;
      }

      .user-initial {
        display: flex;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        font-size: 40px;
        font-weight: 500;

        &.disabled {
          background: #fc5c65;
        }

        &.active {
          background: #000000;
        }

        &.pending {
          background: #ff9604;
        }

        &.approved {
          background: #26de81;
        }

        &.declined {
          background: #d0021b;
        }
      }

      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        padding: 5px 0;

        .user-name {
          font-size: 20px;
          font-weight: bold;
          padding: 0px 8px 10px 0px;
          align-self: flex-start;
        }

        .status-last-login-row {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .last-login {
          line-height: 14px;
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid #6a7076;
          color: #6a7076;
        }

        .user-status {
          &.disabled {
            color: #fc5c65;
          }

          &.active {
            color: #000;
          }

          &.pending {
            color: #ff9604;
          }

          &.approved {
            color: #26de81;
          }

          &.declined {
            color: #d0021b;
          }
        }
      }

      .user-img {
        display: block;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        object-fit: cover;
      }

      .user-img.default {
        background-image: url("../../../../../images/adminPage/graphic-noProfile.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    .tab-list {
      min-width: 20%;
      border-right: 1px solid #efedee;
      padding: 45px 0px 45px 15px;
      font-size: 12px;

      .selected {
        font-weight: 600;
        border-right: 3px solid black;
      }

      .deselected {
        color: #afafaf;
      }

      .list-name {
        padding: 10px 16px;
        cursor: pointer;
      }

      .list-name:hover {
        background-color: #f2f2f2;
      }
    }
  }

  .fields-padding {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .user-status-block {
    display: block;
    width: 100%;
  }

  .user-data-section {
    width: 100%;
    background-color: $white-background;
    // margin-left: 10px;
    display: flex;
    min-height: 900px;

    .container {
      position: relative;
      width: 100%;
    }

    .status_Txt {
      color: #4e565b;
      font-size: 15px;
    }

    .status-field {
      box-sizing: border-box;
      height: 20px;
      width: 68px;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 0.2px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      padding-top: 4px;
      position: absolute;
      left: 80px;
      right: 0;
      bottom: 5%;
    }

    .Disbaled {
      color: #a6b1b9;
      background-color: #efefef;
      border: 1px solid #a6b1b9;
    }

    .Active {
      color: #0f5da2;
      background-color: #d3e8fb;
      border: 1px solid #0f5da2;
    }

    .Pending {
      color: #ffc80a;
      background-color: #fff7da;
      border: 1px solid #ffc80a;
    }

    .Approved {
      color: #ff9604;
      background-color: #fff3e3;
      border: 1px solid #ff9604;
    }

    .profile-fields-mgr {
      width: 100%;

      .body {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      }

      .account-manager-main {
        display: flex;
        border: 1px solid #ececec;
        border-radius: 4px;
        background-color: #ffffff;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        color: #152935;
        font-size: 13px;
        line-height: 15px;
        font-weight: bold;
        align-items: center;
        margin-bottom: 20px;

        .account-image {
          width: 5%;

          .mgr-img {
            margin: auto;
            height: 37px;
            width: 37px;
            border-radius: 50%;
          }
        }

        .account-name {
          width: 19%;

          .email {
            color: #8a9499;
            font-size: 12px;
            line-height: 14px;
            font-weight: normal;
          }
        }

        .account-company {
          width: 14%;
        }

        .account-state {
          width: 14%;
        }

        .account-city {
          width: 14%;
        }

        .account-phone {
          width: 14%;
        }

        .account-action {
          width: 18%;
          text-align: end;
          cursor: pointer;
          color: #03b3f2;
        }
      }
    }

    .c_section-current-company {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 35px;

      .c-st {
        margin-right: 25px;
        width: 39%;

        @include respond-range(320px, 767px) {
          margin-right: 15px;
          margin-left: 15px;
          width: 100%;
        }

        .search-drop {
          .icon {
            background-image: none !important;
          }

          .ui.loading.dropdown > i.icon:after {
            font-size: 12px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .profile-fields {
      width: 100%;
    }

    .header {
      color: #152935;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      padding: 20px 0;
      display: flex;
      align-items: center;
    }

    .icon-edit {
      display: inline-block;
      margin-left: 10px;
    }

    .header.no-pad-top {
      padding-top: 0;
    }

    .flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .body-st {
      border-bottom: 1px solid #e1e1e1;
      padding: 0 5px;

      .status {
        color: #152935;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-right: 25px;
      }

      .flex-row {
        padding: 5px 0;
      }

      .status-btn {
        color: #03b3f2;
        font-size: 13px;
        line-height: 19px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .user-profile-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .flex-row {
        align-items: flex-end;
      }

      .left-col,
      .right-col {
        flex: 1;
        width: 50%;
      }

      .left-col {
        margin-right: 20px;
      }

      .right-col {
        margin-left: 20px;
        align-self: flex-start;
      }
    }

    .form-group {
      margin: 0px 0;
    }

    .no-marg-top {
      margin-top: 0;
    }

    .btn {
      cursor: pointer;
    }

    .btn-new {
      width: 150px;
      height: 30px;
      padding: 5px;
      min-height: 40px;
    }
  }
}

.ui.selection.dropdown {
  min-width: 100px !important;
  min-width: 40px !important;
}

.connection-action-popup {
  margin: 0;
  background: black;
  color: white;
}

.search-officers-account-execs {
  max-width: 540px;

  .search-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    position: relative;

    .flex-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }

    .section-title {
      font-size: 18px;
      font-weight: 700;
    }

    .scroll-container {
      position: relative;
      min-height: 360px;
      width: 110%;
    }

    .float-right {
      position: absolute;
      right: 0;
    }
  }
}

.scroll.officers-account-exec-results {
  padding: 20px 24px 20px 24px;
}

#user-info-action-popup {
  border: 1px solid #f8f8f8;
  box-shadow: 0 2px 4px 0 rgb(134 136 138 / 12%), 0 2px 10px 0 rgb(134 136 138 / 15%);
}

.user-info-action-popup {
  width: 160px;
  height: 90px;

  &.single-action {
    height: 56px;
  }

  .action {
    cursor: pointer;
    padding: 6px 6px;
    font-size: 12px;
    font-weight: 500;
  }

  .action:hover {
    background-color: #f2f2f2;
  }
}

.profile-tab-content {
  margin-top: 55px;
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

$form-content-pad-top: 50px;
$form-content-mobile-pad-top: 30px;
$form-content-margin-top: 30px;

.new-order-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  width: 100%;

  .new-order-form__info {
    margin-bottom: 40px;
    text-align: center;
  }

  .input-group.v2.error .error-message {
    left: 5px;
    top: 0px;
  }
  .input-group.v2.error .error-message-container {
    bottom: -20px;
    left: -10px;
    top: unset;
  }

  .new-order-form__sub-header {
    font-size: 20px;
    margin: 5px 0;
    text-align: center;
  }

  .new-order-form__border {
    margin: 20px 10px;
    max-width: 1200px;
    padding: 20px 10px;
    width: 98%;

    @include respond-min($breakpoint) {
      border: 1px solid #ebebeb;
      margin: 40px 0;
      padding: 80px 50px 100px;
    }
  }

  .new-order-form__fields-wrapper {
    margin: 0 auto;
    max-width: 500px;
  }

  .new-order-form__fields-wrapper_minHeight {
    min-height: 175px;
  }

  .new-order-form__section-header {
    font-weight: 500;
    margin: 50px 0;
    text-align: center;
  }

  .underlined-row {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    display: flex;
    padding: 22px 12px;
    position: relative;
    width: 100%;

    &.highlight {
      color: #03b3f2;
    }

    &:after {
      color: #000;
      content: "\f054";
      font-family: "Icons";
      font-size: 13px;
      font-weight: 100;
      position: absolute;
      right: 3px;
    }
  }

  .add-item {
    margin: 40px 0 50px;
    text-align: center;
    width: 100%;
  }

  .add-item__btn {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }

  .add-item__icon {
    background: #000;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    position: relative;
    vertical-align: -6px;
    width: 20px;

    &:before {
      content: "\002B";
      left: 50%;
      font-size: 16px;
      margin-right: 10px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
  }

  .id-input-wrapper {
    display: flex;
  }

  &.roles .btn-new {
    display: block;
    margin: 0 auto 10px;
    text-align: center;
  }

  .input-group {
    margin: 0;
    padding-top: 25px;

    &:first-child {
      padding-top: 0;
    }

    &.lastName,
    &.phone,
    &.zip,
    &.firstName,
    &.email,
    &.split {
      @include respond-min($breakpoint) {
        width: 240px;
      }
    }

    &.firstName,
    &.email,
    &.split {
      @include respond-min($breakpoint) {
        display: inline-block;
        margin-right: 20px;
        vertical-align: bottom;
      }
    }

    &.lastName,
    &.phone,
    &.zip,
    &.split.last {
      @include respond-min($breakpoint) {
        display: inline-block;
        margin-right: 0;
      }
    }

    &.zip {
      vertical-align: top;
    }

    &.half {
      width: 240px;
    }

    &.third {
      @include respond-min($breakpoint) {
        display: inline-block;
        margin-right: 10px;
        width: 180px;
      }
    }

    &.fourth {
      @include respond-min($breakpoint) {
        display: inline-block;
        width: 120px;
      }
    }

    &.last {
      margin-right: 0;
    }
  }

  .joint-borrowers {
    margin: 60px 0 75px;
  }

  .btn-prop-address {
    display: block;
    margin: 15px auto;
  }

  .btn-remove {
    border-color: $error-red;
    color: $error-red;
    margin-top: 42px;
  }

  .new-order-form__btn-container {
    margin: 60px auto 0;
    max-width: 330px;
    text-align: center;

    &.full-width {
      max-width: none;
    }
  }

  .new-order-form__mailing-address {
    margin-top: 65px;
  }

  .btn-order-continue {
    max-width: 330px;
    width: 100%;
  }
}

.order-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  .form-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 575px;
    padding-top: 5%;
    height: 100%;
    position: relative;

    @include respond-max($breakpoint-xsm) {
      height: calc(100vh - 70px);
      justify-content: space-between;
    }

    input[type="date"] {
      -webkit-appearance: none;
    }

    .back-icon-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      margin: 15px;
      cursor: pointer;
      z-index: 2;
    }

    .flex-column {
      flex-direction: column;
    }

    .sub-title {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    /*-----------------*/
    /* GENERAL MARING/PADDING STYLES */
    .marg-bottom-5 {
      margin-bottom: 5px;
    }

    .marg-bottom-15 {
      margin-bottom: 15px;
    }

    .marg-bot-20 {
      margin-bottom: 20px;
    }

    .marg-bottom-30 {
      margin-bottom: 30px;
    }

    .no-marg-top {
      margin-top: 0;
    }

    .marg-top-30 {
      margin-top: 30px;
    }

    .marg-top-35 {
      margin-top: 35px;
    }

    .pad-top-30 {
      padding-top: 30px;
    }

    .pad-bottom-30 {
      padding-bottom: 30px;
    }
    /*--------------------*/
    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5%;
      width: 100%;
      position: relative;

      @include respond-max(600px) {
        // ios bug fix - flex box was squashing items on order details and peoples section of order flow on iphone
        & > div {
          flex-shrink: 0;
        }
      }

      &.block {
        // ios bug fix
        @include respond-max(600px) {
          display: block;
        }
      }

      .title {
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 30px;
        text-align: center;
      }

      .order-type {
        .sub-title {
          margin-bottom: 30px;
        }
      }

      .order-details {
        .sub-title {
          text-align: center;
          width: 75%;
          margin-top: 30px;
        }
      }

      .txt-small {
        font-size: 12px;
      }

      .align-self-start {
        align-self: flex-start;
      }

      .underline {
        text-decoration: underline;
      }

      .pointer {
        cursor: pointer;
      }

      .input-group {
        width: 100%;
        margin: 10px 0;

        .label.required {
          font-weight: 700;
        }
      }

      .input-dropdown-split {
        display: flex;
        width: 100%;
        position: relative;

        .input-width {
          width: 70%;
        }

        .dash {
          position: absolute;
          left: 69.5%;
          bottom: 18px;
          z-index: 100;
        }

        .dropdown-width {
          width: 30%;

          .ui.selection.dropdown {
            min-width: 0;
          }
        }
      }

      .input-grid-state-zip {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 125px;
        grid-gap: 20px;

        .ui.selection.dropdown {
          min-width: 0;
        }
      }

      .txt-dark-gray {
        color: #787878;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        text-align: center;
      }

      .search-result-row {
        display: flex;
        border-bottom: 1px solid #e1e1e1;
        padding: 10px 0;
        cursor: pointer;
        font-size: 13px;
        color: #bebebe;
        font-weight: 500;
        width: 100%;

        .house-icon {
          background-repeat: no-repeat;
          background-size: contain;
          height: 10px;
          width: 10px;
          margin-right: 12px;
          position: relative;
          top: 4px;
          background-image: url("../../../../../images/titlefyPro/searchIcons/search-icon-prop@2x.png");
        }

        .result-info.underline {
          text-decoration: underline;
        }
      }

      .search-result-row:hover {
        background-color: #f5f8fa;
      }

      .search-result-row.no-result {
        cursor: default;
        border: none;
      }

      .search-result-row.no-result:hover {
        background-color: white;
      }

      .other-option-row {
        height: 60px;
        min-height: 60px;
        border-bottom: 1px solid #e1e1e1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        cursor: pointer;
        color: $light-blue;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }

    .form-content.full-width {
      width: 100%;
    }

    .form-content.order-details,
    .form-content.people {
      width: 100%;
    }

    .order-btn {
      margin-bottom: 10px;
    }

    .scroll-wrapper {
      width: 100%;
      position: relative;
      flex: 1;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }

    .scroll {
      bottom: 0;
      display: flex;
      flex-direction: column;
    }

    .prop-results {
      width: 100%;
    }

    .confirm-prop {
      width: 75%;
    }

    .fill-scroll-height {
      flex: 1;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .biz-src-overview {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    .order-details.scroll {
      .subtitle {
        text-align: center;
        width: 75%;
        margin-top: 30px;
      }
    }

    .contact-info.scroll {
      top: 0;
      width: 95%;
      padding: 5% 5% 0 5%;
      max-height: calc(100% - 125px);
    }

    .contact-info.scroll.full-width {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .contact-section-padding {
      width: 100%;
      padding: 0 5% 0 5%;
      display: flex;
      flex-direction: column;
    }

    .contact-section-padding-plus-5 {
      padding-left: calc(5% + 5px);
      padding-right: calc(5% + 5px);
    }

    .background-gray {
      background-color: #f6f6f6;
    }

    .subtype-options.scroll {
      max-height: calc(100% - 329px);
      max-height: calc(100% - 350px);
      width: 110%;
      padding: 0 5% 20px 5%;
    }

    .text-area.special-instructions {
      width: 90%;
      min-height: 315px;
      margin-bottom: 10%;
      padding: 15px;
      border: 1px solid #e1e1e1;
      background-color: #fafafa;
    }
  }

  .form-border.modal {
    max-width: none;
    min-height: 620px;
    max-height: 800px;
    border: none;
    margin-top: 0;
    padding-top: 0;

    @include respond-max($breakpoint-xsm) {
      max-height: none;
    }

    @include respond-max-height(620px) {
      min-height: 100vh;
    }

    @include respond-max-height(825px) {
      height: 100vh;
      max-height: none;
    }

    &.titlecheck {
      height: 550px;
    }

    .form-content {
      width: 100%;
      padding: 5%;
      flex: 1;
    }
  }

  .partner-logo.order-submission-result {
    width: 200px;
  }

  .order-result__logo {
    margin-bottom: 20px;
  }
}

.order-form-container.no-pad {
  padding: 0;
}

.order-details,
.order-modal {
  .contact-btns-width {
    max-width: 500px;
    margin: 0 auto;
  }

  .input-group {
    margin: 10px 0;
  }

  .additional-detail {
    .sub-title {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .icon-close {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  .contact-header-section {
    display: flex;
    min-height: 50px;
    justify-content: center;
    position: relative;
    align-items: flex-end;
    padding: 30px;
    border-bottom: 1px solid #e1e1e1;

    .sub-title {
      margin: 0;
    }

    .icon-close {
      position: absolute;
      bottom: 30px;
      right: 20px;
      top: auto;
      left: auto;
    }
  }

  .additional-detail {
    height: 100%;
    position: relative;

    .padding {
      padding: 6% 6% 8%;
    }

    .padding > div:first-child {
      margin: 0;
    }

    .first-child-no-margin > div:first-child {
      margin: 0;
    }

    .scroll-wrapper {
      min-height: 105px;
      max-height: 600px;
      overflow-y: auto;
      position: relative;
      width: 100%;
      height: calc(100% - 77px);

      @include respond-max($breakpoint-xsm) {
        max-height: none;
      }
    }
  }

  .additional-detail.pdf {
    max-height: calc(100% - 202px);
    overflow-y: auto;
    padding: 2%;

    .btn-container {
      padding: 5%;
      border-top: 1px solid rgb(225, 225, 225);
      position: absolute;
      bottom: 0;
      background: white;
    }
  }
}

.order-modal.pdf {
  max-height: 850px;
  min-height: 175px;
  position: relative !important;

  @include respond-max($breakpoint-xsm) {
    position: absolute !important;
    max-height: none;
  }

  @include respond-max-height(850px) {
    height: 100vh;
  }
}

/* BTN STYLES */
.order-modal,
.order-form-container {
  .btn {
    cursor: pointer;

    @include respond-max($breakpoint-xsm) {
      width: 100%;
    }
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 125px;
    border-top: 1px solid #e1e1e1;
    padding: 0 5%;

    .btn {
      box-shadow: none;
    }

    a {
      @include respond-max(400px) {
        flex: 1;
      }
    }
  }

  .btn-container-column {
    flex-direction: column;
  }

  .margin-b-30 {
    margin-bottom: 30px;
  }

  .btn-biz-src {
    margin-bottom: 10px;
    min-height: 50px;

    &:last-of-type {
      margin-bottom: 50px;
    }
  }
}

.order-form-container {
  .btn-container {
    position: sticky;
    bottom: 0;
    width: 100vw;
    background-color: white;
    border: none;
    margin-top: 50px;
    z-index: 5;

    @include respond-max($breakpoint-xsm) {
      margin-top: 0;
    }
  }
}

.order-modal {
  .form-border {
    .btn-container {
      width: 100%;
      margin-top: 0;
    }
  }
}

.order-details-container {
  animation: fadeIn 0.6s;
}

.btn-secondary {
  margin-top: 25px;
}

.notification-modal {
  min-height: 400px;
}

.notification-modal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  padding: 20px;
  text-align: center;
}

.notification-modal__header {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 25px;
}

.notification-modal__button-wrapper {
  margin-top: 40px;
  max-width: 330px;
  width: 100%;
}

.notification-modal__p-width-lg {
  max-width: 675px;
}

.order-results {
  align-items: center;
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  max-width: 1000px;
  min-height: 250px;
  padding: 30px 20px;
  text-align: center;
  width: 90%;

  @include respond-min($breakpoint) {
    min-height: 450px;
    width: 80%;
  }
}

.order-results__icon {
  height: 65px;
  margin-bottom: 50px;
}

.order-pending__header {
  margin: 35px 0 65px 0;
}

.order-results__header {
  margin-bottom: 20px;
}

.order-results__btn {
  margin: 35px 0;
  max-width: 330px;
  width: 100%;

  @include respond-min($breakpoint) {
    min-width: 300px;
  }
}

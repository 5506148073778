.services {
  animation: fadeIn 0.4s;
}

.order-types-title {
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 5px;
  text-align: center;
}

.order-types-subheader {
  font-size: 16px;
  margin: 0 0 25px;
  text-align: center;
}

.btn-preclose {
  background-color: transparent;
  color: #000;
  margin: 25px 0;

  &.disabled {
    background: #ebebeb;
    cursor: not-allowed;
    opacity: 0.4;
  }

  @include respond-min($breakpoint-md) {
    align-self: flex-start;
    margin: 0 10px;
    max-width: 135px;
    min-height: 50px;
  }
}

.preclose {
  .split & {
    @include respond-min($breakpoint-md) {
      border-right: 1px solid #f0f0f0;
      padding: 15px 0;
      width: 50%;
    }
  }
}

.preclose__options {
  @include respond-min($breakpoint-md) {
    .with-warning & {
      max-width: 330px;
    }
  }
}

.preclose__subtext {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  font-size: 12px;
}

.services-subtitle {
  color: #000;
  font-size: 15px;
  text-align: center;
}

.existing-order__text {
  color: #000;
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0 0 42px;

  @include respond-min($breakpoint-md) {
    line-height: 16px;
    margin: 10px 0 0 33px;
  }
}

.existing-order {
  border-radius: 7px;
  border: 1px solid #f0f0f0;
  margin: 40px 0 0 7px;
  padding: 20px;

  @include respond-min($breakpoint-md) {
    margin: 0 15px 0 0;
    max-width: 235px;
    padding: 15px;

    &:only-child {
      max-width: 300px;
    }
  }
}

.existing-order__link {
  display: block;
  margin: 10px 0 0 42px;
  text-decoration: underline;

  @include respond-min($breakpoint-md) {
    margin: 10px 0 0 33px;
  }
}

.existing-order__icon {
  display: inline-block;
  height: 20px;
  vertical-align: sub;
  width: 20px;
}

.existing-order__logo {
  color: #000;
  display: inline-block;
  font-weight: 700;
  height: 13px;
  margin-left: 20px;

  @include respond-min($breakpoint-md) {
    margin-left: 13px;
  }
}

.titlecheck-subtext {
  font-size: 14px;
  color: #6d7278;
  line-height: 1.3;
  padding: 0 20px;
  text-align: center;

  @include respond-min($breakpoint-md) {
    padding: 0;
  }
}

.closing-services {
  @include respond-min($breakpoint-md) {
    margin-top: 30px;
  }
}

.closing-services-wrapper {
  margin-top: 40px;

  @include respond-min($breakpoint-md) {
    display: flex;
    justify-content: center;
    margin-top: 0;

    .split & {
      display: block;
      padding: 15px 30px 15px 70px;
      width: 50%;
    }
  }
}

.closing-services-content {
  @include respond-min($breakpoint-md) {
    max-width: 450px;
  }
}

.closing-service-options {
  @include respond-min($breakpoint-md) {
    display: flex;
  }
}

.closing-services-blocked-message {
  max-width: 275px;
  text-align: center;
}

.order-types-mt-3 {
  margin-top: 3rem;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  margin: 40px auto 0;
  max-width: 575px;
  padding: 0 20px;
  @extend .order-types-mt-3;

  @include respond-min($breakpoint-md) {
    &.split {
      flex-direction: row;
      justify-content: center;
      max-width: 1200px;
    }
  }
}

.order-types-wrapper {
  width: 100%;
  height: 100%;
}

.order-types {
  margin: 0 auto;
  max-width: 870px;
  padding: 0 20px;

  @include respond-min(600px) {
    margin: 45px auto 0;
  }
}

.other-option-row {
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  color: #00b2f1;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 60px;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: -10px;
  min-height: 60px;
  width: 100%;
}

%base-btn {
  @extend .order-btn;

  align-items: center;
  background-color: transparent;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.58px;

  &:disabled {
    color: inherit;
  }
  &.hidden {
    display: none;
  }
}

.order-btn-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-min(600px) {
    flex-direction: row;
  }

  .btn-new {
    padding: 5px;

    @include respond-min(600px) {
      width: 130px;
    }
  }
}

.order-btn-row.wide {
  @include respond-min(600px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }

  .btn-new {
    @include respond-min(600px) {
      width: 200px;
    }
  }
}

.btn-types {
  @extend %base-btn;

  margin-bottom: 10px;
}

.btn-service {
  @extend %base-btn;

  margin: 10px 7px;
}

.btn-continue {
  @extend .btn-xxl;
  @extend .btn;

  border: none;
  background-color: transparent;

  &:disabled {
    color: #fff;
    background-color: #d4d7dc;
    cursor: not-allowed;
  }

  &.active {
    background-color: #00b2f1;
    color: #fff;
  }
}

.title-transaction {
  @extend .order-types-title;

  margin-bottom: 30px;
}

.title-construction {
  @extend .order-types-subheader;
}

.product-options-content {
  display: flex;
}

.policy-option-subheader {
  @extend .order-types-subheader;
}

.additional-options-subheader {
  @extend .policy-option-subheader;

  margin: 25px 0 30px !important;
}

.btn-additional-options {
  @extend .btn-service;

  margin: 0;
}

.btn-services-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 0 20px;
}

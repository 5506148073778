.mobile-hidden-inline {
  @include desktop-only(600px, inline);
}

.mobile-only-inline {
  @include respond-max(600px) {
    display: inline;
  }
}

.mobile-only {
  @include mobile-only($breakpoint);
}

.mobile-only-flex {
  @include mobile-only($breakpoint, flex);
}

.mobile-only-ib {
  @include mobile-only($breakpoint, inline-block);
}

.desktop-only {
  @include desktop-only($breakpoint);
}

.desktop-only-flex {
  @include desktop-only($breakpoint, flex);
}

.desktop-inline {
  @include respond-min($breakpoint) {
    display: inline;
    //@todo - remove important when p styles have been reset
    margin-right: 5px !important;
  }
}

.margin-auto {
  display: block;
  margin: 0 auto;
}

.no-bb {
  border-bottom: none !important;
}

.desktop-no-bb {
  @include respond-min($breakpoint) {
    border-bottom: none !important;
  }
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

/* ------------- */
/* NOTE!! These basic-table styles mirror exactly what's been created in the feature/adminV2 branch under the classname "admin-table"
    These styles were copied over because of a rushed deadline to deliver a table with these same styles in a different feature
    The feature/adminV2 branch is a far away from getting merged into development.
    Once feature/#523-ReportDocsUI gets merged into development and dev gets downmerged to adminV2
    these duplications will want to be resolved. 
*/

$col-arrow-border: 4px;

.basic-table.ui.basic.table {
  width: 100%;
  min-width: 600px;
  border: none !important;

  .header-margin {
    height: 5px;
  }

  .cell-txt-medium {
    font-weight: 500;
  }

  .cell-txt-gray {
    color: #646464;
  }

  .cell-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .cell-space-between {
    justify-content: space-between;
  }

  .cell-pad-left-15 {
    padding-left: 15px;
  }

  .cell-pad-right-15 {
    padding-right: 15px;
  }

  .col-sort-control-container {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .sort-arrow-margin {
    margin-left: 10px;
  }

  .col-sort-asc,
  .col-sort-desc {
    width: 0;
    height: 0;
    border-left: $col-arrow-border solid transparent;
    border-right: $col-arrow-border solid transparent;
  }

  .col-sort-asc {
    border-bottom: $col-arrow-border solid #bebebe;
    margin-bottom: 2px;
  }

  .col-sort-desc {
    border-top: $col-arrow-border solid #bebebe;
    margin-top: 2px;
  }

  .col-sort-desc.sort-active {
    border-top: $col-arrow-border solid black;
  }

  .col-sort-asc.sort-active {
    border-bottom: $col-arrow-border solid black;
  }
}

.basic-table.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: #f4f4f4;
}

.basic-table.ui.basic.table th {
  border: none;
  padding: 12px;
  font-weight: 500;
}

.basic-table.ui.basic.table tr {
  cursor: pointer;
  background-color: white;
}

.basic-table.ui.basic.table tr:hover > td {
  background-color: #fafafa;
}

.basic-table.ui.basic.table td {
  padding: 12px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #f4f4f4;
}

.basic-table.ui.basic.table td {
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

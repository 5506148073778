﻿@import "../../modules/_mixins.scss";
@import "../../modules/_constants.scss";
@import "./_homePageConstants.scss";

.insurfulFooter-main {
  width: 100%;
  padding: 50px 0;
  position: relative;

  @include respond-max($breakpoint) {
    margin-top: -40px;
    padding-top: 100px;
  }
  .insurfulFooter-sec {
    width: 80%;
    margin: auto;
    display: flex;
    max-width: $home-max-width;
    position: relative;

    @include respond-max($breakpoint) {
      flex-direction: column;
      width: 90%;
    }

    .insurfulFooter-logo {
      width: 50px;
      height: 50px;
      background-image: url("../../images/homePage/flueid-logomark.png");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;

      @include respond-range(320px, 1024px) {
        margin: auto;
      }
    }

    .p-4.footer-link-careers {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 45px;

      @include respond-max($breakpoint) {
        bottom: -25px;
        left: 0%;
        right: 0;
      }
    }

    .insurfulFooter-textMain {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      justify-content: flex-end;

      @include respond-range(320px, 1024px) {
        margin-left: 0;
      }

      .p-4 {
        @include respond-range(320px, 1024px) {
          margin-top: 15px;
          text-align: center;
        }
      }

      .insurfulFooter-termsMainWeb {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        @include respond-range(320px, 1024px) {
          margin-top: 0;
          display: none;
        }
      }

      .insurfulFooter-termsMainMob {
        @include respond-range(1025px, 3000px) {
          display: none;
        }

        .mob {
          margin-top: 8px;
          color: #000000;
        }

        .insurfulFooter-termasMain {
          display: flex;
          justify-content: space-between;
          width: 50%;
          margin: auto;
          max-width: 150px;

          .p-4 {
            color: #000000;
            font-family: "acumin-pro";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
          }

          .mob {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

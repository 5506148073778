.client-order-settings.profile-tab-content .user-info-block-content .user-info-block {
  padding-bottom: 12px;
  margin-bottom: 30px;
}

.client-order-settings .input-group.v2 {
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  margin-bottom: 24px;
}

.client-order-settings .input-group.v2.disabled {
  opacity: 24%;
}

.client-order-settings .input-group.v2 .label {
  color: #020c14;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
}

.client-order-settings .input-group.v2 .label.required {
  color: #020c14;
}

.client-order-settings .input-group.v2 .label.optional {
  color: #020c14;
}

.client-order-settings .sub-section-title {
  color: #020c14;
  font-size: 16px;
}

.client-order-settings .sub-section-box {
  background-color: #fbfbfb;
  padding: 24px 0px 0px 17px;
}

.client-order-settings .user-input-block .ui.fitted.toggle.checkbox,
.client-order-settings .user-input-block .ui.fitted.toggle.checkbox label,
.client-order-settings .user-input-block .ui.fitted.toggle.checkbox label::before {
  height: 24px;
  width: 48px;
}

.client-order-settings .user-input-block .ui.fitted.toggle.checkbox label::after {
  height: 24px;
}

.client-order-settings .user-input-block .ui.fitted.toggle.checkbox input:checked ~ label::after {
  left: 27px;
}

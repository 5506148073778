﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

$col-arrow-border: 4px;

.admin-table.ui.basic.table {
  width: 100%;
  min-width: 600px;
  border: none !important;
  /*border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;*/
  .header-margin {
    height: 5px;
  }

  .cell-txt-medium {
    font-weight: 500;
  }

  .cell-txt-gray {
    color: #646464;
  }

  .cell-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .cell-space-between {
    justify-content: space-between;
  }

  .cell-pad-left-15 {
    padding-left: 15px;
  }

  .cell-pad-right-15 {
    padding-right: 15px;
  }

  .col-sort-control-container {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .sort-arrow-margin {
    margin-left: 10px;
  }

  .col-sort-asc,
  .col-sort-desc {
    width: 0;
    height: 0;
    border-left: $col-arrow-border solid transparent;
    border-right: $col-arrow-border solid transparent;
  }

  .col-sort-asc {
    border-bottom: $col-arrow-border solid #bebebe;
    margin-bottom: 2px;
  }

  .col-sort-desc {
    border-top: $col-arrow-border solid #bebebe;
    margin-top: 2px;
  }

  .col-sort-desc.sort-active {
    border-top: $col-arrow-border solid black;
  }

  .col-sort-asc.sort-active {
    border-bottom: $col-arrow-border solid black;
  }

  .col-actions-header {
    width: 60px;
  }
}

.admin-table.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: #f4f4f4;
}

.admin-table.ui.basic.table th {
  border: none;
  padding: 12px;
  font-weight: 500;
}

.admin-table.ui.basic.table tr {
  cursor: pointer;
  background-color: white;
}

.admin-table.ui.basic.table tr:hover > td {
  background-color: #fafafa;
}

.admin-table.ui.basic.table td {
  padding: 12px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #f4f4f4;
}

.admin-table.ui.basic.table td {
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.admin-table.ui.basic.table td:first-child {
  /*border-left: 1px solid rgba(34, 36, 38, 0.15);*/
}

.admin-table.ui.basic.table td:last-child {
  /*border-right: 1px solid rgba(34, 36, 38, 0.15);*/
}

﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.properties-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  /* need to set this body container color to white/grey based on whether the filters are open or not */
  .properties-body-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    width: 100%;
    background: #fafafa;

    .body-content {
      width: 100%;
      display: flex;

      @include respond-max($mobile-breakpoint-width) {
        flex-direction: row;
        width: 100%;
        margin: 0;
        position: relative;
      }

      .sidebar-container {
        flex: 0.75;
        max-width: 400px;

        @include respond-max($mobile-breakpoint-width) {
          display: none;
        }
      }

      .sidebar-container.fixed-maxwidth.white {
        background: white;
      }

      .body-content-mobile {
        display: none;

        @include respond-max($mobile-breakpoint-width) {
          display: flex;
          flex: 1;
          position: relative;
          width: 100%;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }

  .properties-body-container.background-white {
    background: white;
  }

  .properties-body-container.mobile {
    display: none;

    @include respond-max-width-max-height($mobile-breakpoint-width, $mobile-breakpoint-height) {
    }
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.division-branch-settings-form-container-wrapper {
  width: 885px;
  min-height: 900px;
  background-color: #ffffff;
  border-radius: 4px;
  //border: 1px solid #ececec;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.division-branch-settings-form-container-content {
  margin: 50px;
}

.division-branch-form-title-group {
  display: flex;
  justify-content: space-between;
}

.division-branch-form-title-main {
  margin-bottom: 30px;
}

.division-branch-form-title {
  font-size: 18px;
  font-weight: bold;
}

.division-branch-form-subtitle {
  font-size: 15px;
  color: #4e565b;
  margin-top: 2px;
}

.division-branch-form-title-btn {
  height: 40px;
  width: 150px;
  cursor: pointer;
}

.add-division-top-btn {
  cursor: pointer;
}

.division-form-container {
  margin-bottom: 20px;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.division-form-content {
  margin: 15px 20px;
}

.division-form-header-group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #d1d1d1;
}

.division-form-header {
  font-size: 16px;
  font-weight: bold;
}

.division-form-main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}

.division-form-left {
  margin-right: 50px;
  width: 90px;
}

.division-form-left-small {
  margin-right: 20px;
  width: 60px;
}

.division-form-city {
  font-size: 13px;
}

.division-form-right {
  margin-right: 20px;
}

.division-form-address {
  font-size: 13px;
}

.edit-branch-container {
  margin: 50px 70px 50px 70px;
}

.edit-branch-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}

.edit-branch-subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.edit-branch-division-name {
  margin-bottom: 40px;
  width: 345px;
}

.branch-form-index {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.branch-form-group-content {
  margin: 40px;
}

.branch-form-container {
  margin-bottom: 40px;
}

.branch-form-content {
  display: flex;
  flex-wrap: wrap;
}

.branch-form-group-input {
  width: 460px;
  margin-bottom: 15px;
}

.branch-form-group-input-smaller {
  width: 90px;
  margin-right: 15px;

  .ui.selection.dropdown {
    min-width: 90px !important;
  }
}

.branch-form-group-input-small {
  width: 100px;
  margin-right: 15px;
}

.branch-form-group-input-medium {
  width: 240px;
  margin-right: 10px;
}

.form-bottom-row {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 15px;
}

.cancel-btn {
  color: #4e565b;
  background: #f3f3f3;
  border: solid 1px #d0d0d0;
}

.edit-division-btn {
  background-image: url("../../../../../images/commonIcons/icon-edit.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.add-branch-header-btn {
  font-size: 12px;
  color: #03b3f2;
  cursor: pointer;
}

.division-row-right-btn {
  background-image: url("../../../../../images/commonIcons/icon-edit.png");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.header-btn {
  margin-top: 0px;
}

.division-header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.division-form-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
  border-top: solid 1px #d0d0d0;
}

.add-branch-btn {
  margin-bottom: 50px;
}

.division-form-outer-left {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

.blue-small-txt {
  color: #03b3f2;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  cursor: pointer;
}

.add-branch-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.add-edit-division-content {
  margin: 40px;
}

.add-division-header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.add-division-header-title {
  font-size: 18px;
  font-weight: bold;
}

.add-division-title {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 20px;
}

.bottom-done-box {
  margin: 40px 10px;
}

.bottom-modal-section {
  border-top: 1px solid #e5e9ed;
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.bottom-modal-section-branch {
  border-top: 1px solid #e5e9ed;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.save-changes-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

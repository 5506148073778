﻿@import "./variables.module";
/*---------------------------------*/
/* Fonts */
/*---------------------------------*/

$font-main: Helvetica, Arial, sans-serif;

/*---------------------------------*/
/* Colors */
/*---------------------------------*/

$light-blue: #00b2f1;
$lightest-blue: #fafcfd;
$light-blue-hover: #23bef4;
$blue: #2980b9;

$light-gray: #a6b1b9;
$lighter-gray: #c0c0c0;
$light-gray-hover: #cbc8c8;

/* Property Status colors*/
$orange-hot-prop: #ff5f00;
$green-listed: #26de81;
$green-lead: #76a646;
$dark-blue-pending: #2c3e50;
$dark-blue-do-not-contact: #50658c;
$deep-purple-prospect: #8a53ae;
$yellow-recently-sold: #f1c40f;
$gray-off-market: #d0d0d0;
$dark-gray-off-market-active: #4e555b;
$pink-is-saved: #ff4b76;

/*---------------------*/
$red: #e72b32;
$warning-yellow: #f1c40f;
$error-red: #e74c3c;
$bright-green: #4bb97d;

$white-background: #ffffff;
/*---------------------------------*/
/* Breakpoints */
/*---------------------------------*/
$breakpoint: 1024px;

$breakpoint-xxsm: 320px;
$breakpoint-xsm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-sml: 1440px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;

$mobile-breakpoint-width: #{$mobBreakpoint}px;
// $mobile-breakpoint-width: 812px;
$mobile-breakpoint-height: 825px;

/* mobile breakpoint for the property detail page*/
$prop-detail-mobile-breakpoint: 805px;

/*---------------------------------*/
/* Padding & Margins */
/*---------------------------------*/
$padding-16: 16px;
$margin-20: 20px;
$margin-40: 40px;

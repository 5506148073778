﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

/* Common text styles, structures, designs can go here. */

/* -------------------------*/
/* TEXT STYLING */

* {
  font-family: $font-main !important;
}

.icon {
  font-family: Icons !important;
}

.svg-icon {
  height: 100%;
  width: 100%;
  &.green g {
    stroke: #86e2c5;
  }
}

.loader.active.blue:after {
  border-color: #78c1ec transparent transparent;
}

.header-1 {
  font-size: 64px;
  font-weight: 700;
  color: #ffffff;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 27px;
  }

  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
    font-size: 40px;
  }
}

.header-2 {
  font-size: 40px;
  font-weight: 900;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 24px;
  }

  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
    font-size: 35px;
  }
}

.header-3 {
  font-size: 32px;
  font-weight: 900;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 20px;
  }

  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
    font-size: 28px;
  }
}

.header-4 {
  font-size: 26px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 20px;
  }
}

.header-5 {
  font-size: 24px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 20px;
  }
}

.header-6 {
  font-size: 20px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 16px;
  }
}

.header-7 {
  font-size: 16px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 16px;
  }
}

.header-8 {
  font-size: 17px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    font-size: 17px;
  }
}

.control-text,
.filter-text {
  font-size: 14px;
  font-weight: 500;
}

.control-text {
  color: #000000;
}

.filter-text {
  color: #969696;
}

.filter-text.active {
  color: $light-blue;
}

.standard-text-link {
  font-size: 14px;
  font-weight: 700;
  color: $light-blue;
}

.paragraph-small {
  font-size: 13px;
  line-height: 15px;
}

.main-blue {
  color: $light-blue;
}

.txt-gray {
  color: #bebebe;
}

.unknown-gray {
  color: $light-gray;
}

.warn-red {
  color: $red;
}

.cursor-pointer {
  cursor: pointer;
}

.registered-tm {
  font-size: 8px;
  top: -5px;
}

/*-------------------------*/

/*-------------------------*/
/* BUTTON STYLING */
/* Prevent Chrome's new default outline style */
button:focus {
  outline: none;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.58px;
  transition: 0.3s ease all;
}

.btn-xxl {
  height: 50px;
  width: 360px;
}

.btn-xl {
  height: 50px;
  width: 274px;
}

.btn-large {
  width: 174px;
  height: 44px;
}

.btn-medium {
  width: 141px;
  height: 44px;
}

.btn-small {
  height: 23px;
  width: 98px;
  border-radius: 11.5px;
  font-size: 10px;
}

.btn-disabled {
  color: #ffffff !important;
  background-color: #d4d7dc !important;
  cursor: not-allowed !important;
}

.btn-disabled-light-gray {
  background-color: #f7f7f7 !important;
  border: 1px solid #e1e1e1;
  color: #8a9499 !important;
  cursor: not-allowed !important;
}

.btn-grey {
  color: #afafaf !important;
  border: 1px solid #e1e1e1;
}

.btn-grey:hover {
  color: $light-gray-hover !important;
  border-color: $light-gray-hover;
}

.btn-green {
  background-color: $bright-green;
  color: #ffffff;
}

.btn-green-lite {
  color: $bright-green;
  border: 1px solid $bright-green;
}

.btn-basic {
  border: 1px solid #e1e1e1 !important;
  background-color: white;
}

.btn-blue {
  background-color: $light-blue;
  color: #ffffff;
}

.btn-blue:hover {
  background-color: $light-blue-hover;
}

.btn-black {
  color: white;
  background: black;
}

.transparent-border {
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.transparent-border:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

/* small buttons without any "click" behavior should not have mouse hover state styling */
.btn-small:hover.btn-grey {
  color: #afafaf !important;
  border: 1px solid #e1e1e1;
}

.btn-small:hover.btn-blue {
  background-color: $light-blue;
  color: #ffffff;
}

.btn-text {
  border: none;
  background: no-repeat;
  text-decoration: underline;
  cursor: pointer;
}

/* radio button */
.radio-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.radio-btn.outer-circle {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  cursor: pointer;

  .inner-circle {
    width: 10px;
    height: 10px;
    background-color: #e1e1e1;
    border-radius: 30px;
    transition: all 0.3s linear;
  }

  .inner-circle.active {
    background-color: $light-blue;
  }
}

/*-------------------------*/

.border-lite {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

/*----------------------------*/
/* SCROLL STYLING */

.scroll {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-3dlight-color: #ededed;
}

.scroll > * {
  // if the size of the flex items are greater than the container, prevent any children from getting shrunk
  flex-shrink: 0;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  background: white;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 8px;
}

.overflow-y_hidden {
  overflow-y: hidden;
}

/*--------------------------*/

/*---------------------------*/
/* Semantic UI override styling */

.ui.grid {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ui.modal {
  .btn {
    cursor: pointer;
  }

  .modal-content_overflowY {
    max-height: 60vh;
    overflow-y: auto;
  }

  .actions.modal-actions_padding {
    @include respond-max($breakpoint-sm) {
      padding: 1rem !important;
    }
  }
}

.ui.modal.large {
  max-width: 690px !important;
}

.ui.modal.modal-small {
  width: 100% !important;
  max-width: $breakpoint-xsm;
  @include respond-max($breakpoint-xsm) {
    max-width: none;
    height: 100vh;
  }
}

.ui.modal.order-modal,
.ui.modal.confirm-changes-modal {
  .sub-title {
    font-size: 16px;
  }

  @include respond-max($breakpoint-xsm) {
    max-width: none;
    width: 100% !important;
    height: 100vh;
  }
}

.ui.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.35) !important;
}

.ui.page.modals.dimmer:has(.ui.modal.full-page) {
  padding-top: 0;
}

.ui.modal.full-page {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  margin-top: 0 !important;
}

.popup.ui.label-property,
.popup.ui.farm-options,
.popup.ui.mls-info {
  @include respond-max-width-max-height($mobile-breakpoint-width, $mobile-breakpoint-height) {
    right: auto !important;
    left: 0 !important;
    top: auto !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: 100%;
    max-width: none;
  }
}

.ui.segment {
  position: relative;
  background: transparent;
  box-shadow: 0 0 0 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
}

.ui.loader.massive {
  padding-top: 80px !important;
  width: 100% !important;
}

.ui.dropdown.dropdown-with-label {
  position: relative;
  z-index: 1000;
  background: transparent !important;
}

.ui.progress {
  /*width: 100%;*/

  .bar {
    height: 5px !important;
    background: $light-blue !important;
  }

  .label {
    left: 102% !important;
    top: -10px !important;
    margin-top: 0 !important;
  }
}

.ui.pagination.menu .item:focus {
  outline-color: #000;
}

.confirm-changes-modal {
  .confirm-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .message-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10%;

      .title {
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }

    .btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 125px;
      border-top: 1px solid #e1e1e1;
      padding: 2%;
    }
  }
}

/*---------------------------*/

/* --------------------------*/
/* Links */

a {
  text-decoration: none;
  color: inherit !important;
}
/*--------------------------*/

.range-slider {
  /*background: linear-gradient(to right, $light-blue 0%, $light-blue 50%, #fff 50%, #fff 100%);*/
  border-radius: 8px;
  height: 4px;
  width: 230px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  cursor: pointer;
  margin: 0;
  box-shadow: 0px 0px 2px grey, 0px 0px 1px grey;
  border: 6px solid white;
  background: $light-blue;
}

.mls-info-popup-container {
  width: 300px;
  padding: 10px 18px;

  @include respond-max-width-max-height($mobile-breakpoint-width, $mobile-breakpoint-height) {
    width: auto;
    max-width: 500px;
  }

  .close-icon {
    cursor: pointer;
    float: right;
    width: 12px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../../images/commonIcons/icon-close@2x.png");
  }

  .mls-info-description {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .locations-scroll-container {
    height: 240px;
    overflow-y: auto;
  }

  ul {
    padding-left: 21px;
    margin: 5px 0;
    font-size: 145%;

    .mls-org-name {
      font-size: 14px;
      position: relative;
      bottom: 1px;
      right: 5px;
    }
  }
}

.loading-dots {
  .dot {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

%active-dot {
  &.active {
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 50%;
      position: absolute;
      right: 2px;
      top: 3px;
    }
  }
}

.basic-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;

  .pad-left {
    padding-left: 15px;
    text-align: right;
  }

  .pad-right {
    padding-right: 15px;
  }
}

.btn-new {
  align-items: center;
  background-color: #000;
  border-radius: 30px;
  border: none;
  color: #fff !important;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  max-width: 330px;
  min-height: 50px;
  padding: 15px;
  width: 100%;

  &.blue {
    background-color: #03b3f2;
  }

  &.white {
    background-color: #fff;
    color: #000 !important;
  }

  &.inverted:not(:disabled) {
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #000 !important;

    &.border-black {
      border: 1px solid #000;
    }

    &.active {
      background-color: #000;
      color: #fff !important;
    }
  }

  &:disabled:not(.no-disable-style) {
    background-color: #ebebeb;
    color: #afafaf !important;
    cursor: not-allowed;
  }

  &.squared {
    border-radius: 4px;
  }

  &.green-inverted {
    border: 1px solid #78eda8;
    background-color: #fff;
    color: #78eda8 !important;
  }

  &.medium {
    width: 200px;
  }

  &.small {
    width: 115px;
  }

  &.outlined {
    background-color: unset;
    border: 1px solid black;
    color: black !important;
  }

  &.link {
    text-decoration: underline;
    color: black !important;
    background-color: unset;
  }

  &.link:disabled {
    background: unset;
  }
}

.btn-link {
  background-color: transparent;
  border-width: 0 0 1px 0;
  cursor: pointer;
  padding: 1px 0;
}

.btn-link_red {
  color: red;
  border-color: red;
}

.separator {
  border: 1px solid #eaeaea;
  border-width: 1px 0 0 0;
  margin: 10px 0;
}

.btn-modal-close-x {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 22px;
  height: 18px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 18px;
  z-index: 1000;
}

.underline {
  text-decoration: underline;
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

.basic-radio-btn {
  display: flex;
  align-items: center;
  min-width: 90px;
  max-width: 200px;

  .outer-btn {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-btn {
      width: 9px;
      height: 9px;
      border-radius: 2px;
      background-color: transparent;
      transition: 0.3s ease all;
    }

    .inner-btn.checked {
      background-color: black;
    }
  }

  .outer-btn.circle {
    border-radius: 15px;
  }

  .inner-btn.circle {
    border-radius: 9px;
  }

  .label {
    /* consider adding an overflow ellipsis to label */
    margin-left: 10px;
  }
}

@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";
@import "../../_adminPageContainer.scss";

$sort-arrow-border: 6px;

.users-page-container {
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  min-width: $admin-body-min-width;
  max-width: $admin-body-max-width;
  @extend .admin-body-left-right-padding;
}

.users-search-invite {
  height: 150px;
  padding: 20px 0;

  .users-textInvite {
    height: 40px;
    display: flex;
    justify-content: space-between;

    .users-text {
      float: left;
      font-size: 22px;
      color: #152935;
      font-weight: 700;
      line-height: 40px;
      font-family: $font-main;
    }
  }

  .user-searchFilter-container {
    display: flex;
    justify-content: space-between;
  }

  .add-user-btn {
    cursor: pointer;
    justify-self: flex-end;
    margin-top: 33px;
    min-width: 140px;
    margin-left: 20px;
  }

  .users-searchFilter {
    padding-top: 35px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    grid-gap: 8px;
    position: relative;

    .filters-wrapper {
      margin-left: 0px;
    }

    .btn-filter {
      width: 100%;
    }
  }

  .scroll-filters-options {
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
  }

  .scroll-filters-options::-webkit-scrollbar {
    width: 5px;
  }

  .scroll-filters-options:hover {
    overflow-y: auto;
  }
}

/* PENDING REMOVAL - once new <PartnersFiltersDropdown/> is used on client companies list, this can be removed */
.users-list-filters-popup {
  width: 273px;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  color: #152935;
  font-size: 13px;
  line-height: 15px;

  .header-filter {
    display: flex;
    border-bottom: 1px solid #d4d4d4;
    justify-content: space-between;
    padding: 5px;

    .status-label {
      display: flex;

      .status {
        color: #03b3f2;
        font-weight: 600;
      }
    }

    .close {
      background-image: url("../../../../../images/adminPage/icon-close@3x.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }

  .checkboxMain {
    padding: 5px;

    .checkbox {
      padding-top: 14px;
      padding-bottom: 12px;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      color: #03b3f2;
    }

    .ui.checkbox input:checked ~ .box:before,
    .ui.checkbox input:checked ~ label:before {
      border-radius: 2px;
      background: #03b3f2;
      display: inline-block;
      margin-right: 1ex;
      content: "";
    }

    .ui.toggle.checkbox .box:after,
    .ui.toggle.checkbox label:after {
      width: 1rem;
      height: 1rem;
      top: 3px;
    }
  }
}

@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

$list-row-padding: 20px;
$list-row-bottom-border: 1px solid #efedee;
$list-column-padding: 30px;

.property-main-container {
  width: 100%;

  .map-Container {
    height: 400px;
    width: 100%;
    position: relative;

    @include respond-range(320px, 768px) {
      height: 250px;
    }

    .image-horizantal {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .prop-location-map-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .property-details-factsDetails-SubFacts {
    @extend %sub-page-container;
    margin-bottom: 100px;
    margin-top: 30px;
  }
  .property-detail-header {
    font-weight: 900;
    color: #152935;
    font-size: 26px;
    line-height: 30px;
    margin: 40px 0 $list-row-padding;
  }

  .property-detail-header2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-top: 20px;
  }

  .property-detail-subheader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    color: #2980b9;
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    border-bottom: 1px solid #efedee;

    @include respond-range(320px, 979px) {
      font-size: 13px;
    }
  }

  .property-detail__section-header {
    font-size: 16px;
    font-weight: 700;
    margin: 30px 0 10px;
  }

  .property-detail-listContainer {
    display: flex;
    margin: 0 0 40px;

    @include respond-range(320px, 979px) {
      flex-direction: column;
    }
  }

  .property-detail-listColumn-left {
    padding-right: $list-column-padding;
    @extend %list-column;
  }

  .property-detail-listColumn-right {
    padding-left: $list-column-padding;
    @extend %list-column;
  }

  .property-factItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    border-bottom: $list-row-bottom-border;
    padding: $list-row-padding 0;
  }

  .property-factItem.list-row-empty-space {
    display: none;
    @include respond-min(979px) {
      visibility: hidden;
      display: flex;
    }
  }

  .property-factListItem {
    display: flex !important;
    height: 18px;
    color: #000000;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;

    @include respond-range(320px, 979px) {
      font-size: 13px !important;
      line-height: 22px;
    }
  }

  a {
    .property-factListItem {
      color: $light-blue;
    }
  }

  .property-familyStatus {
    height: auto;
    font-weight: 700;
    color: #152935;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    max-width: 240px;
    word-wrap: break-word;

    @include respond-range(320px, 979px) {
      font-size: 13px !important;
      line-height: 22px;
    }
  }

  .document-number {
    display: inline-block;
    cursor: pointer;
    color: $light-blue;
  }

  .ui.inline.loader {
    margin-left: 6px;
    margin-top: -12px;
    margin-bottom: -6px;
  }

  svg {
    margin-left: 5px;
    margin-top: -10px;
    margin-bottom: -6px;
    width: 25px;
    height: 25px;
  }

  /* this refers to basic sizing for the subsequent icons*/
  .property {
    height: 20px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 80%;
    margin-right: 5px;
  }

  .property-propertyIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/prop-type-icon@2x.png");
  }

  .property-BuiltIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/year-built-icon@2x.png");
  }

  .property-livingIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-sqft@2x.png");
  }

  .property-BedroomIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bedroom@2x.png");
  }

  .property-BathroomIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bathroom@2x.png");
  }

  .property-totalRoomsIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-total-rooms@2x.png");
  }

  .property-stairsIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/stairs-icon@2x.png");
  }

  .property-poolIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/pool-icon@2x.png");
  }

  .property-lotIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/lot-sisze-icon@2x.png");
  }

  .property-parkingtIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/parking-icon@2x.png");
  }

  .property-firePlaceIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/fireplace-icon@2x.png");
  }

  .property-UnitsIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-units@2x.png");
  }

  .property-countyIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/county-icon@2x.png");
  }

  .property-occupancyIcon {
    background-image: url("../../../../images/titlefyPro/propertyIcons/occupancy-icon@2x.png");
  }

  .mb-sm {
    margin-bottom: 12px;
  }

  .mb-md {
    margin-bottom: 30px;
  }

  .mb-lg {
    margin-bottom: 50px;
  }

  .mb-xl {
    margin-bottom: 70px;
  }

  .mb-xxl {
    margin-bottom: 90px;
  }
}

%list-column {
  width: 50%;

  @include respond-range(320px, 979px) {
    font-size: 14px;
    width: 100%;
    padding: 0px;
  }
}

.prop-data-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 60px;
  margin-bottom: 40px;

  @include respond-min(980px) {
    grid-template-columns: 1fr 1fr;
  }
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

.detail-card-container {
  .icon-overflow-gray-circle {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.detail-card-container.farm {
  .prop-info-amenity {
    max-width: 50%;
  }
}

.detail-card-container.farm-card-type_upload {
  height: calc(100% - 20px);
}

.detail-card-container.farm-card-type_unprocessed:hover {
  border: none;
  cursor: auto;
}

.label-property-container,
.farm-options-container {
  min-width: 235px;

  .close-icon-row {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .close-icon {
    height: 15px;
    width: 15px;
    background-image: url("../../../../images/commonIcons/icon-close@2x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    cursor: pointer;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #efedee;
    cursor: pointer;

    .icon {
      width: 12px;
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .lead {
      background-image: url("../../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-lead@2x.png");
    }

    .visit {
      background-image: url("../../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-prospect@2x.png");
    }

    .no-contact {
      background-image: url("../../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-dnc@2x.png");
    }

    .label.icon {
      margin-right: 3px;
      height: 15px;
      width: 14px;
    }

    .left-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .txt-small {
      font-size: 13px;
    }

    .checkmark.icon.active {
      background-image: url("../../../../images/titlefyPro/commonIcons/icon-checkmark@2x.png");
    }
  }
}

.selected-farm-prop-container {
  .detail-card-container.farm-property {
    flex-shrink: 0;
    margin: 0;
    border: none;
    border-radius: 0;
  }
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../propertiesPage/_propListConstants.scss";

.sales-comps-container {
  .sales-comps-control-bar-mobile {
    display: flex;
    padding: 16px 16px 0 16px;

    &.sales-comps-mobile-map-padding {
      padding: 16px;
    }

    @include respond-min($breakpoint-sm) {
      display: none;
    }
  }

  .list-map-container {
    display: flex;
    flex: 1;
  }

  .sales-comps-list {
    background-color: #fafafa;
    flex: 0.75;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 1;

    @include respond-max($breakpoint-sm) {
      max-width: none;
      flex: 1;
      padding-top: 0;

      .sales-comps-control-bar {
        display: none;
      }
    }
  }

  .sales-comps-control-bar-desktop {
    @include respond-max($breakpoint-sm) {
      display: none;
    }
  }

  .sales-comps-control-bar-desktop {
    margin-bottom: 32px;
  }

  .sales-comps-control-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .icon-list,
  .icon-map {
    width: 25px;
    height: 25px;
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 900;
    flex: 1;

    @include respond-max($breakpoint-sm) {
      flex: none;
    }
  }

  .no-results-container {
    margin-top: 16px;
  }

  .sales-comps-cards {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @include respond-max($breakpoint-sm) {
      padding-top: 0;
      height: 100%;
    }
  }

  .sales-comps-cards-scroll {
    position: absolute;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .sales-comps-cards-scroll::-webkit-scrollbar {
    display: none;
  }

  .sales-comps-map-container {
    flex: 1;

    @include respond-max($breakpoint-sm) {
      position: relative;
      z-index: 2;
    }
  }

  .mobile-hidden {
    @include respond-max($breakpoint-sm) {
      position: absolute;
      width: 100%;
      height: 69%;
      z-index: 0;
    }
  }

  .filters-wrapper {
    margin-left: 0;
  }

  .sales-comps-dropdown {
    .filter-menu,
    .sort-menu {
      max-height: 80vh;
      overflow-y: auto;
      @include respond-max($breakpoint-sm) {
        width: 90%;
        margin-left: $prop-list-margin-mobile;
      }
    }
  }

  .btn-filter,
  .btn-sort {
    margin-top: 0;
  }

  #selected-mobile-card-container {
    z-index: 3;
  }
}

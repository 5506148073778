﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../propertiesPage/_propListConstants.scss";

.property-card-wrapper {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;

  @include respond-max($mobile-breakpoint-width) {
    height: 100%;
  }
}

.property-card-main {
  width: auto;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%;

  @include respond-max($mobile-breakpoint-width) {
    padding-right: 0 !important;
  }

  @include respond-max($mobile-breakpoint-width) {
    height: 100% !important;
  }
}
.loading-card {
  height: 340px;
  background-image: url("../../../../images/titlefyPro/propertyDetails/cards/graphic-loading-propcard@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

#selected-mobile-card-container {
  height: auto !important;
  max-width: 500px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  @include respond-range(500px, $mobile-breakpoint-width) {
    margin-bottom: 25px;
  }
}

.property-card-main::-webkit-scrollbar {
  display: none;
}

.property-card-main::-ms-scrollbar {
  display: none;
}

.property-card-main::-moz-scrollbar {
  display: none;
}

.windowed-list::-webkit-scrollbar {
  display: none;
}

.windowed-list {
  padding-bottom: 100px;
}

.props-list-emptyMessage {
  text-align: center;
  margin: $prop-list-margin-desktop;
  @include respond-max($mobile-breakpoint-width) {
    margin: $prop-list-margin-mobile;
  }
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

.order-details-subpage-container {
  width: 100%;
  overflow-x: auto;

  .company-logo {
    width: 65px;
    height: 65px;
    object-fit: contain;
    position: absolute;
    top: 10%;
  }

  .content-width {
    width: 100%;
    max-width: 1400px;
    padding: 100px 32px;
    margin: auto;

    @include respond-max($mobile-breakpoint-width) {
      padding: 100px 16px;
    }
  }

  .no-results-message {
    text-align: center;
    font-size: 24px;
  }

  .basic-table.ui.basic.table {
    .header-margin {
      height: 10px;
    }
  }

  .basic-table.ui.basic.table tr {
    cursor: default;
  }

  .underline {
    text-decoration: underline;
  }

  .basic-table.ui.basic.table tr {
    background-color: #f8f8f8;
  }

  .col-width-25 {
    width: 25%;
  }

  .col-width-16 {
    width: 16%;
  }
}

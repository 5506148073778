﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../_navbarPro.scss";

.new-order-container {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.new-order__cancel {
  background: none;
  border: none;
  cursor: pointer;
  width: 25px;
}

.new-order-navbar {
  width: 100%;
  display: flex;
  height: $navbar-height;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 5%;
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;

  .title {
    display: flex;
    justify-content: center;
    font-size: 18px;
    text-align: center;
  }

  .right-btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .left-btn-container,
  .right-btn-container {
    max-width: 45px;
    min-width: 45px;
  }
}

.new-order-navbar > * {
  flex: 1;
}

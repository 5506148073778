﻿@import "../../modules/_mixins.scss";
@import "../../modules/_constants.scss";
@import "./_homePageConstants.scss";

$navbar-mobile-height: 75px;
.navbar-container {
  background: white;
  width: 100%;
  box-shadow: none;
  transition: ease all 0.3s;

  @include respond-max($breakpoint-sm) {
    height: 80px;
  }

  @include respond-max($breakpoint-sm) {
    align-items: center;
  }

  .icon-close-black {
    width: 15px;
    height: 15px;
  }

  .navbar-content {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: $section-width;
    max-width: $home-max-width;
    height: 100px;
    margin: auto;

    @include respond-max($breakpoint-sm) {
      height: $navbar-mobile-height;
    }

    .logo {
      margin: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 100%;
      transition: ease all 0.3s;
      background-image: url("../../images/svg/logos/logo-flueid.pro.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 22px;
      width: 175px;
      cursor: pointer;

      @include respond-max($breakpoint-sm) {
        bottom: 50%;
        top: 50%;
      }
    }

    .static-flueid-logo {
      top: 50%;
      bottom: 50%;
    }

    .logo-grey {
      cursor: pointer;
    }

    .contact-us,
    .login {
      color: #000000;
      cursor: pointer;
      border: none;
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 420px;

      .btn-black {
        margin-left: 15px;
        width: 85px;
      }

      .contact_seperator {
        height: 40px;
        width: 1px;
        border: 1px solid #4b4b4b;
        margin: 0 50px;
      }

      .btn-white {
        width: 152px;
      }
    }

    .buttons-container * {
      cursor: pointer;
    }

    .main-container {
      @include respond-max($breakpoint-sm) {
        display: none;
      }
    }

    .navbar-hamburger {
      display: block;
      width: 22px;
      cursor: pointer;

      @include respond-min($breakpoint-sm + 1) {
        display: none;
      }

      .hamburger-bar {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: black;
      }
    }

    .navbar-mobile-menu-open {
      height: 100vh;
      width: 100%;
      background-color: #ffffff;
      transition: 0.5s;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 100;
      transform-origin: center center;
      perspective: 2000px;
      top: 0 !important;
      left: 0 !important;
      user-select: none;

      .mobile-menu-content {
        height: 100%;
        max-width: $breakpoint-sm;
        margin: 0 auto;
        display: block;

        a {
          display: block;
          text-align: center;
          margin-top: 10px;
          cursor: pointer;
        }

        a:nth-child(2),
        a:last-child {
          margin-top: 40px;
        }

        .top-row {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: $navbar-mobile-height;
        }

        .mobile-menu-content {
          color: $lighter-gray;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

.navbar-container.navbar-scrolling.fade-in {
  border-bottom: 1px solid #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 1000000;

  .logo {
    top: 50%;
    bottom: 50%;
  }
}

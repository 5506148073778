﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.order-step-card {
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  display: flex;
  margin: 20px 0;
  min-height: 140px;
  padding: 20px 15px 25px;
  transition: box-shadow 0.2s;

  @include respond-min(600px) {
    border-radius: 0;
    padding: 40px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &.active {
    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
    }

    .order-step-card__icon-check {
      fill: #000;

      .inner-check {
        fill: #fff;
      }
    }
  }

  p {
    margin: 0;
  }

  .order-step-card__icon-check {
    fill: #ebebeb;
    height: 22px;
    width: 22px;

    .inner-check {
      fill: #afafaf;
    }

    &.complete {
      fill: #78eda8;

      .inner-check {
        fill: #fff;
      }
    }
  }

  .order-step-card__info-header {
    font-size: 15px;
    margin: 0;
  }

  .txt-gray {
    color: #999;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .order-step-card__description {
    color: #999;
    margin: 30px 0 5px;

    @include respond-min(600px) {
      margin-top: 10px;
    }
  }

  .order-step-card__completed-info {
    margin-top: 20px;

    @include respond-min($breakpoint) {
      display: flex;
    }
  }

  .order-step-card__primary-content {
    @include respond-max(600px) {
      margin-top: 20px;
    }

    @include respond-min($breakpoint) {
      flex-basis: 50%;
    }
  }

  .order-step-card__secondary-content {
    @include respond-min($breakpoint) {
      margin-left: 2%;
    }
  }

  .btn-order-step {
    @include respond-max(600px) {
      background-color: #fff;
      border: none;
      color: #000 !important;
      font-weight: 700;
      height: auto;
      min-height: 0;
      padding: 0 5px;
      width: auto;

      &.green-inverted {
        color: #78eda8 !important;
        padding-top: 3px;
        position: absolute;
        right: 0;
      }
    }
  }

  .order-step-card__btn-container {
    position: relative;

    @include respond-min(600px) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      position: static;
    }
  }

  .icon-lock {
    display: inline-block;
    height: 15px;
    width: 20px;

    @include respond-min(600px) {
      height: 24px;
      width: 24px;
    }
  }

  .info-container {
    flex: 1;
    margin: 0 5%;

    @include respond-max(400px) {
      margin: 0 auto;
      margin-left: 10px;
    }

    .step-title {
      font-size: 18px;

      @include respond-max(400px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 22px;
        font-size: 16px;
      }

      .mobile-btn {
        display: none;
        margin-left: 10px;

        @include respond-max(400px) {
          display: block;
        }

        .btn {
          @include respond-max(400px) {
            width: auto;
            border: none;
            background: none;
            height: auto;
          }
        }

        .btn-blue {
          @include respond-max(400px) {
            color: $light-blue;
          }
        }
      }
    }

    .summary-content {
      margin-top: 20px;

      .summary-container {
        display: block;
      }
    }
  }
}

.order-step-warning {
  display: flex;
  justify-content: center;
}

.order-step-warning-icon {
  display: inline-block;
  flex-basis: 15px;
  height: 13px;
  width: 13px;
  margin: 0 5px;
  vertical-align: -1px;
}

.order-step-warning__text {
  @extend .p;
  @extend .light-grey;

  display: inline-block;
  margin-bottom: 15px;
}

.order-step-card-container > * {
  min-height: 100%;
}

.submit-order {
  min-height: 125px;
  padding-top: 50px;
  text-align: center;

  .submit-order__btn {
    display: block;
    margin: 20px auto 0;
    max-width: 360px;
    width: 100%;
  }
}

.order-step-card-container:first-child {
  margin-top: 0px;
}

.order-step-card-container.locked {
  opacity: 0.6;
}

.initials-icon {
  background: lightgray;
  border-radius: 30px;
  width: 53px;
  min-width: 53px;
  height: 53px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include respond-max(375px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 18px;
  }
}

.initials-icon.background-white {
  background: white;
}

.initials-icon.empty {
  background: white;
  border: 1px solid #e1e1e1;
  background-image: url("../../../../../images/adminPage/icon_my_account.png");
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}

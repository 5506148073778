﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.sort-props-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.3s all ease;
  flex-direction: column;
  overflow: hidden;

  .row {
    border-bottom: 1px solid #efefef;
    width: 100%;
    cursor: pointer;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      margin: 10px 20px;

      .sort-type {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .sort-name {
        margin-left: 5px;
      }

      .icon {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
      }

      .icon.closest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-closest@2x.png");
      }

      .icon.created-newest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-created-newest@2x.png");
      }

      .icon.created-oldest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-created-oldest@2x.png");
      }

      .icon.name-az {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-name-az@2x.png");
      }

      .icon.name-za {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-name-za@2x.png");
      }

      .icon.location {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-location@2x.png");
      }

      .icon.address {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-address@2x.png");
      }

      .icon.newest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-newest@2x.png");
      }

      .icon.high-value {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-highest-Val@2x.png");
      }

      .icon.low-value {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-lowest-Val@2x.png");
      }

      .icon.largest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-largest@2x.png");
      }

      .icon.smallest {
        background-image: url("../../../images/titlefyPro/commonIcons/sorting/icon-sort-smallest@2x.png");
      }

      .icon.checkmark {
        background-image: url("../../../images/titlefyPro/commonIcons/icon-checkmark-green@2x.png");
        width: 12px;
        height: 12px;
      }
    }
  }

  .row:last-child {
    border: none;
  }
}

.sort-props-container.visible {
  max-height: 280px;
}

.sort-props-container.hidden {
  max-height: 0;
}

.icon.sort {
  background-image: url("../../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
}

﻿/* Semantic UI toggle checkbox style overrides. */
/* Jhonnie wants all toggle checkboxes with these styles, hence wrapping in titlefy-pro-container */

.ui.fitted.toggle.checkbox.fitted,
.ui.toggle.checkbox.fitted input,
.ui.toggle.checkbox.fitted label:before {
  width: 2.5rem;
}

.ui.toggle.checkbox.fitted input:checked ~ label:after {
  left: 1.15rem;
}

.ui.toggle.checkbox.checked input:checked ~ label:before,
.ui.toggle.checkbox.checked input:focus:checked ~ label:before,
.ui.toggle.checkbox.fitted input:focus:checked ~ .box:before,
.ui.toggle.checkbox.fitted input:focus:checked ~ label:before {
  background-color: black !important;
}

.ui.toggle.checkbox.fitted input:focus ~ label:before,
.ui.toggle.checkbox.fitted label:before,
.ui.toggle.checkbox.fitted label:hover::before {
  background-color: #bababa;
}

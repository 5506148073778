﻿.user-row-container {
  .user-bold {
    font-weight: 600;
  }

  .user-bolder {
    font-weight: 700;
  }

  .user-text-light {
    color: #646464;
  }

  .user-initial,
  .user-img {
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .user-initial {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: white;
  }

  .primary-text-status-1 {
    color: black;
    font-size: 13px;
  }

  .primary-text-status-2 {
    background-color: #ff9604;
    font-size: 13px;
    color: #ffffff;
    border-radius: 25px;
  }

  .primary-text-status-3 {
    background-color: #fc5c65;
    font-size: 13px;
    color: #ffffff;
    border-radius: 25px;
  }

  .primary-text-status-0 {
    color: #a6b1b9;
    font-size: 13px;
  }

  .primary-text-status-4 {
    color: #a6b1b9;
    font-size: 13px;
  }

  .icon-overflow-vertical {
    width: 35px;
    height: 20px;
  }

  .user-row-status-bubble {
    border-radius: 25px;
    padding: 3px 10px 3px 10px;
    line-height: 20px !important;
    margin-left: 15px;
  }
}

.user-row-action-popup {
  /* override semantic style */
  min-width: 175px !important;

  .action {
    cursor: pointer;
    padding: 10px;
  }
}

﻿@import "../../modules/_mixins.scss";
@import "../../modules/_constants.scss";

$navbar-mobile-links-breakpoint: 812px;
$navbar-height: 65px;
$links-margin: 30px;
$links-margin-mobile: 20px;

.navbar-pro-container {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  position: relative;

  .navbar-pro-content {
    width: 100%;
    margin: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-max($mobile-breakpoint-width) {
      margin: 0 16px;
    }

    .page-link {
      font-size: 15px;
      font-weight: 500;
      color: #afafaf;
      padding: 7px 7px;
      position: relative;

      @include respond-max($navbar-mobile-links-breakpoint) {
        display: none;
      }
    }

    .page-link.active {
      color: black !important;
      /*box-shadow: 0 3px 0 0 $light-blue;*/
    }

    .page-link.active:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -16px;
      width: 100%;
      height: 2px;
      background-color: black;
    }

    .mobile-link-txt {
      position: relative;
      top: 2px;
      font-size: 10px;
    }

    .page-link-mobile-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;

      @include respond-min($navbar-mobile-links-breakpoint + 1px) {
        display: none;
      }
    }

    .page-link.admin {
      @include respond-max($breakpoint-xsm) {
        display: none;
      }
    }

    .nav-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      width: 36px;
      height: 36px;
    }

    .left-controls {
      display: flex;
    }

    .right-controls {
      display: flex;
      align-items: center;

      .settings-margin-right {
        margin-right: $links-margin;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #afafaf;

        @include respond-max($mobile-breakpoint-width) {
          display: none;
        }
      }

      .account-icon {
        font-size: 20px;
        background-color: black;
        color: white;
        padding: 10px;
        border-radius: 50px;
        background-position: center;
        align-self: center;
        position: relative;
        left: 8px;
        cursor: pointer;
        width: 39px;
        text-align: center;

        @include respond-max($mobile-breakpoint-width) {
          left: 6px;
        }
      }

      .account-icon.border-lift:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        position: relative;
        top: 33px;
      }
    }

    .page-links {
      display: flex;
      align-items: center;
      border-left: 2px solid #f0f0f0;
      margin: 0 $links-margin;

      @include respond-max($mobile-breakpoint-width) {
        margin: 0 $links-margin-mobile;
      }
    }

    .links-margin {
      margin: 0 0 0 $links-margin;
    }

    .links-margin-mobile {
      margin: 0 0 0 $links-margin-mobile;
    }
  }

  .navbar-account-dropdown-container {
    .user-image-actual {
      height: 36px;
      width: 36px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      align-self: center;
      cursor: pointer;
    }

    .user-section {
      height: 36px;
    }

    .user-image {
      font-size: 18px;
      background-color: black;
      color: white;
      padding: 10px;
      border-radius: 50px;
      background-position: center;
      align-self: center;
      cursor: pointer;
      width: 40px;
      text-align: center;
    }
  }
}

.navbar-pro-container.navbar-basic {
  font-size: 17px;

  .back-icon {
    position: absolute;
    left: 32px;

    @include respond-max($navbar-mobile-links-breakpoint) {
      left: 16px;
    }
  }
}

.navbar-pro-container.navbar-farm {
  @include respond-max($navbar-mobile-links-breakpoint) {
    display: none;
  }
}

.navbar-width {
  width: 100%;
  margin: 0 16px;

  @include respond-min($navbar-mobile-links-breakpoint) {
    margin: 0 32px;
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

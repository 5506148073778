﻿@import "_constants.scss";

/* Used to apply media queries */
/* At breakpoint-lg and if old-ie, just renders rule w/o media query */
/*==========================================================*/
@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin respond-range($min-width, $max-width) {
  @if ($min-width == $breakpoint-lg) {
    @content;
  } @else {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
}

@mixin respond-range-height($min-height, $max-height) {
  @media screen and (min-height: $min-height) and (max-height: $max-height) {
    @content;
  }
}

@mixin respond-min-heigth($min-height) {
  @media screen and (min-height: $min-height) {
    @content;
  }
}

@mixin respond-max-height($max-height) {
  @media screen and (max-height: $max-height) {
    @content;
  }
}

@mixin respond-max-width-max-height($max-width, $max-height) {
  @media screen and (max-width: $max-width) and (max-height: $max-height) {
    @content;
  }
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

/* basic centered/contained background image */
@mixin imgCenterContain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin mobile-only($breakpoint, $display: block) {
  display: $display !important;

  @include respond-min($breakpoint) {
    display: none !important;
  }
}

@mixin desktop-only($breakpoint, $display: block) {
  display: none;

  @include respond-min($breakpoint) {
    display: $display;
  }
}

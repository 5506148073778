@import "../../../modules/_constants.scss";
@import "../../../modules/_mixins.scss";
@import "../_homePageConstants.scss";

.txm-section-full-page-main-container {
  .txm-section-main-container {
    background-color: #0070c0;
    width: 100%;

    .txm-flexbox-container {
      display: flex;
      width: 70%;
      margin: 0 auto;
    }

    .txm-flexbox-container-homepage {
      display: flex;
      max-width: 1400px;
      margin: 0 auto;

      @include respond-max($breakpoint-md) {
        flex-direction: column;
        align-items: center;
      }

      .whatElse-content {
        @include respond-max($breakpoint-md) {
          padding-bottom: 0;
        }
      }
    }

    .btn-txm {
      background-color: white;
      color: black;
      border-color: white;
      width: 33%;
      min-width: 120px;

      @include respond-max($breakpoint-sm) {
        margin: 0 auto;
      }
    }

    .txm-hero-img {
      background-image: url("../../../images/homePage/New-Home.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 60%;
      height: 666px;
      flex-shrink: 0;
      align-self: center;
      justify-content: flex-end;

      @include respond-max($breakpoint-md) {
        width: 100%;
        margin: 50px auto;
        height: 90vw;
        min-height: 475px;
      }
    }

    .dashed-line-txm-home {
      position: relative !important;
    }

    .txm-text-title {
      font-size: 48px;
    }

    .whatElse-content {
      color: white;
      max-width: $home-max-width;
      padding-top: 125px;
      padding-bottom: 190px;
      padding-left: 75px;
      padding-right: 75px;
      display: flex;
      flex-direction: column;
      position: relative;

      @include respond-max($breakpoint) {
        width: $section-width;
      }

      @include respond-max($breakpoint-md) {
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        align-items: center;
      }

      .header-1 {
        color: white;
      }

      .subheader-1 {
        margin-top: 30px;
        font-size: 32px;
        width: 70%;

        @include respond-max($breakpoint-sm) {
          width: auto;
        }
      }

      .whatElseTxtSubSecMain {
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        @include respond-range(320px, 1024px) {
          flex-direction: column;
        }
      }

      .whatelseSec {
        width: 90%;
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 20px;

        @include respond-range(320px, 1024px) {
          width: 100%;
        }

        .sec {
          margin-top: 30px;
        }

        .p-3 {
          margin-top: 10px;
        }

        .whatelseSecParaTxt {
          display: flex;
          margin-top: 20px;

          .icon-check-white {
            position: relative;
            top: 4px;
            margin-right: 10px;
          }

          .p-3 {
            margin-top: 0;
          }
        }

        .whatelseSecImage {
          height: 180px;
          width: 220px;
          background: url("../../../images/homePage/Image\ 1@2x.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-top: 40px;
        }
      }

      .block-text-margin {
        width: 100%;
        margin-top: 30px;
      }

      .rich {
        @include respond-range(320px, 1024px) {
          margin-top: 30px;
        }
      }
    }
  }

  .txm-middle-section-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    padding-top: 150px;
    margin: 0 auto;

    @include respond-max($breakpoint-sm) {
      grid-template-columns: 1fr;
    }

    .subsection-titles {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .txm-middle-left-block {
      width: 45%;
      margin-right: 10%;
    }

    .txm-middle-right-block {
      width: 45%;
    }
  }

  .txm-middle-section-container {
    background-color: black;

    .info-block-container {
      padding-bottom: 70px;
      margin: 0 auto;
    }
  }

  .middle-section-white {
    background-color: white;
  }

  .txm-middle-section-container {
    .txm-middle-section-content {
      width: 70%;
      max-width: 1155px;
      margin: 0 auto;
      padding-bottom: 150px;

      @include respond-max($breakpoint-sm) {
        width: 90%;
      }
    }

    .middle-section-title {
      padding-top: 150px;
      padding-bottom: 20px;
    }

    .txm-para-spacing {
      padding-top: 20px;
    }

    .txm-txt-white {
      color: white;
    }

    .txm-subcontent-icon {
      height: 35px;
      width: 35px;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: contain !important;
    }

    .icon-package {
      background: url("../../../images/homePage/np_package_2878042_FFFFFF.svg");
    }

    .icon-notif {
      background: url("../../../images/homePage/np_notification-bell_3974735_FFFFFF.svg");
    }

    .icon-action {
      background: url("../../../images/homePage/np_checklist_4200134_000000.svg");
    }

    .icon-schedule {
      background: url("../../../images/homePage/np_schedule_1464727_000000.svg");
    }

    .icon-wiresafe {
      background: url("../../../images/homePage/np_locker-money_2585382_000000.svg");
    }

    .icon-convo {
      background: url("../../../images/homePage/np_conversation_1039499_000000.svg");
    }

    .icon-document {
      background: url("../../../images/homePage/np_share-document_1416586_FFFFFF.svg");
    }

    .icon-infosheets {
      background: url("../../../images/homePage/np_write_3492624_FFFFFF.svg");
    }

    .icon-autograph {
      background: url("../../../images/homePage/np_confirmed-sign_3651032_000000.svg");
    }

    .icon-data {
      background: url("../../../images/homePage/np_data_2594705_000000.svg");
    }

    .icon-browser {
      background: url("../../../images/homePage/np_folder-search_3538937_000000.svg");
    }

    .icon-order {
      background: url("../../../images/homePage/np_inspect-document_152575_000000.svg");
    }

    .icon-docnamer {
      background: url("../../../images/homePage/np_document_3106619_FFFFFF.svg");
    }

    .icon-quick {
      background: url("../../../images/homePage/Group_3.svg");
    }
  }

  .txm-full-page-hero-img {
    height: 973px;
    width: 73%;
    background: url("../../../images/homePage/Top_Image.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 50px;

    max-width: 1000px;
    background-size: cover;
    width: 45%;
    background-position: right;
    margin-right: 0;
    height: 100vw;
    min-height: 500px;
    max-height: 1000px;

    @include respond-max($breakpoint-md) {
      width: 100%;
    }
  }

  .txm-flexbox-container-full {
    display: flex;
    align-items: center;

    @include respond-max($breakpoint-md) {
      flex-direction: column;
      width: 100%;
    }
  }

  .margin-top-txm {
    margin-top: 50px;
  }

  .middle-section-color-white {
    color: white;
  }

  .padding-bottom-txm {
    padding-bottom: 150px;
  }

  .dashed-line-container {
    margin-top: 120px;

    @include respond-max($breakpoint-sm) {
      display: none;
    }
  }
}

﻿@import "../../../../../modules/_mixins.scss";
@import "../../../../../modules/_constants.scss";

.order-steps-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .order-steps__outer-border {
    margin: 10px;
    max-width: 1200px;
    padding: 20px 10px;
    width: 98%;

    @include respond-min($breakpoint) {
      border: 1px solid #ebebeb;
      margin: 40px 0;
      padding: 60px 50px;
    }
  }

  .scroll-container {
    width: 100%;
    height: 100%;
  }

  .scroll::-webkit-scrollbar-track {
    margin: 5% 0 20px 0;
  }

  .top-block {
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }

  .back-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .back-arrow {
      background-image: url("../../../../../images/commonIcons/icon-backArrow@2x.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 12px;
      width: 12px;
    }
  }

  .new-order-title {
    font-size: 18px;
  }
}

﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.ui.popup.account-dropdown-popup {
  padding: 0;
  margin-top: 80px;
  margin-right: 32px;
  border-radius: 0px;
  box-shadow: 0 2px 12px 0px rgba(34, 36, 38, 0.12);
  border: 1px solid #ededed;

  @include respond-max(812px) {
    margin-right: 16px;
  }
}

.navbar-user-account-dropdown {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  background-color: #ffffff;

  @include respond-range(220px, 360px) {
    width: 100%;
  }

  .user-section {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #efeff4;
    padding-bottom: 10px;
    padding-top: 20px;
    align-self: center;
    padding-left: 40px;
    padding-right: 10px;

    .user-image {
      font-size: 24px;
      background-color: black;
      color: white;
      padding: 14px;
      border-radius: 50px;
      background-position: center;
      align-self: center;
      min-width: 48px;
      max-width: 50px;
      text-align: center;
    }

    .user-image-actual {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      align-self: center;
    }

    .user-data {
      align-self: center;
      width: 50%;
      margin-left: 14px;
      font-size: 14px;
    }

    .user-edit {
      width: 30%;
      color: #00b2f1;
      font-weight: 500;
      font-size: 14px;
      text-align: end;
      padding-right: 17px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .quote-section {
    padding-top: 25px;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    cursor: pointer;

    h5 {
      margin-bottom: 8px;
      font-weight: 900;
    }

    .links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;

      .next-arrow {
        height: 20px;
        width: 15px;
        background-image: url("../../../images/titlefyPro/propertyIcons/next-arrow@2x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 47%;
        cursor: pointer;
      }
    }

    .sign-out {
      font-size: 13px;
      font-weight: 800;
      cursor: pointer;
    }
  }

  .quote-section:hover {
    background-color: #fafcfd;
  }

  .sign-out-accountdropdown {
    border-bottom: none !important;
  }

  .sign-out-accountdropdown:hover {
    background-color: black;

    .sign-out {
      color: white;
    }
  }

  .trulytitle-section {
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 10px;
    padding-right: 10px;

    .truly-title {
      height: 70px;
      width: 100%;
      background-image: url("../../../images/titlefyPro/truly-logo@3x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 60%;
      padding-bottom: 5px;
    }

    .web-link {
      color: #00b2f1 !important;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .help-section {
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;

    .ned-help {
      text-align: center;
      padding-bottom: 20px;

      .contact {
        color: #afafaf;
      }
    }

    .help-usr {
      display: flex;
      width: 100%;
      align-self: center;

      .user-image {
        width: 20%;
        background-image: url("../../../images/titlefyPro/avatar-default@3x.png");
        height: 53.08px;
        width: 41.13px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        align-self: center;
        border-radius: 50%;
      }

      .user-data {
        align-self: center;
        width: 50%;
        margin-left: 14px;
        font-size: 14px;
      }

      .user-edit {
        width: 30%;
        font-size: 30px;
        text-align: end;
        align-self: center;
        cursor: pointer;
      }
    }

    .show-partnerdata {
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;

      .label {
        width: 50px;
      }

      .partner-email {
        color: #00b2f1;
        font-weight: 500;
        font-size: 14px;
        margin-left: 10px;
      }

      .partner-phone {
        font-weight: 600;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}

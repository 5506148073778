﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.ui.popup.customer-service-dropdown-popup {
  left: 0px;
  padding: 0px;
  margin-top: 80px;
  margin-left: 32px;
  margin-right: 32px;
  display: flex;
  border-radius: 0px;
  box-shadow: 0 2px 12px 0px rgba(34, 36, 38, 0.12);
  border: 1px solid #ededed;

  @include respond-range(320px, 812px) {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
    max-width: 425px;
  }
}

.navbar-customer-service-dropdown {
  width: 380px;

  @include respond-range(320px, 812px) {
    width: auto;
  }

  .quote-section {
    padding: 25px 40px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    @include respond-range(320px, 375px) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
    }
  }

  .top-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }

  .service-logo {
    width: 175px;
    height: 50px;
    object-fit: contain;
    margin-top: 50px;
    margin-bottom: 30px;

    @include respond-range(320px, 375px) {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .service-link {
    color: #00b2f1 !important;
  }

  .need-help {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 35px;
    width: 270px;
    margin: 0 auto;
    border-top: solid 1px #efeff4;
    margin-bottom: 35px;

    @include respond-range(320px, 400px) {
      width: 200px;
    }

    @include respond-range(320px, 375px) {
      margin-top: 25px;
      margin-bottom: 25px;
      padding-top: 25px;
    }
  }

  .contact-main {
    font-size: 18px;
    font-weight: bold;
  }

  .contact {
    font-size: 14px;
    color: #afafaf;
    margin-top: 5px;
  }

  .help-user {
    display: flex;
    background-color: #fafcfd;
    padding: 40px;

    @include respond-range(320px, 375px) {
      padding: 20px 0px 20px 20px;
    }
  }

  .user-image {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    border-radius: 50px;
  }

  .user-data {
    padding-left: 20px;
    padding-top: 5px;
  }

  .service-name {
    font-size: 16px;
  }

  .service-title {
    font-size: 13px;
  }

  .label {
    font-weight: 500;
    font-size: 15px;
  }

  .partner-data {
    font-size: 15px;
    font-weight: 700;
    padding-left: 30px;
  }

  .email-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 350px;

    @include respond-range(320px, 812px) {
      width: 280px;
    }
  }

  .partner-data.text {
    padding-left: 40px;
  }

  .show-partnerdata:hover {
    background-color: #fafcfd;
  }

  .partnerdata-border-top {
    border-top: 1px solid #f0f0f0;
  }

  .switch-partners-btn {
    margin-bottom: 30px;
  }
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";
@import "./_logos.scss";

/* THIS WILL BECOME OBSOLETE
    - once app is using <BasicInput/> for all forms and there aren't more instances of this "form" class selector
    - .form class selector still being used in:
        - renameFarmForm.tsx
        - saveFarmFilterForm.tsx
*/

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .error-label:after {
    background-color: $red !important;
  }

  .error-message {
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
    color: $red;
    width: 70%;
    min-width: 260px;
    height: 24px;
  }

  .error-message.multiple-errors {
    height: auto;

    ul {
      margin-top: 0;
      padding-left: 10px;
    }
  }

  .label-back {
    z-index: 0 !important;
  }

  .animated-label {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    font-weight: 300;
    cursor: text;
    transition: 0.2s ease all;
    margin: 0;
    padding-left: 5px;
    padding-bottom: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 18px;
    letter-spacing: 0.79px;
    color: #a6b1b9;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 45%;
      height: 2px;
      width: 10px;
      visibility: hidden;
      background-color: $light-blue;
      transition: 0.2s ease all;
    }
  }

  &.not-empty {
    .animated-label {
      top: -4px;
      font-size: 12px;
    }
  }

  .form-control {
    width: 100%;
    color: black;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    border-radius: 0;
    /*border-width: 0 0 1px;*/
    border-width: 0 0 2px;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    height: auto;
    padding: 3px 0 5px 5px;
    outline: none;
    caret-color: $light-blue;

    &:focus {
      box-shadow: none;
      border-bottom-color: rgba(0, 0, 0, 0.12);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color: $light-blue;
        transition: 0.2s ease all;
      }

      ~ .animated-label {
        top: -4px;
        opacity: 1;
        /*color: $light-gray;*/
        color: black;
        font-size: 12px;

        &:after {
          visibility: visible;
          width: 100%;
          left: 0;
        }

        .icon-alert {
          bottom: 5px;
        }
      }
    }
  }

  .invalid {
    border-bottom-color: $red;
  }

  .form-group-basic {
    position: relative;
  }

  .form-input {
    position: relative;
    width: 100%;
    border: none;
    padding: 0 10px;
    box-shadow: none;
    outline: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .form-input.border-bottom {
    position: relative;
    background: transparent;
    padding: 0 0 5px 5px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }

  .form-input.border-bottom.not-empty {
    border-bottom: 2px solid $light-blue;
  }

  .form-input.border-bottom ~ .border-bottom-animation {
    position: absolute;
    content: "";
    width: 0;
    /*background: rgba(0, 0, 0, 0.2);*/
    height: 2px;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .form-input.border-bottom ~ .border-bottom-animation.center {
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }

  .form-input.border-bottom:focus ~ .border-bottom-animation {
    background: $light-blue;
    width: 100%;
  }

  .password-icon {
    position: absolute;
    bottom: 5px;
    left: 92%;
    z-index: 100;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    height: 20px;
    width: 20px;
  }

  .password-icon.hide {
    background-image: url("../../images/auth/icon-hide@2x.png");
  }

  .password-icon.show {
    background-image: url("../../images/auth/icon-show@2x.png");
  }

  .clear-icon {
    background-image: url("../../images/titlefyPro/searchIcons/search-icon-clear@2x.png");
    height: 12px;
    width: 12px;
    background-size: contain;
    position: absolute;
    right: 5px;
    cursor: pointer;
    z-index: 100;
    bottom: 10px;
  }

  .clear-icon.hidden {
    visibility: hidden;
  }
}

textarea {
  resize: none;
}

﻿@import "../../../../modules/_constants.scss";
@import "../../../../modules/_mixins.scss";

.slider-container {
  padding: 10px 0;

  .icon-container {
    display: flex;
    justify-content: flex-end;

    .edit-icon {
      background-image: url("../../../../images/commonIcons/icon-edit.png");
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }

  .labels-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .slider-label {
      flex: 1;
    }

    .slider-label.centered {
      text-align: center;
    }

    .slider-label.right {
      text-align: right;
    }
  }
}

.basic-range-slider-container {
  width: 100%;
  .labels-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .range-slider {
    width: 100%;
  }
}

// can't target p tag because it is already used
p,
.p {
  color: #000;
  font-size: 14px;
  font-weight: 500;

  &.large {
    font-size: 16px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

.h2 {
  font-size: 22px;

  @include respond-min($breakpoint) {
    font-size: 32px;
  }
}

.h3 {
  font-size: 20px;
  font-weight: 700;

  @include respond-min($breakpoint) {
    font-size: 26px;
  }
}

.h4 {
  font-size: 16px;
  font-weight: 700;

  @include respond-min($breakpoint) {
    font-size: 26px;
    font-weight: 500;
  }
}

.h5 {
  font-size: 16px;
  font-weight: 700;

  @include respond-min($breakpoint) {
    font-size: 18px;
    font-weight: 500;
  }
}

.light-grey {
  color: $lighter-gray !important;
}

.success-green {
  color: $bright-green !important;
}

.red {
  border-bottom-color: $red;
  color: $red !important;
}

.warning-yellow {
  color: $warning-yellow !important;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.boldest {
  font-weight: 900;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-xsm {
  font-size: 9px;
}

.txt-sm {
  font-size: 12px;
}

.txt-overflow_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line-height-normal {
  line-height: normal;
}

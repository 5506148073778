﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.sidebarContentType-Map {
  @include respond-max($mobile-breakpoint-width) {
    display: none;
  }
}

.properties-sidebar_desktop {
  display: none;
  @include respond-min($mobile-breakpoint-width) {
    display: block;
  }
}

.properties-sidebar_mobile {
  display: block;
  @include respond-min($mobile-breakpoint-width) {
    display: none;
  }

  &.sidebarContentType-Map {
    display: none;
  }
}

.saved-sidebar.mobile-content_Map {
  display: none;
  @include respond-min($mobile-breakpoint-width) {
    display: block;
  }
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 32px;
  width: 100%;

  @include respond-max($mobile-breakpoint-width) {
    flex-direction: column;
    width: 100%;
    margin: 12px 0;
    padding: 0 16px;
  }

  .control-icon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .clear-icon {
    background-image: url("../../../images/titlefyPro/searchIcons/search-icon-clear@2x.png");
    height: 16px;
    width: 16px;

    @include respond-max($mobile-breakpoint-width) {
      right: 4px;
      position: relative;
    }
  }

  .clear-icon.hidden {
    visibility: hidden;
  }

  .left-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0.75 1;
    max-width: 625px;
  }

  .properties-left-controls {
    @include respond-max($mobile-breakpoint-width) {
      width: 100%;
      justify-content: center;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 12px;
      max-width: none;
    }
  }

  .back-arrow {
    background-image: url("../../../images/commonIcons/icon-back-arrow@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .back-arrow.close-saved {
    @include respond-max($mobile-breakpoint-width) {
      display: none;
    }
  }

  .left-controls-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include respond-max($mobile-breakpoint-width) {
      width: 100%;
      // margin: 0 16px;
    }
  }

  .search-input-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-max($mobile-breakpoint-width) {
      width: 91.5%;
    }
  }

  .search-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-search@2x.png");
    height: 20px;
    width: 20px;
  }

  .search-form {
    display: flex;
    flex: 1;
  }

  input {
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
  }

  .suggestion-label {
    font-size: 16px;
    margin-left: 36px;
    margin-right: 6px;

    @include respond-max($mobile-breakpoint-width) {
      margin-left: 18px;
    }
  }

  input,
  .suggestion-label {
    line-height: 21px;
  }

  .right-controls {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
  }

  .properties-right-controls {
    @include respond-max($mobile-breakpoint-width) {
      width: 100%;
      margin-top: 12px;
    }
  }

  .filter-icon-container {
    display: none;

    @include respond-max($mobile-breakpoint-width) {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .filter-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-filter@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 16px;
    width: 16px;
    cursor: pointer;

    @include respond-max($mobile-breakpoint-width) {
      // margin-left: 16px;
    }
  }

  .control-text {
    margin-left: 10px;
    cursor: pointer;
  }

  .filter-icon-container.farms-active {
    display: none;
  }

  .control-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
  }

  .control-text {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sort-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-sort-descending@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
    cursor: pointer;
  }

  .saved-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-saved-nopadding@2x.png");
    background-position: center;
    margin-right: 3px;
  }

  .saved-active {
    /*height: 18px;*/
    background-position: center;
    background-image: url("../../../images/titlefyPro/commonIcons/icon-saved-active-nopadding@2x.png");
    margin-right: 3px;
  }

  .map-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-map@2x.png");
  }

  .list-icon {
    background-image: url("../../../images/titlefyPro/commonIcons/icon-list@2x.png");

    @include respond-max($mobile-breakpoint-width) {
      position: relative;
      left: 6.5px;
    }
  }

  .control-container.last {
    padding-right: 0;

    @include respond-max($mobile-breakpoint-width) {
      display: none;
    }
  }

  #hide-map-container {
    @include respond-min($mobile-breakpoint-width) {
      display: none;
    }
  }

  #saved-control-container {
    padding-right: 0;
  }

  #sort-control-container {
    display: none;

    @include respond-max($mobile-breakpoint-width) {
      display: flex;
    }
  }

  .control-container.last.mobile {
    display: none;

    @include respond-max($mobile-breakpoint-width) {
      display: flex;
      width: 80px;
      // margin-right: 16px;
    }
  }

  .right-controls.saved-mode-on {
    @include respond-max($mobile-breakpoint-width) {
      margin-top: 0;
    }
  }

  .control-bar-sort-container {
    display: none;

    @include respond-max($mobile-breakpoint-width) {
      display: flex;
      width: 100%;
    }
  }
}

.properties-controls-container {
}

.saved-controls-container {
  @include respond-max($mobile-breakpoint-width) {
    flex-direction: row;
    width: 100%;
    margin: 12px 0;
  }
}

﻿@import "../../../modules/_mixins.scss";
@import "../../../modules/_constants.scss";

.farm-filters-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .top-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    // margin-top: 20px;

    .text-gray {
      font-size: 12px;
      color: #b9bec1;
      font-weight: 900;
      cursor: pointer;
    }

    .close-icon {
      height: 12px;
      width: 12px;
      background-image: url("../../../images/commonIcons/icon-close@2x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
    }
  }

  .list-type-control-container {
    padding-bottom: 32px;
    width: 100%;

    .list-type-control {
      display: flex;
      height: 27px;
      background: #f4f4f4;
      padding: 2px;
      border-radius: 5px;

      .list-type {
        flex: 1;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
      }

      .list-type.active {
        background: white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
      }
    }
  }

  .farm-filters {
    position: absolute;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-right: 10px;
    padding-left: 3px;

    .filter-category-container {
      margin-top: 32px;

      .filter-category-header {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
      }

      .filter-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #efedee;
        cursor: pointer;

        .edit-icon {
          height: 15px;
          background-image: url("../../../images/titlefyPro/commonIcons/icon-edit-line@2x.png");
          background-size: contain;
          background-position: center;
          transition: all 0.3s ease;
        }

        .edit-icon.open {
          width: 15px;
          margin-right: 15px;
        }

        .edit-icon-closed {
          width: 0;
        }

        .edit-controls {
          display: flex;
          transition: all 0.3s ease;
          position: relative;
          bottom: 1px;

          .control {
            color: white;
            padding: 12px 0;
            width: 50%;
            display: flex;
            justify-content: center;
          }

          .rename {
            background: $lighter-gray;
          }

          .delete {
            background: red;
          }
        }

        .edit-controls.open {
          margin-left: 15px;
          width: 140px;
        }

        .edit-controls.closed {
          width: 0;
          margin-left: 0;
          visibility: hidden;
        }

        .filter-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          padding: 12px 0;
          border-bottom: 1px solid #efedee;
        }

        .filter-name-container {
          display: flex;
          align-items: center;

          .lead {
            background-image: url("../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-lead@2x.png");
          }

          .visit {
            background-image: url("../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-prospect@2x.png");
          }

          .no-contact {
            background-image: url("../../../images/titlefyPro/propertyStatusSigns/farms/map-dot-dnc@2x.png");
          }

          .hot {
            width: 15px;
            height: 15px;
            background-image: url("../../../images/commonIcons/Flame_Icon@2x.png");
          }

          .for-sale {
            background-image: url("../../../images/titlefyPro/propStatusDots/map-dot-listed.png");
          }

          .recently-sold {
            background-image: url("../../../images/titlefyPro/propStatusDots/map-dot-recently-sold.png");
          }

          .pending {
            background-image: url("../../../images/titlefyPro/propStatusDots/map-dot-pending.png");
          }

          .off-market {
            background-image: url("../../../images/titlefyPro/propStatusDots/map-dot-off-market.png");
          }

          .filter-name {
            margin-left: 6px;
          }
        }

        .filter-icon {
          width: 12px;
          height: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          bottom: 1px;
        }

        .arrow-icon {
          background-image: url("../../../images/titlefyPro/up-arrow@3x.png");
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        .text-smaller {
          font-size: 13px;
        }

        .light-blue {
          color: $light-blue;
        }

        .checkmark {
        }

        .checkmark.active {
          background-image: url("../../../images/titlefyPro/commonIcons/icon-checkmark-green@2x.png");
        }

        .filter-slider-block {
          width: 100%;

          .slider-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .min-max {
              display: flex;

              .dash-separator {
                margin: 0 3px;
              }
            }
          }
        }

        .block-selection-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .block {
            background: #f4f4f4;
            flex: 1;
            display: flex;
            justify-content: center;
            padding: 3px 0;
            border: none;
            outline: none;
            cursor: pointer;
            margin: 0 1px;
          }

          .active {
            background: $light-blue;
            color: white;
          }

          .left-end {
            border-radius: 15px 0 0 15px;
            margin-left: 0;
            margin-right: 1px;
          }

          .right-end {
            border-radius: 0 15px 15px 0;
            margin-right: 0;
            margin-left: 1px;
          }
        }
      }

      .filter-row.column {
        flex-direction: column;

        .filter-name {
          width: 100%;
          margin-bottom: 6px;
        }
      }
    }
  }

  .txt-small {
    font-size: 12px;
  }
}

.farm-filters-relative {
  position: relative;
  height: 100%;
}

﻿@import "../../modules/_constants.scss";
@import "../../modules/_mixins.scss";

$border-gray: #e1e1e1;
$v2-error-red: #ff5a46;
$input-padding: 10px;

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 13px;
    font-weight: 500;
    padding-left: 5px;
  }

  input,
  .dropdown {
    padding: 5px 25px 5px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid $border-gray;
    font-size: 16px;
    font-weight: 700;
    caret-color: $light-blue;
  }

  input:focus {
    outline: none;
  }

  input.read-only {
    border-bottom-color: rgba(0, 0, 0, 0);
  }

  input.pad-right-5 {
    padding-right: 5px;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .bar:before,
  .bar:after {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $light-blue;
    transition: 0.3s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  .bar.dropdown-bar {
    z-index: 10;
  }
  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  .dropdown:focus ~ .bar:before,
  .dropdown:focus ~ .bar:after {
    width: 50%;
  }

  .dropdown.search.active ~ .bar:before,
  .dropdown.search.active ~ .bar:after {
    width: 50%;
  }
  /*---------------*/
  /* Semantic UI Dropdown style overrides*/
  .ui.selection.dropdown {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $border-gray;
    outline: none;
    min-height: 0;
    height: 29px;
    padding: 5px 5px 5px 5px;

    &.multiple {
      height: auto;
      min-height: 32px;
      padding: 4px 25px 4px 10px;
    }
  }

  .ui.selection.dropdown:hover {
    box-shadow: none !important;
    border-color: $border-gray;
  }

  .ui.selection.active.dropdown {
    box-shadow: none;
  }

  .ui.selection.active.dropdown:hover {
    box-shadow: none;
    border: none;

    .icon-dropdown {
      bottom: 9px;
    }
  }

  .ui.dropdown > .text {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: inherit !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 25px;
    display: block;
    left: 0;
  }

  .ui.selection.active.dropdown.menu {
    border-color: $light-blue !important;
    width: 100%;
    min-width: 100%;
    border-radius: 0;
    margin: 0;
    border-radius: 0;
  }

  .ui.selection.active.dropdown:hover .menu {
    border-color: $light-blue !important;
  }

  .ui.multiple.dropdown > .label {
    font-size: 0.75em;
  }

  /* SEARCH DROPDOWN */
  .ui.search.selection.dropdown > input.search {
    padding: inherit;
    height: 100%;
    left: 0;
  }

  .ui.dropdown.search {
    font-size: 16px;
  }

  .ui.search.dropdown > .text {
    position: absolute;
    padding: inherit;
    bottom: 0;
    height: 100%;
  }
  /* DROPDOWN ICON */
  .ui.dropdown > .dropdown.icon {
    border-bottom: none;
    padding: 2px 5px 0 0 !important;
  }

  .ui.disabled.dropdown {
    border-bottom-color: rgba(0, 0, 0, 0) !important;
    opacity: 1;

    .icon-dropdown {
      display: none;
    }
  }

  .icon-clear,
  .icon-dropdown,
  .icon-locked,
  .icon-unlocked {
    position: absolute;
    right: 5px;
    cursor: pointer;
    z-index: 5;
    bottom: 8px;
  }

  .icon-clear.hidden {
    visibility: hidden;
  }

  .icon-dropdown {
    z-index: 10;
  }
  /*-------------------*/
  /*------------ ERROR STYLES ------------*/
  input.error,
  .ui.selection.dropdown.error {
    border-bottom: 1px solid red;
  }

  .error-message {
    color: red;
    position: absolute;
    font-size: 12px;
    left: 5px;
    bottom: -15px;
  }
}

.input-group.read-only {
  input {
    border-bottom-color: rgba(0, 0, 0, 0);
  }

  .icon-clear {
    visibility: hidden;
  }

  .bar {
    visibility: hidden;
  }
}

/* NEW BORDERED VERSION STYLE THAT IS BEING USED IN UPLOAD FARM */
.input-group.v2 {
  input,
  .ui.selection.dropdown {
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    background-color: #ffffff;
    padding: 11px 25px $input-padding $input-padding;
    transition: 0.3s ease border;
    font-weight: 500;
    color: #152935;
    box-sizing: border-box;

    input.search {
      margin: 0;
      height: 100%;
      position: absolute;
    }

    & > .dropdown.icon {
      padding: 12px !important;
    }

    &.disabled {
      border: 1px solid #e1e1e1 !important;
      background-color: #fafafa;
      color: #d0d0d0;
    }

    &.multiple {
      height: unset;
      padding: 4px 25px 4px 10px;

      div.default.text {
        margin: 5px 0 !important;
      }

      .ui.label {
        background-color: #f8f8f8;
        color: #000 !important;
        font-size: 15px;
        font-weight: 500;
      }

      &.search.visible {
        overflow: unset;

        div.default.text {
          display: none;
        }

        input.search {
          position: static;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 30px;
        }
      }
    }
  }

  .ui.selection.dropdown {
    min-height: 40px;
  }

  input:hover,
  .ui.selection.dropdown:hover {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
  }

  input:focus,
  .ui.selection.dropdown:focus {
    border: 1px solid #152935;
  }

  .ui.search.dropdown > .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 25px;
    // display: block;
    left: 0;
  }

  .ui.selection.active.dropdown {
    border: 1px solid #152935;

    .menu {
      border-color: #152935 !important;
    }
  }

  .ui.search.selection.dropdown.active > input.search {
    left: 0;
  }

  input::placeholder {
    color: #d0d0d0;
  }

  .label {
    font-size: 14px;
    color: #777;
    padding-left: $input-padding;
    margin-bottom: 5px;
    text-align: left;

    &.required {
      color: #000;
      font-weight: 700;
    }

    &.optional {
      color: #787878;
    }
  }

  .ui.selection.active.dropdown:hover {
    .icon-dropdown {
      bottom: 13px;
    }
  }

  .icon-clear,
  .icon-dropdown,
  .icon-locked,
  .icon-unlocked {
    z-index: 5;
    right: 10px;
    bottom: 13px;
  }

  .icon-password-show,
  .icon-password-hidden {
    cursor: pointer;
    right: 34px;
    bottom: 10px;
    position: absolute;
  }

  .bar {
    display: none;
  }
}

.input-group.v2.error {
  input,
  .ui.selection.dropdown.error {
    border: 1px solid $v2-error-red;
    color: $v2-error-red;
  }

  input::placeholder {
    color: $v2-error-red;
  }

  .error-message-container {
    position: absolute;
    top: 64px;
    text-align: left;
    padding: 0 $input-padding;
    width: 100%;
    z-index: 5;
  }

  .error-message-container.error-box-shadow {
    padding: 5px $input-padding;
    background: white;
    border-radius: 2px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  .error-message {
    color: $v2-error-red;
    position: relative;
    top: 0;
    left: 0;
    font-size: 11px;
  }
}

.input-group.v2.read-only {
  input {
    border: 1px solid #e1e1e1 !important;
    background-color: #fafafa;
    color: #787878;
    pointer-events: none;
  }
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* add the calender icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 100;
  cursor: pointer;
  color: transparent;
  background-image: url("../../images/titlefyPro/orders/common/icon-calendar@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  background-color: white;
}

.input-group.v2.order-search,
.input-group.v2.user-search {
  .icon-clear {
    visibility: hidden;
    transition: visibility 0s;

    .open & {
      &:not(.hidden) {
        transition-delay: 0.1s;
        visibility: visible;
      }
    }
  }

  input {
    border: 0;
    font-size: 15px;
    height: 40px;
    padding: 0;
    transition: padding 0.3s 0.1s;

    &::placeholder {
      opacity: 0;
      transition: opacity 0.7s;
    }

    .open & {
      border: 1px solid #e1e1e1;
      padding: 10px 25px 10px 45px;

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.auto-suggest-input {
  .auto-suggest-input__text {
    color: #777;
    margin: 0;
  }

  .auto-suggest-input__results {
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    max-height: 280px;
    overflow: auto;
    padding: 5px 15px;
  }

  .auto-suggest-input__result-wrapper {
    border-bottom: 1px solid #f0f0f0;
    padding: 5px 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .auto-suggest-input__result {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -10px;
    padding: 10px 15px;
    text-align: left;
    width: calc(100% + 20px);

    &:hover,
    &:focus {
      background-color: #fafafa;
    }
  }
}

// Remove Spinners from number inputs
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

﻿@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";
@import "../../_navbarPro.scss";
@import "../../../common/animations.scss";
@import "../../propertiesPage/_propListConstants.scss";

$text-gray: #afafaf;
$section-padding: 2%;
$section-padding-mobile: 4%;

/*========== PROP DETAIL NAVBAR - used for all prop detail and subpages screens ================== */
.prop-detail-navbar {
  /*  height: 90px;*/
  height: $navbar-height;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 auto;
  z-index: 1001;

  .prop-detail-navbar-padded-width {
    display: flex;
    width: 100%;
    /* Keep commented out for now in case Jhonnie changes mind*/
    /*padding: 30px calc(4% + 1px);*/
    padding: 30px $prop-list-margin-desktop;
    @include respond-max($mobile-breakpoint-width) {
      padding: 30px $prop-list-margin-mobile;
    }
  }

  .property-name {
    color: #323232;
    font-size: 17px;
    text-align: center;
    margin: auto;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 15px;
    }
  }

  a {
    position: relative;
  }

  .back-icon {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

/*====================== MAIN PROP DETAIL PAGE CONTAINER ===================*/
.property-main-containerV2 {
  .fade-in {
    animation: fadeIn 1s;
  }

  .no-display {
    display: none;
  }

  .propertyDetail-body-container {
    width: 100%;
    margin: 0 auto;
  }
  /*====== General Text styles=======*/
  .txt-gray {
    color: $text-gray;
  }
  /*======== SECTIONAL PADDING ==============*/
  .property-background-grey {
    background-color: #fafafa;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-top: $section-padding;
    padding-bottom: $section-padding;
  }

  .section-max-width {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0;
  }

  .section-padded-width {
    width: calc(100% - 485px);
    padding: $section-padding;

    @include respond-max($prop-detail-mobile-breakpoint) {
      width: 100%;
      padding: $section-padding 4%;
    }
  }

  .section-padded-width.prop-summary {
    margin-bottom: calc(2% - 20px);

    @include respond-max($prop-detail-mobile-breakpoint) {
      margin-bottom: calc(2% - 15px);
    }
  }

  .calculators {
    @extend .property-background-grey;
    order: 3;
    margin-top: 20px;
    padding-bottom: 0;
  }

  .section-padded-width.calc-mini-pad-top {
    padding-top: 4%;

    @include respond-max($prop-detail-mobile-breakpoint) {
      padding-top: calc(#{$section-padding} + 15px);
    }
  }

  .prop-summary-mobile-width {
    @include respond-max($prop-detail-mobile-breakpoint) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  /* ==================== HERO SECTION ===========================*/
  .hero-section-container {
    height: 453px;
    width: 100%;
    background-color: #f8f8f8;
    position: relative;
    display: flex;

    @include respond-max($prop-detail-mobile-breakpoint) {
      height: 250px;
    }
  }

  .hero-section-map {
    width: 100%;
    position: relative;
  }

  .hero-section-map.with-photos {
    @include respond-max($prop-detail-mobile-breakpoint) {
      display: none;
    }
  }

  .prop-details-map-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-horizantal {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .img-prop-placeholder.no-lat-lng {
    background-size: contain;
  }

  .prop-photo-container {
    height: 453px;
    max-width: 900px;
    width: 50%;
    position: relative;

    @include respond-max($prop-detail-mobile-breakpoint) {
      height: 250px;
      width: 100%;
    }
  }

  .prop-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .property-dImage {
    max-width: 1400px;
    margin: 0 auto;
  }

  .map-photos-btns-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 8%;
    right: calc(4% + 1px);
    background-color: white;
    border-radius: 30px;
  }

  .map-btns.with-photos {
    @include respond-max($prop-detail-mobile-breakpoint) {
      display: none;
    }
  }

  .map-photos-btns-container.photo-btns {
    .map-btn {
      display: none;
    }

    #map-view-toggle {
      display: none;
    }

    @include respond-max($prop-detail-mobile-breakpoint) {
      .map-btn {
        display: flex;
      }
    }
  }

  .svg-icon.svg-margin-0 {
    margin: 0;
  }

  .map-photos-btn,
  .photos-link {
    cursor: pointer;
    width: 43px;
    height: 43px;
    padding: 10px;
    margin: 0 2px;
  }

  .photos-link {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 11px;
    background: white;
    border-radius: 30px;
    width: 65px;
    padding: 0;
    margin: 0;
  }

  .photos-link-prop-count {
    position: relative;
    left: 9px;
    top: 2px;
  }

  .map-photos-btn {
    background-size: 110%;
    background-color: #ffffff;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ui.image {
    object-fit: cover !important;
    cursor: pointer;
  }
  /*====================== PROP ACTION CARD =============================*/
  .property-cardDetails {
    position: absolute;
    top: -150px;
    right: 75px;
    height: calc(100% + 150px);
    width: 375px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      order: 1;
      position: static;
      width: 100%;
      position: relative;
      right: 0;
      top: 0px;
    }
  }

  .property-cardItems {
    position: sticky;
    top: 15%;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    background-color: #ffffff;
    padding: 30px 20px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);

    @include respond-max($prop-detail-mobile-breakpoint) {
      position: static;
      top: 0;
      width: 100%;
      padding: calc(8% + 11px) 4%;
      padding-top: 10px;
    }
  }

  .property-cardName-Details {
    padding-top: 15px;
    display: flex;
    justify-content: center;

    .property-Name {
      font-size: 15px;
      line-height: 22px;
    }

    .price {
      font-size: 26px;
      font-weight: bold;
    }

    .Adress {
      font-size: 13px;
      font-weight: 500;
    }

    .Area {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .property-cardName-Details.no-pad-top {
    padding-top: 0;
  }

  .property-shareSave {
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;

    .property-shareButton {
      border: 1px solid #e1e1e1;
      border-radius: 30px;
      background-color: #ffffff;
      width: 48%;
      cursor: pointer;
      line-height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .save-Icon {
        width: 15px;
        background-image: url("../../../../images/titlefyPro/propertyIcons/icon-saved@2x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120%;
        margin-right: 7px;
        height: 48px;
      }

      .unSave-Icon {
        width: 15px;
        background-image: url("../../../../images/titlefyPro/propertyIcons/Group 9@2x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        margin-right: 7px;
        height: 48px;
      }

      .share-Icon {
        width: 15px;
        background-image: url("../../../../images/titlefyPro/propertyIcons/share icon@2x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        margin-right: 7px;
        height: 48px;
      }
    }

    .unSave {
      background-color: #f5f7f9;
    }
  }

  .property-btn {
    display: block;
    margin: 15px 0;
    max-width: 100%;
    text-align: center;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    @include respond-min($prop-detail-mobile-breakpoint) {
      max-width: initial;
    }
  }

  .action-card-border-top {
    border-top: 1px solid #efedee;
    margin-top: 30px;
    padding-top: 30px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      margin-top: 0px;
      border-top: none;
    }
  }

  .property-cardItems-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .property-display-none-mobile {
    @include respond-max($prop-detail-mobile-breakpoint) {
      display: none;
    }
  }

  .property-details-container {
    @include respond-max($prop-detail-mobile-breakpoint) {
      padding: 0px;
      width: 100% !important;
      flex-flow: column;
      display: flex;
    }

    width: 100%;
    height: 100%;
    position: relative;

    .property-details-texts {
      @include respond-max($prop-detail-mobile-breakpoint) {
        max-width: 100% !important;
        font-size: 18px;
        order: 2;
        padding: 0rem;
        float: none;
      }
    }
  }

  .prop-summary-card {
    @include respond-min($prop-detail-mobile-breakpoint) {
      display: none;
    }
  }
  /*===================== PROP SUMMARY ====================*/
  .prop-status-badge-container {
    @include respond-max($prop-detail-mobile-breakpoint) {
      position: absolute;
      width: 100%;
    }
  }

  .prop-status-badge-position {
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    bottom: 12px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      position: absolute;
      z-index: 1;
      bottom: -10px;
    }
  }

  .property-cardSpecifications {
    display: flex;
    justify-content: space-between;
    max-width: 405px;

    .property-cardSpec {
      display: flex;
      align-items: flex-end;
      padding-right: 2%;
      font-size: 15px;

      @include respond-range(320px, 375px) {
        padding-right: 10px;
        font-size: 13px;
      }
    }

    .sqftL {
      padding-right: 0px;
    }

    .property-cardSpecificationsBeds {
      width: 25px;
      height: 25px;
      margin-right: 4px;
      background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bedroom@2x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .Bath {
      background-image: url("../../../../images/titlefyPro/propertyIcons/icon-bathroom@2x.png");
    }

    .sqft {
      background-image: url("../../../../images/titlefyPro/propertyIcons/icon-sqft@2x.png");
    }

    .mobile-scale-font {
      @include respond-max(400px) {
        font-size: 3vw !important;
      }
    }
  }

  .property-cardItems-statusSec {
    display: flex;

    .property-cardItems-status {
      height: 22px;
      width: auto;
      padding: 5px;
      color: #fafafa;
      font-size: 12px;
      line-height: 10px;
      text-align: center;
      border-radius: 2px 0 0 2px;
    }

    .property-cardItems-status.pending {
      border: 1px solid #2c3e50;
      background-color: #2c3e50;
    }

    .property-cardItems-status.for-sale {
      border: 1px solid #26de81;
      background-color: #26de81;
    }

    .property-cardItems-status.recently-sold {
      background-color: #f1c40f;
      border: 1px solid #f1c40f;
    }

    .off-market {
      width: 74px;
      border: 1px solid #d0d0d0;
      border-radius: 2px 0 0 2px;
      background-color: #d0d0d0;
      color: #fafafa;
      font-size: 12px;
      line-height: 14px;
      padding-top: 3px;
    }

    .off-marketText {
      width: 74px;
      border: 1px solid #d0d0d0;
      border-radius: 2px 0 0 2px;
      background-color: #d0d0d0;
      color: #fafafa;
      font-size: 12px;
      line-height: 14px;
      padding-top: 3px;
    }

    .Famliy {
      border: 1px solid #fafafa;
      border-radius: 0 2px 2px 0;
      background-color: #fafafa;
      color: #000000;
      font-weight: 500;
    }
  }

  .icon-activeParent {
    height: 22px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 2px 2px 0;
    background-color: #fafafa;

    .icon-inactive {
      height: 18.43px;
      width: 14.1px;
      background-image: url("../../../../images/titlefyPro/propertyDetails/cards/icon-hot-inactive@2x.png");
      background-repeat: no-repeat;
      background-position: center;
      float: left;
      background-size: cover;
      background-size: cover;
    }

    .icon-active {
      height: 18.43px;
      width: 14.1px;
      background-image: url("../../../../images/titlefyPro/propertyDetails/cards/icon-hot-properties@2x.png");
      background-repeat: no-repeat;
      background-position: center;
      float: left;
      background-size: cover;
      background-size: cover;
    }
  }

  .property-detail-overview {
    font-weight: bold;
    color: #152935;
    /*height: 30px;*/
    font-size: 26px;
    line-height: 30px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 16px;
      /*            margin: 30px 0 15px 0;*/
      margin: 0;
    }
  }

  .property-detail-main-title {
    font-weight: 700;
    color: #152935;
    font-size: 32px;
    line-height: 30px;
    margin-top: 20px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 16px;
    }
  }

  .prop-detail-text-align-left {
    text-align: right;
  }

  .property-detail-address {
    padding-top: 5px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 13px;
      padding-top: 0px;
      margin-bottom: 10px;
      line-height: 18px;
    }

    @include respond-range(805px, 1219px) {
      width: 92%;
    }
  }

  .property-factItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efedee;
    padding: 20px 0;

    @include respond-max($prop-detail-mobile-breakpoint) {
      padding: 15px 0;
    }
  }

  .property-factItem.no-border {
    border: none;
  }

  .property-factListItem {
    display: flex !important;
    align-items: center;
    color: #000000;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 13px !important;
      line-height: 22px;
    }

    .icon {
      height: 20px;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 10px;
    }

    .listItem-icon-margin-left {
      margin-left: 10px;
    }
  }

  .property-familyStatus {
    font-weight: bold;
    color: #152935;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    word-wrap: break-word;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 13px !important;
      line-height: 22px;
    }
  }

  .property-detailFactRightSubDet {
    margin-top: 4%;

    @include respond-max($prop-detail-mobile-breakpoint) {
      margin-top: 2%;
    }
  }

  .property-detail-overview-para {
    padding-top: 5px;
    font-size: 15px;
    line-height: 26px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      font-size: 13px;
      padding-top: 0px;
    }

    @include respond-range(805px, 1219px) {
      width: 92%;
    }
  }

  .property-name-Highlight {
    color: #000000;
    font-weight: 600;
  }

  .prop-header-container {
    display: flex;
    justify-content: space-between;
  }
  /*PROP FACTS*/
  .property-detail-facts {
    display: flex;
    flex-direction: column;

    @include respond-max($prop-detail-mobile-breakpoint) {
      margin-bottom: 0px;
    }

    @include respond-range(805px, 1219px) {
      flex-direction: column;
      margin-bottom: 0px;
    }

    .property-detailFactLeft {
      width: 100%;

      @include respond-max($prop-detail-mobile-breakpoint) {
        font-size: 14px;
        width: 100%;
        padding: 0px;
      }

      .property-factItem {
        .property-propertyIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/prop-type-icon@2x.png");
        }

        .property-BuiltIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/year-built-icon@2x.png");
        }

        .property-lotIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/lot-sisze-icon@2x.png");
        }

        .property-stairsIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/stairs-icon@2x.png");
        }

        .property-parkingtIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/parking-icon@2x.png");
        }

        .property-ZillowIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/icon-zillow@2x.png");
        }

        .property-TruliaIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/icon-trulia@2x.png");
        }
        // .property-propertyIcon
        .property-propertyInformation {
          height: 20px;
          width: 15px;
          background-image: url("../../../../images/titlefyPro/propertyIcons/next-arrow@2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 47%;
          cursor: pointer;
        }
      }

      .detailed {
        cursor: pointer;
      }
    }

    .property-detailFactRight {
      width: 100%;

      @include respond-max($prop-detail-mobile-breakpoint) {
        font-size: 14px;
        width: 100%;
        padding: 0px;
      }

      .property-factItem {
        .property-factListItem {
          display: flex !important;
          height: 18px;
          color: #000000;
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;

          @include respond-max($prop-detail-mobile-breakpoint) {
            font-size: 13px !important;
            line-height: 22px;
          }
        }

        .property-poolIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/pool-icon@2x.png");
        }

        .property-firePlaceIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/fireplace-icon@2x.png");
        }

        .property-countyIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/county-icon@2x.png");
        }

        .property-occupancyIcon {
          background-image: url("../../../../images/titlefyPro/propertyIcons/occupancy-icon@2x.png");
        }

        .property-Realtor {
          background-image: url("../../../../images/titlefyPro/propertyIcons/icon-realtor@2x.png");
        }

        .property-propertyInformation {
          height: 20px;
          width: 15px;
          background-image: url("../../../../images/titlefyPro/propertyIcons/next-arrow@2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 47%;
          cursor: pointer;
        }
      }

      .detailed {
        cursor: pointer;
      }
    }
  }

  .ownership-width {
    width: 100% !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  /*============== AREA INSIGHTS ===================*/
  .map-pad-top {
    padding-top: 20px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      padding-top: 15px;
    }
  }
  /*============== LINKS ====================*/
  .ownership-set {
    display: flex;
    width: 100%;
    /*margin-bottom: 40px;*/
    @include respond-range($breakpoint-xxsm, 979px) {
      display: block;
    }
  }

  .ownership-set-data {
    width: 48%;
    margin-right: 60px;

    @include respond-range($breakpoint-xxsm, 979px) {
      width: 100%;
    }
  }

  .value {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    color: #000000;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    border-bottom: 0.5px solid #efedee;
    padding: 15px 0;

    .data {
      display: flex;
      flex-direction: column;
    }
  }

  .property-detail-no-padding {
    padding: 0 !important;
  }

  .property-detail-link-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 2em;
    margin-bottom: 5px !important;
  }

  .property-detail-link {
    cursor: pointer;
  }

  .property-detail-notification {
    border-radius: 4px;
    border: 1px solid #eaeaea;
    display: inline-block;
    font-size: 13px;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.5s;

    &.show {
      opacity: 1;
    }
  }

  .property-detail-notification__icon {
    height: 15px;
    vertical-align: -2px;
  }

  .property-Realtor {
    background-image: url("../../../../images/titlefyPro/propertyIcons/icon-realtor@2x.png");
  }
  /*================ FOOTER ====================*/
  .property-footerDetails {
    height: 160px;
    padding: 40px 0;
    width: 100%;
  }

  .property-footerTexts {
    margin: auto;
    height: 80px;

    @include respond-max($prop-detail-mobile-breakpoint) {
      margin: 0px;
      padding: 0 23px;
    }
  }

  .property-footerText {
    color: #8a9499;
    font-size: 11px;
    line-height: 16px;
    text-align: center;

    @include respond-max($prop-detail-mobile-breakpoint) {
      text-align: left;
    }
  }

  .photo-cred-container {
    @include respond-max(375px) {
      font-size: 9px;
    }
  }

  .property-footerDisclaim {
    height: 30px;
    color: #8a9499;
    font-size: 11px;
    line-height: 45px;
    width: 53px;
    margin: auto;
    text-align: center;
    border-bottom: 1px solid #8a9499;
    cursor: pointer;

    @include respond-max($prop-detail-mobile-breakpoint) {
      text-align: left;
      margin: 0;
    }
  }

  .cta-titlecheck__header {
    color: #020c14;
    font-size: 22px;
    font-weight: 500;

    @include respond-min($breakpoint) {
      font-size: 26px;
    }
  }

  .cta-titlecheck__subheader {
    color: #020c14;
    font-size: 15px;
    font-weight: 500;
  }

  .cta-titlecheck {
    background-color: #f1f1f5;
    border-radius: 5px;
    padding: 50px 30px 20px;
  }

  .cta-titlecheck__link {
    display: inline-block;
    margin: 15px 10px 0 0;
    text-align: center;
    width: 100%;

    @include respond-min(600px) {
      max-width: 235px;
    }

    @include respond-min($breakpoint) {
      margin: 15px 10px 0 0;
      max-width: none;
      width: 160px;
    }
  }

  .cta-titlecheck__btn {
    margin-top: 15px;
    width: 100%;

    @include respond-min(600px) {
      max-width: 235px;
    }

    @include respond-min($breakpoint) {
      max-width: none;
      width: 330px;
    }
  }

  .cta-titlecheck__footnote {
    color: #a6a6a6;
    font-size: 12px;
    margin-top: 20px;
    text-align: right;
  }
}

@import "../../../../modules/_mixins.scss";
@import "../../../../modules/_constants.scss";

$max-width-border: 950px;
$max-width-content: 480px;

@mixin mobile-margin-top-20() {
  margin-top: 20px;
}

@mixin mobile-margin-bottom-20() {
  margin-bottom: 20px;
}

.personalize-report-container {
  width: 100%;

  .max-width {
    width: 100%;
    max-width: $max-width-border;
    margin: 0 auto;
  }

  .form-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #f0f0f0;
    margin: 35px 0;
    padding: 10% $padding-16 40px $padding-16;

    @include respond-max($max-width-border) {
      border: none;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    max-width: $max-width-content;
  }

  .input-group {
    width: 100%;
    @extend .margin-bottom-20;
  }

  .input-margins {
    width: 100%;
    @extend .margin-top-50;
    @extend .margin-bottom-50;

    .input-group:last-child {
      margin-bottom: 0;
    }
  }

  .customize-content-rows {
    .basic-row:first-child {
      border-bottom: none;
    }
  }

  .upload-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .margin-top-50;

    @include respond-max($breakpoint-xsm) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .user-photo {
    border-radius: 50%;
    object-fit: cover;
    width: 132px;
    height: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-photo-border {
    border: 1px solid black;
  }

  .svg-icon {
    height: 50%;
  }

  .company-logo {
    object-fit: contain;
    border: 1px solid #d4d4d4;
    border-style: dashed;
    width: 180px;
    height: 120px;
  }

  .btn-xxl {
    width: 100%;
    max-width: 329px;
  }

  .header {
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
  }

  .sub-header {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .txt-small-gray {
    font-size: 13px;
    line-height: 16px;
    color: #aaaaaa;
  }

  .underline {
    text-decoration: underline;
  }

  .margin-top-bottom-10 {
    margin: 10px 0;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .margin-top-32 {
    margin-top: 32px;
  }

  .margin-bottom-32 {
    margin-bottom: 32px;
  }

  .margin-top-40 {
    margin-top: 40px;
  }

  .margin-bottom-40 {
    margin-bottom: 40px;
  }

  .margin-top-50 {
    margin-top: 50px;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }
}
